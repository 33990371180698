import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-race-media-select-dropdown',
  templateUrl: './race-media-select-dropdown.component.html',
  styleUrls: ['./race-media-select-dropdown.component.css'],
})
export class RaceMediaSelectDropdownComponent implements OnInit {
  public isOriginalMedia: boolean = true;
  public isEmotionalMedia: boolean = false;
  public isAnimation: boolean = false;
  @Input()
  isSelectedMediaId: number = 0;
  @Input()
  selectedVideo: any;

  @Input()
  translatedArray: any;

  @Output()
  selectedMedia: EventEmitter<number> = new EventEmitter();

  public filterTypes = [
    { id: 1, type: 'ORIGINAL HD' },
    { id: 2, type: 'EMOTIONAL VIDEO' },
    { id: 3, type: 'EMOTIONAL ANIMATION' },
  ];
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    console.log(this.selectedVideo, 'selectedVideo');
    if (this.isSelectedMediaId == 1) {
      this.isOriginalMedia = true;
      this.isEmotionalMedia = false;
      this.isAnimation = false;
    } else if (this.isSelectedMediaId == 2) {
      this.isEmotionalMedia = true;
      this.isAnimation = false;
      this.isOriginalMedia = false;
    } else {
      this.isAnimation = true;
      this.isEmotionalMedia = false;
      this.isOriginalMedia = false;
    }
  }
  selectMedia(id: number) {
    this.selectedMedia.emit(id);
    if (id == 1) {
      this.isOriginalMedia = true;
      this.isEmotionalMedia = false;
      this.isAnimation = false;
    } else if (id == 2) {
      this.isEmotionalMedia = true;
      this.isAnimation = false;
      this.isOriginalMedia = false;
    } else {
      this.isAnimation = true;
      this.isEmotionalMedia = false;
      this.isOriginalMedia = false;
    }
  }

  myFunction() {
    var x: any = document.getElementById('myLinks');
    if (x.style.display === 'block') {
      x.style.display = 'none';
    } else {
      x.style.display = 'block';
    }
  }
  downloadMedia(videoUrl: any, selectedUrl: any) {
    let url;
    console.log(videoUrl, selectedUrl, '99999');
    if (videoUrl) {
      url = videoUrl.url;
    } else {
      url = selectedUrl.url;
    }
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = url;

    // Use download attribute to set set desired file name
    a.setAttribute("download", "video");

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
}
}
