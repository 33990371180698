<ng-container *ngIf="isFooterAvailable">
    <!------Chnages for footer when map is NOT visvible----->

  <ng-container *ngIf="!isExplorePage">
    <div class="footer-container" id="footer-container">
      <footer class="amg-footer">
        <div class="nav-row">
        <div class="footernavigation">
            <nav>
              <ul>
                <li><a target="_blank" (click)="openProviderPage()" id="footerProviderLink"><span>{{"CP_LM_FOOTER_PROVIDER"| customTranslation: translationData}}</span></a></li>
                <li><a target="_blank" (click)="openLegalPage()" id="footerLegalLink"><span>{{"CP_LM_FOOTER_LEGAL" | customTranslation: translationData}}</span></a></li>
                <li style="display: none;"><a id="footerSRLink"><span>{{'SR_SETTINGS' | customTranslation: translationData}}</span></a></li>
                <li><a target="_blank" (click)="openPrivacyPage()" id="footerPrivacyLink"><span>{{ "CP_LM_FOOTER_PRIVACY" | customTranslation: translationData}}</span></a></li>
                <li style="display: none;"><a id="footerContactLink"><span>{{'SR_CONTACT' | customTranslation: translationData}}</span></a></li>
                <li style="display: none;"><a id="footerSrCcpaink"><span>{{'SR_CCPA' | customTranslation: translationData}}</span></a></li>
              </ul>
            </nav>
        </div>
        <div class="footernavigation language">
          <nav>
            <span class="menu-item top-menu-font" *ngIf="isLanguageSelector; else langSelectionBlock"> 
              <span class="glow-button lang-padding" (click)="openLanguagePicker()" ><a id="footerCurrentLanguageLink"><span>{{languageToDisplay}}</span></a></span> 
            </span>

            <ng-template #langSelectionBlock>
              <ul>
                <li (click)="switchLanguage(currentLanguage)"><a ><span>{{currentLanguage}}</span></a></li>
                <li (click)="switchLanguage('English')"><a ><span>English</span></a></li>          
              </ul>
            </ng-template>
          </nav>
        </div>
        </div>
        <div class="footer-logo">
          <span class="footer-logo-face"></span>
        </div>
        <div class="footer-top-button" (click)="scrollTop()">
            <button class="move-top">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="brandhub-back-top-button__icon">
                <path fill="currentColor" fill-rule="evenodd" d="m9.121 19.728-.707-.707 7.07-7.071-7.07-7.071.707-.707 7.07 7.07h.001l.707.708-7.778 7.778Z">
                </path>
              </svg>
              <span>{{'CP_FOOTER_TOP_LBL' | customTranslation: translationData}}</span>
            </button>
        </div>
      </footer>
    </div>
  </ng-container>
  <!------Chnages for footer when map is visvible----->
  <ng-container *ngIf="isExplorePage">
  <div class="footer-container-explore" id="footer-container-explore">
    <p>
      {{ "CP_LM_FOOTER_COPYRIGHT" | customTranslation : translationData }}&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;
      <span><a (click)="openProviderPage()" id="footerProviderLink">{{ "CP_LM_FOOTER_PROVIDER" | customTranslation : translationData }}</a></span>&nbsp;
      |&nbsp;
      <span><a (click)="openPrivacyPage()" id="footerPrivacyLink">{{ "CP_LM_FOOTER_PRIVACY" | customTranslation : translationData }}</a></span> &nbsp;
      |&nbsp;
      <span><a (click)="openLegalPage()" id="footerLegalLink">{{ "CP_LM_FOOTER_LEGAL" | customTranslation : translationData }}</a></span>&nbsp;
      <!------Hiding Cookies for now until the implementation is confirmed----->
      <span style="display: none;">|&nbsp;<a id="footerCookiesLink">{{ "CP_LM_FOOTER_COOKIES" | customTranslation : translationData }}</a></span>
    </p>
  </div>
</ng-container>
</ng-container>

