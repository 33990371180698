import { Injectable } from '@angular/core';
import {ProfileDetails} from '../components/interfaces/myStories.interface'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MyProfileService {

    private profileInfo: ProfileDetails = <any>null;
    constructor(private _httpClient: HttpClient){}
    public set profileInfoResponse(data: ProfileDetails){
        this.profileInfo = data;
    }

    public get profileInfoResponse() : ProfileDetails{
        return this.profileInfo;
    }
   
   
  /**
   * @description called when login for Profile Information
   * @returns User profile details
   */
  public getProfile(): Observable<any> {
    const url = environment.ausBaseUrl +'/acp/v1/user';
    return this._httpClient.get<any>(url);
  }





}