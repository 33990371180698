import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AppServicesService } from 'src/app/services/app-services.service';
import { DataService } from 'src/app/services/data.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';
import { EALL_TRACKS } from '../interfaces/map-view.interface';

@Component({
  selector: 'app-clusters-list',
  templateUrl: './clusters-list.component.html',
  styleUrls: ['./clusters-list.component.scss']
})
export class ClustersListComponent implements OnInit {
  @Input() public clusterList: any = [];
  @Input() public completeClusterList: any = [];
  @Input() public filter: any = EALL_TRACKS.ALL;
  @Input() public totalElements:any = 425;
  @Input() public isOffRoadEnabled:boolean = false;
  @Input() public isCircuitsEnabled:boolean = true;
  @Input() public isEmotiontourEnabled:boolean = true;
  @Input() public enableTrackList:boolean = true;
  @Output() public sendDataToMap = new EventEmitter<object>();
  @Output() public sendHoverToMap = new EventEmitter<object>();
  @Output() public showMarker = new EventEmitter<object>();
  @Output() public sendSearchDataToMap = new EventEmitter<object>();


  completeList: any;
  searchResults: any = [];
  filterName: any = EALL_TRACKS.ALL;
  searchFilter: any;
  listParent: any;
  index = 0;
  pageNumber = 0
  pageSize = 15
  totalPages = 19
  paginatedResults: any = [];
  searchTerm: any = "";
  showSpinner:Boolean = false;
  showLoader:Boolean = false;
  translationData = [];
  ciamToken:any
  featureFlags: any;

  constructor(public appService: AppServicesService, public dataService:DataService, private translationService: TranslationService) { }

  @HostListener('window:unload', ['$event'])
  onPopState(event:any) {
    localStorage.removeItem('filter');

  }


  fetchNextResults() {
    this.index = this.index + 1;
    if (this.index <= (this.totalPages - 1)) {
      this.pageNumber = this.index;
      this.showSpinner = true;
    }
  }

  ngOnInit(): void {
    this.featureFlags = this.translationService.featureFlags
    this.isOffRoadEnabled = this.featureFlags.landingPage.enableOffTour;
    this.ciamToken = localStorage.getItem('token')? localStorage.getItem('token') : '' ;

    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    let filter = localStorage.getItem('filter');
    this.filterName = filter;

    this.dataService.shareLoader.subscribe((data:any) => {
      this.showLoader = data;
    })
  }

  ngOnChanges() {
    if (this.clusterList && this.clusterList.length > 0) {
      this.completeList = this.clusterList;
    }
    this.searchFilter = this.filter;
  }

  sendDetails(data: any) {
    this.sendDataToMap.emit(data);
  }

  getSearchResults(event: any) {
    if (event !== 'empty') {
      if (event.length > 0) {
        this.searchResults = event;
        this.completeList = event;
      } else {
        this.searchResults = [];
        this.completeList = [];
      }
    } else {
      this.completeList = this.clusterList;
    }
    
    if (this.searchTerm.length > 0) {
      let dataToMap = {
        completeList: this.completeList,
        searchTerm: this.searchTerm
      }
      this.sendSearchDataToMap.emit(dataToMap);
    }
    else {
      if(this.filterName === EALL_TRACKS.ALL){
        let completeDataToMap = {
          completeList: this.completeClusterList,
          searchTerm: this.searchTerm
        }
        this.sendSearchDataToMap.emit(completeDataToMap);
      } else{
        let completeDataToMap = {
          completeList: this.completeList,
          searchTerm: this.searchTerm
        }
        this.sendSearchDataToMap.emit(completeDataToMap);

      }
      

    }
  }

  getsentMouseStatus(event: any, tourInfo: any) {
    let tourStatus = {
      mouseStatus: event,
      tourInfo: tourInfo
    }
    this.sendHoverToMap.emit(tourStatus);
  }

  public showTracks(data: any): void {
    if (!this.showLoader && !this.showSpinner) {
      this.filterName = data;
      this.index = 0;
      this.pageNumber = 0;
      this.showMarker.emit(data);
      this.paginatedResults = [];
    }
  }

  emitPaginatedResults(event: any) {
    if (event) {
      this.showSpinner = false;
      this.paginatedResults = [...this.paginatedResults, ...event];
      this.completeList = [...this.completeList, ...this.paginatedResults];
      let dataToMap = {
        completeList: this.completeList,
        searchTerm: this.searchTerm
      }
      this.sendSearchDataToMap.emit(dataToMap)

    }
  }

  getTotalPages(event:any){
    this.totalPages = event;
  }

  getTotalResultLength(event:any){
    this.totalElements = event;
  }

  getSearchTerm(event: any) {
    this.searchTerm = event;
  }

}