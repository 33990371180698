<div class="track-card-style">
    <div class="name-style"> <span>{{track?.name}}</span> 
        <ng-container *ngIf="ciamToken?.length > 0 && !showLoader">
            <span (click)="setUnsetFavourite(true)" *ngIf="!track.isFavorite" class="fav-icn"></span>            
            <span (click)="setUnsetFavourite(false)" *ngIf="track.isFavorite" class="fav-icn-filled"></span>
        </ng-container>  
        <ng-container *ngIf="showLoader">
            <span><div class="spinner-border spinner">
            </div></span>
        </ng-container>      
    </div>
    <div class="tag-items">
        <a href="#" [class.disabled]="isAnchorDisable" *ngIf="track?.trackLength" class="btn common-button sector-style"><span>{{track?.trackLength | number:'1.2-2'}} Km</span></a>
        <a href="#" [class.disabled]="isAnchorDisable" *ngIf="track?.noOfSectors" class="btn common-button sector-style"><span>{{track?.noOfSectors}} {{'CP_LM_TRACKDETAILSPAGE_SECTORS' | customTranslation: translationData}}</span></a>
    </div>
    <div class="owner-info">
        <span class="prof-image"></span> {{'CP_LM_MAINPAGE_BY' | customTranslation: translationData}} {{ track?.owner?.nickName ? track?.owner?.nickName : track?.owner?.name}} 
        <ng-container>
            <span class="dot"></span>
            <span class="fav-icn place-icn"></span>
            <span>{{track.favoriteCount}}</span>
        </ng-container>  
    </div>
</div>