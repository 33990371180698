import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language: any;
  supportedLanguage = environment.supported_language;
  private _participantLocaleSubject = new BehaviorSubject<any>('de-DE');  
  public participantLanguage = new BehaviorSubject<any>(environment.supported_language[1]);
  public shareParticipantLanguage = this.participantLanguage.asObservable();
  isNotparticipantSelectedLang:boolean = false;
  constructor() { 
    
  }

  getLanguage() {
    const selectedLanguage:any = localStorage.getItem('language'); //languageId will get from url
    const defaultBrowserLanguage = navigator.language;

    const languageCode:any=selectedLanguage?.split('-')[0]  // en,de,cz.... 
    const languageRegex: RegExp=/^[a-z]{2}-[A-Z]{2}$/

    const supportedLocales: any = {
      'en': 'en-GB',
      'de': 'de-DE',
      'fr': 'fr-FR',
      'it': 'it-IT',
      'ko': 'ko-KR',
      'cs': 'cs-CZ',
      'es': 'es-ES'
    }

    if(languageRegex.test(selectedLanguage)) { //en-GB, //de-DE
      this.language = selectedLanguage;
    } else if(defaultBrowserLanguage && this.supportedLanguage.includes(defaultBrowserLanguage)) { //system lang
      const selectedLang = (lang: string) => supportedLocales[lang];
      this.language = selectedLang(defaultBrowserLanguage);
    } else {
      this.language = environment.supported_language[0];  //en-GB
    }
    this._participantLocaleSubject.next(this.language);
    return this.language;
  }

  
  public set participantLocale(locale : string) {
    this._participantLocaleSubject.next(locale);
  }

  
  public get participantLocale() : any {
    return this._participantLocaleSubject.asObservable();
  }

  getDefaultLanguage() {
    const selectedLanguage = localStorage.getItem('language');
    const defaultBrowserLanguage = navigator.language;
    if(selectedLanguage && this.supportedLanguage.includes(selectedLanguage)) {
      this.language = selectedLanguage;
    } else if(defaultBrowserLanguage && this.supportedLanguage.includes(defaultBrowserLanguage)) {
      this.language = defaultBrowserLanguage;
    } else {
      this.language = environment.supported_language[0];
    }
    return this.language;
  }

  setParticipantLanguage(languageId: string) {
    this.participantLanguage.next(languageId);
  }

  setIsNotParticipantSelectedLang(status: boolean) {
    this.isNotparticipantSelectedLang = status;
  }
  getIsNotParticipantSelectedLang() {
    return this.isNotparticipantSelectedLang;
  }

  /**
   * @description This method is used to check the language is valid or not
   * Match the pattern of language en-GB, en-IN, de-DE, fr-FR, it-IT, es-ES, cs-CZ, ko-KR
   * @param locale 
   * @returns 
   */
  public checkIsValideLanguage(locale: any): boolean {
    const languageRegex: RegExp = /^[a-z]{2}-[A-Z]{2}$/;
    const isValid = languageRegex.test(locale);
    return isValid;
  }  
  
}
