import { Component, Input } from '@angular/core';
import { VehicleInfo } from 'src/app/components/interfaces/race-share.interface';

@Component({
  selector: 'app-event-car-details',
  templateUrl: './event-car-details.component.html',
  styleUrls: ['./event-car-details.component.scss']
})
export class EventCarDetailsComponent {
@Input() vehicleInfo!: VehicleInfo;
}
