import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { TranslationService } from 'src/app/services/translation.service';
@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit {
  @Input() eventBanner = "";
  defaultBanner = "../../../assets/mercedes1.png";
  volume: boolean = false;
  // Assuming an array of media file URLs
  @Input() itemType : string = '';
  @Input() numberOfImages: number = 0;
  public currentIndex: number = 0;
  date: any;
  @Input() story !: any;
  isPublished: boolean = false;
  raceStartTime: any;
  translationData = [];
  language: string = '';
  offroadTitle: string = 'CP_SM_OFFROAD_TITLE';
    constructor(private el: ElementRef,
      private translationService: TranslationService,
    ) { }
   
    ngOnInit(): void {  
      this.translationService.getTranslationDataAsObservable().subscribe(
        (translationData: any) => {
          this.translationData = translationData;
        }
      )
      this.date = new Date(this.story?.createdAt *1000);
      this.raceStartTime = new Date(this.story?.raceData?.raceStartTime  *1000);
      
    }
    
    scroll(direction: number) {
      this.currentIndex = (this.currentIndex + direction + this.story.media.length) % this.story.media.length;
      const mediaContainer = this.el.nativeElement.querySelector('.media-container');
      if (mediaContainer ) {
        const scrollTo = this.currentIndex * mediaContainer.clientWidth;
        mediaContainer.scrollLeft = scrollTo;
      }
    }
   

    convertToTotalTime(value: number): string {
      let totalSeconds: number;
      if (value >= 1000) {
        // Convert milliseconds to seconds
        totalSeconds = value / 1000;
      } else {
        // Use the provided value as seconds
        totalSeconds = value;
      }
    
      let minutes: number = Math.floor(totalSeconds / 60);
      let remainingSeconds: number = totalSeconds % 60;
      let roundedSeconds: string = remainingSeconds.toFixed(2); // Round seconds to two decimal places
      
      // Format the output
      return `${minutes}:${roundedSeconds}`;
    }



}