import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EventInfo } from 'src/app/interfaces/event-info.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyStoriesService {
  
  private _participantInfo: EventInfo = <any>null;


  constructor(private _httpClient: HttpClient) { }

  public set particpantInfoResponse(data : EventInfo) {
    this._participantInfo = data;
  }

  public get particpantInfoResponse(): EventInfo { 
    return this._participantInfo;
  }

  private editProfileClickedSubject = new BehaviorSubject<boolean>(false);
  private editProfileClosedSubject = new BehaviorSubject<boolean>(false);

  editProfileClicked$ = this.editProfileClickedSubject.asObservable();
  editProfileClosed$ = this.editProfileClosedSubject.asObservable();

  editProfilePopupVisible(visible: boolean) {
    this.editProfileClickedSubject.next(visible);
  }

  editProfilePopupClosed() {
    this.editProfileClosedSubject.next(true);
  }

  getGarageDetails(ciamId?: any): Observable<any>{
    const headers = new HttpHeaders()
    .set('ciamId', ciamId)
    .set('Content-Type','application/json');
    const url = environment.aesBaseUrl+'/v1/acp/garage';
    const body = {
      "latestTimestamp": 0
    }
    return this._httpClient.post(url, body, {headers: headers});
    // const response = require('../../../../test.json')
    // return of (response);
  }

  getStroiesForVin(vinOrFin: any, ciamId: any, pageNumber: number, pageSize: number): Observable<any>{
    let api_header = {
      headers: { 'Content-Type': 'application/json', 'vinOrFin': vinOrFin, 'ciamId': ciamId },
    };
    const url = `${environment.aesBaseUrl}/acp/v1/stories?page_size=${pageSize}&page_number=${pageNumber}`
    return this._httpClient.get<any>(url, api_header);
  }

  public getProfile(userId?: any): Observable<any> {
    let api_header = {
      headers: {'userId': userId}
    }
    const url = `${environment.ausBaseUrl}/acp/v2/user`;
    return this._httpClient.get<any>(url, api_header);
  }

  public updateProfile(nickName: string, description: string, socialLink: string, userId: any): Observable<any> {
    const api_header = { 'Content-Type': 'application/json', 'userId': userId};
    const baseUrl= environment.ausBaseUrl +'/acp/v2/user';
    const url=`${baseUrl}`;
    return this._httpClient.put(url,{nickName, description, socialLink},{headers: api_header});
  }
  /**
   * @description called post successful CIAM login; will have customer details and 
   * event details. Called when we reach the link-participant page
   * @param participantId 
   * @param participantKey 
   * @returns Participant details and event details
   */
  public getParticipantInfo(participantId: any, participantKey: any): Observable<any>{
    const baseUrl = environment.serverUrl
    const url= `${baseUrl}api/v2/customer_ciam/participant/${participantId}?participantSecretKey=${participantKey}`;
    return this._httpClient.get(url);
  }

  /**
   * @description called on confirm click and refresh
   * @param participantId 
   * @param participantKey 
   * @returns event data
   */
  public updateCiamConfirmation(participantId: any, participantKey:any): Observable<any> {
    // const baseUrl= environment.serverUrl;
    const baseUrl = environment.serverUrl;
    const url = `${baseUrl}api/v2/customer_ciam/participant/${participantId}?participantSecretKey=${participantKey}`;
    return this._httpClient.put(url,{});
  }




}