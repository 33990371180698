<div class="wrapper">

	<div class='loader' [ngClass]="{'d-block':isLoaderVisible, 'd-none':!isLoaderVisible}">
		<div class="spinner-border text-primary">
		</div>
	  </div>

	<section id="banner-container">
		<div class="module-loader module-loaded">
			<div class="videoContainer">
				<app-banner *ngIf="isbannerLoaded" [translatedArray]="translatedArray"
					[data]="data" [isbannerLoaded]=isbannerLoaded (loaderVisibility)="loaderVisibility($event)"> </app-banner>
			</div>
		</div>
	</section>
	<section class="share-module-container-style" id="share-module-container">
		<div class="module-loader"
			[ngClass]="{'module-loaded': isShareModuleLoaded}">
			<app-race-media *ngIf="data" [data]="data" [emotionalVideo]="emotionalVideo" [translatedArray]="translatedArray"></app-race-media>
		</div>
	</section>
	<section  class="car-data-container-style" id="car-data-container">
		<div class="module-loader container-full-width"
			[ngClass]="{'module-loaded': isCarDataLoaded}">
			<app-car-details *ngIf="data" [data]="data" [translatedArray]="translatedArray"></app-car-details>
		</div>
	</section>
	<section class="car-data-container-style" id="race-data-container">
		<div class="container-full-width margin-style"
			[ngClass]="{'module-loaded': isRacedataAnimatorLoaded }">
			<div class="highlight-class">{{ 'RSP_CUSTOMER_HIGHLIGHT_WITH_NAME' | customTranslation: {param: data?.participantInfo.firstName | uppercase }:translatedArray }}</div>
			<!-- <div class="highlight-class">{{ data?.participantInfo.firstName | uppercase }}{{'rspApostrophe' | customTranslation:translatedArray}} </div> -->
			<app-emotional-data-animator *ngIf="data" [data]="data" [translatedArray]="translatedArray"></app-emotional-data-animator>
		</div>
	</section>
	<!-- <section id="race-compare-container">
		<div class="module-loader"
			[ngClass]="{'module-loaded': isRaceCompareLoaded }">
			<div *ngIf="compareRaceData">
				<div *ngIf="compareRaceData.telemetryData && parameterList">
					<div *ngIf="compareRaceData?.telemetryData?.length">
						<app-race-compare *ngIf="data && fullRaceList"
							[fullRaceList]="fullRaceList"
							[currentRaceDataFromList]="currentRaceDataFromList"
							[raceInfo]="data?.raceinfo" [compareRaceData]="compareRaceData" (changeRacer)="updateCompareRace($event)"></app-race-compare>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section >
		<div *ngIf="data" class="module-loader "
			[ngClass]="{'module-loaded': isRaceCompareLoaded }" style="margin: 20px 0"> {{compareRaceFullData?.trackinfo.trackName}}
			<app-map [trackInfo]="data.trackinfo" [raceinfo]="data.raceinfo" [car1Pos]="car1Pos" [compareRaceData]="compareRaceFullData?.trackinfo"></app-map>
		</div>
	</section>
	<section id="race-analysis-container">
		<div class="module-loader"
			[ngClass]="{'module-loaded': isRaceAnalysisLoaded }">
			<div class="col-12 float-left">
				<div class="row">
					<div class="col-12 col-md-10 offset-md-1" *ngIf="compareRaceData">
						<div class="row slections-for-compare">

							<h2 class="col-12"></h2>
							<div class="col-12 col-md-6 raceList d-none" *ngIf="raceList">
								<label>Compare With Best Race Of: </label>
								<app-dropdown (select)="compareSelection($event)"
									[dropdownData]="raceList" [selectedItem]="compareWith"
									[title]="'name'"></app-dropdown>
							</div>
						</div>
					</div>
					<div class="wrapper" *ngIf="compareRaceData">
						<div class="col-12"
							*ngIf="compareRaceData.telemetryData && parameterList">
							<div class="row" *ngIf="compareRaceData?.telemetryData?.length">
								<div class="col-12">
									<app-graph-compare *ngIf="compareRaceData"
										[parameterList]="parameterList"
										[compareRaceData]="compareRaceData"
										(carPosChanged)="updateCarPos($event)"
										[raceInfo]="data?.raceinfo"> </app-graph-compare>
								</div>
							</div>
							<div class="col-12 no-data"
								*ngIf="!compareRaceData.telemetryData.length">Complete
								Race data for {{compareRaceData.racerName}}'s race is not
								available. Please try to compare with other racers from drop
								down.</div>
						</div>
					</div>
					<div class="col-3"></div>
				</div>
			</div>
		</div>
	</section> -->









	<!-- <app-race-summary *ngIf="data" [originalRaceInfo]="data?.raceinfo" [currentRaceDataFromList]="currentRaceDataFromList"></app-race-summary> -->

</div>