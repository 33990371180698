import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AwsAuthService {

  signedIn = false;
  auth = Auth;
  currentUser: any;
  userAuthTokenData: any;
  isUserLoggedIn: boolean = false;
  private _loginStatus = new Subject<any>();;
  private _shareLoginStatus$ = this._loginStatus.asObservable();

  constructor() {
    this.config();
  }

  config() {
    this.auth.configure(environment.amplifyConfig);
  }

  getLoginStatus(){
    return this._shareLoginStatus$;
  }

  setLoginStatus(val:any){
    return this._loginStatus.next(val);
  }

  async signIn() {
    await this.auth.federatedSignIn({customProvider: 'CIAMProvider'})
      .then((user: any) => {
        this.signedIn = true;
      })
      .catch((err: any) => {
        console.log(err);
        this.signedIn = false;
      });
  }

  getCurrentUserInfo() {
    return this.auth.currentUserInfo().then((info: any) => {
      this.currentUser = info;
      return info;
    });
  }

  async logout() {
    this.clearLocalStorageItems();
    await this.auth.signOut().then((data) => {
      setTimeout(()=> {
        window.open(environment.amplifyConfig.oauth.redirectSignOut, "_self");
      }, 200);
    });
  }

  async signOut() {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  currentSession() {
    return this.auth.currentSession();
  }

  setCurrentUserToken(data: any) {
    this.userAuthTokenData = data;
    localStorage.setItem('token',data.access_token)
  }

  getCurrentUserToken() {
    return this.userAuthTokenData;
  }

  setUserLoginStatus(status: boolean) {
    this.isUserLoggedIn = status;
  }

  getUserLoginStatus() {
    return this.isUserLoggedIn;
  }

  clearLocalStorageItems() {
    localStorage.removeItem('useLogged');
    localStorage.removeItem('token');
    localStorage.removeItem('access');
    localStorage.removeItem('access_token');
    localStorage.removeItem('currentCIAMUser');
    localStorage.removeItem('routeUrl');
  }

}
