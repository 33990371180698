<ng-container *ngIf="isTranslationDone && translationData.length">
<ng-container *ngIf="content | customTranslation: translationData; ">

    <section class="legal-page-container" [innerHTML]="content | customTranslation: translationData">
    
    </section>
    </ng-container>
    <ng-container *ngIf="isNotFound">
        <app-not-found-page></app-not-found-page>
    </ng-container>
</ng-container>