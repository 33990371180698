<div *ngIf="clustername" 
     (mouseenter)="addHeightAnimation()" (mouseleave)="removeHeightAnimation()" [ngStyle]="{'background-image': 'url('+returnBackgroundImage(clustername?.type)+')' }" class="tour-card-main">
    <div id="cardContent" class="card-content">
        <div class="clustername"><span>{{clustername?.name}}</span></div>
        <div class="tag-area">
            <span *ngIf="clustername?.trackLength || clustername?.type ==='EMOTION_TOUR'" class="blue-tag">{{'CP_LM_LANDINGPAGE_EMOTIONTOUR' | customTranslation: translationData}}</span>
            <span *ngIf="clustername?.hasOwnProperty('layoutsAvailable') || clustername?.type ==='CIRCUIT'" class="red-tag">{{'CP_LM_LANDINGPAGE_TRACKPACE' | customTranslation: translationData}}</span>
            <span *ngIf="clustername?.type === 'OFFROAD_TOUR'" class="amber-tag">{{'CP_LM_LANDINGPAGE_OFFROAD' | customTranslation: translationData}}</span>

            <span *ngIf="clustername?.layouts && !clustername?.layouts[0]?.guid" class="black-tag">{{clustername?.layouts}}  {{'CP_LM_LANDINGPAGE_TRACKS' | customTranslation: translationData}}</span>
            <span *ngIf="clustername?.totalNumberOfLayouts" class="black-tag">{{clustername?.totalNumberOfLayouts}}  {{'CP_LM_LANDINGPAGE_TRACKS' | customTranslation: translationData}}</span>

            <span *ngIf="clustername?.annotationsCount" class="black-tag">{{clustername?.annotationsCount}}  {{'CP_LM_TOURDETAILSPAGE_HIGHLIGHTS' | customTranslation: translationData}}</span>
            <span *ngIf="clustername?.trackLength" class="black-tag">{{(clustername?.trackLength / 1000).toPrecision(4)}} Km</span>
        </div>
        <div *ngIf="clustername?.description" class="description">
            <span>{{ clustername?.description || sampleDesc}}</span>
        </div>
        <!-- <div *ngIf="clustername?.owner" class="user">  // Uncomment after design clarification
            <span>{{'CP_LM_MAINPAGE_BY' | customTranslation: translationData}} {{clustername?.owner?.nickName}}</span>
        </div> -->
    </div>
    <div class="tour-image-reg" *ngIf="clustername.media">
        <figure>
            <img src="{{clustername.media.image[0].thumbnailReference}}"  alt="" />
        </figure>
    </div>
</div>