import { EventInfo, EventInfoResponse } from '../../../../interfaces/event-info.interface';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AppServicesService } from 'src/app/services/app-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { TourDbService } from '../../../../components/landing-page/landingpage.db';
import { TranslationService } from 'src/app/services/translation.service';
import { env } from 'process';
import { environment } from 'src/environments/environment';
import { MyStoriesService } from 'src/app/modules/stories/services/my-stories.service';
// import { MyStoriesService } from 'src/app/modules/my-stories/services/my-stories.service';

@Component({
  selector: 'app-ciam-link',
  templateUrl: './ciam-link.component.html',
  styleUrls: ['./ciam-link.component.scss'],
})
export class CiamLinkComponent implements OnInit {
  eventName: string = '';
  translationData = [];
  eventBanner = '';
  defaultBanner = '../../../assets/images/banner.png';
  participants: any;
  traceId: any;
  eventInfo : EventInfo = <any>null;
  isShowSpinner: boolean = false;

  constructor(
    private application: AppServicesService,
    private router: Router,
    private languageService: LanguageService,
    private _db: TourDbService,
    private translationService: TranslationService,
    private _activatedRoute: ActivatedRoute,
    private _myStoriesService : MyStoriesService,
  ) {}

  public ngOnInit(): void {
    this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
      });
    this.participants = sessionStorage.getItem('partcipationDetails');
    const languageFromUrl = this._activatedRoute.snapshot.paramMap.get('languageId');
    if (!this.participants || !languageFromUrl || !this.languageService.checkIsValideLanguage(languageFromUrl)){ 
      this.router.navigate(['/' + languageFromUrl + '/404']);
    }
    if (this.participants) {
      this.participants = JSON.parse(this.participants);
    }
    this._activatedRoute.params.subscribe(params => {
      let id = params['participantId'];
      if (id === this.participants.participantId) { 
          this.getParticipantInfo(this.participants.participantId, this.participants.participantKey);
      } else {
        const languageFromUrl = this._activatedRoute.snapshot.paramMap.get('languageId');
        this.router.navigate(['/' + languageFromUrl + '/404']);
      }
    });
  }

  public getParticipantInfo(participantId : string, participantKey : string) : void {
    if(this._myStoriesService.particpantInfoResponse){
      this.eventInfo = this._myStoriesService.particpantInfoResponse;
      this.isShowSpinner = true;
    } else{ 
      this._myStoriesService.updateCiamConfirmation(participantId, participantKey).subscribe((response: EventInfo) => {
        this.eventInfo = response;
        this.isShowSpinner = true;
      });
    }
  }

}


