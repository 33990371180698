<div class="vehicle-page-main">
    <div class="vehicle-page-container">
        <div class="car-cards-section">
        </div>
        <div class="main-sec">
            <div class="profile-about-social">
                <div id="prof-large">
                    <app-profile-component [profileDetails]="profileDetails"></app-profile-component>
                </div>
                <div id="prof-mobile">
                    <app-profile-mobile-view [profileDetails]="profileDetails"></app-profile-mobile-view>
                </div>           
             </div>
          
            <div class="user-moments">
                <div class="no-vehicle-card">
                    <div class="car-info-min">
                       <h1 class="car-name-min">{{'CP_SM_NO_VEHICLE_TITLE' | customTranslation: translationData}}</h1>
                       <div class="car-tags">
                           <div class="tag">{{'CP_SM_NO_VEHICLE_SUB_MSG' | customTranslation: translationData}}</div>
                       </div>
                    </div>
                    <div class="divider"></div>
                    <p>{{'CP_SM_NO_VEHICLE_MSG' | customTranslation: translationData}}
                    </p>
                  </div> 
            </div>
        </div>
    </div>
   
</div>


