<div class="not-found"*ngIf="!contentNotFound; else elseBlock">
    <h2>{{'CP_LM_PAGENOTFOUND_MESSAGE' | customTranslation: translationData }}</h2>
</div>
<ng-template #elseBlock>
    <div class="main-container">
        <div class="content-position">
            <h2>{{'CP_CONTENT_NOT_FOUND' | customTranslation: translationData}}</h2>
            <div class="button-region center">
              <button class="primary" (click)="refreshPage()">
                {{'CP_REFRESH_BTN' | customTranslation: translationData}}
              </button>
            </div>
          </div>
    </div>
    
  </ng-template>