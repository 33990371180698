import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { NgModule } from '@angular/core';
import { NoRouteComponent } from './no-route/no-route.component';
import { TrackLoaderComponent } from './track-loader/track-loader.component';
import { CircuitDetailsPageComponent } from './components/circuit-details-page/circuit-details-page.component';
import { LibraryComponent } from './components/library/library.component';
import { AuthGuard } from './services/auth.guard';
import { CiamLoginComponent } from './components/ciam-login/ciam-login.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ToggleGuard } from './services/toggle-guard.guard';
import { RouterLanguageGaurdGuard } from './services/router-language-gaurd.guard';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { LogoutHandlerComponent } from './components/logout-handler/logout-handler.component';
import { ProdEnabledGuard } from './services/prod-enabled.guard';
import { PrivacyPageComponent } from './components/shared/pages/privacy-page/privacy-page.component';
import { ProviderPageComponent } from './components/shared/pages/provider-page/provider-page.component';
import { LegalPageComponent } from './components/shared/pages/legal-page/legal-page.component';
import { LanguageSelectionPageComponent } from './components/language-selection-page/language-selection-page.component';
import { AnonymizeComponent } from './modules/stories/pages/anonymize/anonymize.component';
export const routes: Routes = [
  {
    path: ':languageId/explore',
    component: LandingPageComponent,
    canActivate: [ToggleGuard, ProdEnabledGuard],
    data: {page: 'enableLandingPage'}
  },
  {
    path: ':languageId/home',
    component: MainPageComponent,
  },
  {
    path: 'login',
    component: CiamLoginComponent,
  },  
  {
    path: 'anonymize/:partipationInfo', 
    children: [
      {
        path: '**',
        children: [],
        canActivate: [RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [RouterLanguageGaurdGuard]
  },
  {
    path: ':languageId/anonymize/:partipationInfo',
    children: [
      {
        path: '**',
        children: [],
        canActivate: [RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [RouterLanguageGaurdGuard]
  },
  {
    path: 'logout',
    component: LogoutHandlerComponent,
  },

  {
    path: ':languageId/explore/circuit/:name',
    component: CircuitDetailsPageComponent,
    canActivate: [ToggleGuard, ProdEnabledGuard],
    data: {page: 'enableCircuitDetails'}

  },
  {
    path: ':languageId/explore/emotion-tour/:name',
    component: TrackLoaderComponent,
    canActivate: [ToggleGuard, ProdEnabledGuard],
    data: {page: 'enableTourDetails'}
  },  
  {
    path: 'verify/:partipationInfo', 
    children: [
      {
        path: '**',
        children: [],
        canActivate: [RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [RouterLanguageGaurdGuard]
  },
  {
    path: ':languageId/verify/:partipationInfo',
    children: [
      {
        path: '**',
        children: [],
        canActivate: [RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [RouterLanguageGaurdGuard]
  },
  { 
    path: 'stories',
    loadChildren: () => import('../app/modules/stories/stories.module').then(m => m.StoriesModule),
    canActivate: [RouterLanguageGaurdGuard, ProdEnabledGuard, ToggleGuard]
  },
  {
    path: ':languageId/stories',
    loadChildren: () => import('../app/modules/stories/stories.module').then(m => m.StoriesModule),
    canActivate: [ProdEnabledGuard, ToggleGuard ],
    data: {page: 'enableMyStories'}
  },
  
  {    
    path: ':languageId/privacy',
    component: PrivacyPageComponent,
  },
  {
    path: ':languageId/provider',
    component: ProviderPageComponent,
  },
  {
    path: ':languageId/legal-notice',
    component: LegalPageComponent,
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
  },
  {

      path: 'home',

      component: MainPageComponent,

    },
    {
      path: ':languageId/404',
      component: NoRouteComponent,
    },
  
  { path: '**', component: NoRouteComponent },
    
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'  
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}