import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() { }

  decrypt(value: any): any {
    const key = environment.encryptionKey; //SECRET KEY FOR ENCRYPTION
    const decrypted = CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
    if(decrypted){
      return JSON.parse(decrypted);
    } 
  }
}
