import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-tags-component',
  templateUrl: './tags-component.component.html',
  styleUrls: ['./tags-component.component.scss']
})
export class TagsComponentComponent implements OnInit {
  @Input() tagTitle : any;
  @Input() tagClass : any;
  @Input() tagIconClass : any;
  translationData = [];
  constructor(private translationService: TranslationService,) { }

  ngOnInit(): void {}

}