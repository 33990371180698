import { Component, OnInit, Input, OnChanges, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data-services/data-service';

@Component({
	selector: 'app-car-details',
	templateUrl: './car-details.component.html',
	styleUrls: ['./car-details.component.css']
})
export class CarDetailsComponent implements OnInit, OnChanges {

	@Input()
	data: any;

	@Input()
	translatedArray: any = [];

	raceId: any;

	carData: any;

	model: string = '';

	isCarVisible: boolean = false;

	constructor(private dataService: DataService, private route: ActivatedRoute) {
		this.raceId = this.route.snapshot.paramMap.get('id');
	}

	@HostListener('window:scroll', ['$event'])
	onScroll(e: any) {
		const element = document.querySelector('#car-image-container');
		if (element) {
			const position = element.getBoundingClientRect();

			//console.log(position, window.innerHeight);
			// checking whether fully visible
			if (position.top >= 0 && position.bottom <= window.innerHeight) {
				this.isCarVisible = true;
				//console.log('Element is fully visible in screen');
			}

			// checking for partial visibility
			if (position.top < window.innerHeight && position.bottom >= 0) {
				this.isCarVisible = true;
			}
		}
		
	}

	ngOnInit() {
		//this.carData = ['./assets/images/iris1.png'];
		//this.model = 'AMG Test Model'
	}

	ngOnChanges() {
		// this.dataService.getCarImage(this.raceId).subscribe((data: any) => {
		// 	let formatedRace: any = [];
		// 	Object.keys(data).forEach(key => {
		// 		const dataString = data[key];
		// 		let formatedStr = dataString.replace('\"', '"');
		// 		formatedStr = formatedStr.replace('"{', '{');
		// 		formatedStr = formatedStr.replace('}"', '}');
		// 		const newFormatedData = JSON.parse(formatedStr);
		// 		Object.keys(newFormatedData.vehicle).forEach((key1, data1) => {
		// 			formatedRace.push(newFormatedData.vehicle[key1].url);
		// 		});
		// 	});
		// 	this.carData = formatedRace;


		// 	//this.carData = {"vehicle": {"BE020-P27": 'https://europe.starconnect-ce.i.daimler.com/iris/iris.png?COSY-EU-100-1713d0VXqb8FqtyO67PobzIr3eWsrrCsdRRzwQZQ9vZbMw3SGtlaItsd2HtcUfp8qXGEubYJ0l3DVOB2NS1bApjI9I5ux6YQC30MrkzNBzkm7jAGIhKV5ph%25vqCycyLRgcDYaxPXSrH1eHrn8ws8WoiZUkEM4FGmJTg90hT6PDBSbSeWA0Rtsd6vDcUfSO6XGEtTjJ0lcOtOB2a8EbApHPCI5u8cJQC3iX7kzN4JZm7j06ohKVBKF%25vqAujyLR5QmYaxCrSrH1znOn8w7PcoiZKegM4FvsjTg9LbZ6PDZkoSeW0mRtsdB%25ycJtTjqN4QYaxG5SrH1Aj3n8waAfoiZH5XM4F8SQTg9i6e6PD4GpSeWsehtsdUvGcUfG8WXGE0bwJ0lBDZOB2Mr2bA4wHEcmqN1IwCJ1MpwpnIu2fzzjFm93Su9Q6DF1s1n2nvligKfLlCVzia2P0v38d9g4J&&BKGND=9&IMGT=P27&POV=BE020,PZM'}}

		// })

		// if (this.data) {
		// 	this.dataService.getCarName(this.data.vehicleInfo.vin).subscribe((data: any) => {				
		// 		/*const modelArray = data.modelName.split("_");
		// 		let model = modelArray[modelArray.length - 2] + ' ' + modelArray[modelArray.length - 1];
		// 		for (let i = 0; i < modelArray.length - 2; i++) {
		// 			model = model + ' ' + modelArray[i];
		// 		}
		// 		this.model = 'AMG ' + model;*/
		// 		this.model = "AMG " + data.modelName;
		// 	})
		// }

	}

}
