<div *ngIf="data">
  <ng-container *ngIf="isPublishFeatureEnabled">
    <div class="col-12 publish-banner d-block" *ngIf="!data.raceInfo?.isShared">
      <div class="col-12 publish-banner-title">
        <span>
          {{'CP_RSP_RSPPUBLISHTITLE' | customTranslation:translatedArray}}
        </span>
        <span>
          <button class="share-button" (click)="shareRace()">
            {{'CP_RSP_RSPPUBLISHBUTTON' | customTranslation:translatedArray}}
          </button>
        </span>
      </div>
    </div>
    <div class="col-12 publish-banner d-block" *ngIf="data.raceInfo.isShared && showSuccessMsg">
      <div class="col-12 publish-banner-title">
        {{'CP_RSP_RSPPUBLISHSUCCESSMSG' | customTranslation:translatedArray}}
      </div>
    </div>
  </ng-container>

  <div class="animation">
    <div class="banner-container">
      <div class="amg-container">
        <div class="overlay-row-top">
          <div *ngIf="rankingListLink.length > 0" class="back-to-leaderboard">
            <a class="full-list-link" href="{{ rankingListLink }}" target="_blank">
              <img class="backArrow" alt="" src="./assets/images/race/back-arrow.svg" />
              <span>{{'CP_RSP_RSPOVERALLRANKING' | customTranslation:translatedArray}}</span>
            </a>
          </div>
          <div class="screen-control" (click)="controlScreen()">
            {{'CP_RSP_RSPWATCHFULLSCREEN' | customTranslation:translatedArray}}
          </div>
         
        </div>
        <div class="overlay-middle">
          <div class="banner-data d-none d-lg-block">
            
            <div class="banner-title-row">
              <div [ngClass]="{ 'animation-done': isbannerLoaded }" class="text-animator event-name-container">
                <span class="event-name-style">{{ data?.eventInfo ? data?.eventInfo.eventName : data.raceInfo.raceName }}</span>
              </div>
              <div class="banner-sub-info">
                <div [ngClass]="{ 'animation-done': isbannerLoaded }" class="event-data text-animator">
                  <span>{{ raceDate | date: 'dd/MM/YYYY' }}</span> <span>{{ raceTime }}</span><span class="add-slash"></span>
                  <span>{{ data?.trackInfo.trackName }}</span>
                </div>
              </div>
            </div>
            <div class="user-name-panel">
              <div [ngClass]="{ 'animation-done': isbannerLoaded }" class="racer-data text-animator">
                {{
                data.participantInfo.firstName + " "
                }} {{data.participantInfo?.lastName ? data.participantInfo.lastName :''}}
              </div>
            </div>
          </div>
        </div>
        <div class="racer-data-container d-block d-lg-none">
          <div class="wrapper" >
           
            <div class="banner-title-row">
              <div class="event-name-container"> <span class="event-name-style">{{ data?.eventInfo ? data?.eventInfo.eventName : data.raceInfo.raceName }}</span> </div>
              <div class="banner-sub-info">
                <div class="event-data" >
                  <span >{{ raceDate | date: 'dd/MM/YYYY' }}</span> <span>{{ raceTime }}</span><span class="add-slash"></span>
                  <span>{{ data?.trackInfo.trackName }}</span>
                </div>
              </div>
            </div>
            <div class="user-name-panel">
              <div class="racer-data">
                {{
                  data.participantInfo.firstName + " "
                  }} {{data.participantInfo?.lastName ? data.participantInfo.lastName :''}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="emotional-video-container" >
        <video id="bannerVideo" #bannerVideo *ngIf="emotionalVideo" autoplay loop playsinline id="race-video"
          class="emotional-video" [muted]="!volume">
          <source type="video/mp4" [src]="emotionalVideo?.url" />
        </video>

        <div class="video-overlay d-none d-md-block">
          <!-- <svg *ngIf="idMediaLoaderVisible" class="media-loader"
                        [inlineSVG]="'./assets/images/loader.svg'"></svg> -->
          <div class="trapezoid"></div>
        </div>
        
      </div>

      <div class="wrapper no-video" *ngIf="!emotionalVideo">
        <div style="color: #fff" class="col-12 mt-5 text-center">
          {{'CP_RSP_RSPNORACEVIDEO' | customTranslation:translatedArray}}
        </div>
      </div>

      <!-- <span class="scroll-down d-none d-lg-block" *ngIf="isscrollerVisible">
        <img class="scroll" (click)="scrollTo('race-summary-container')" alt="" src="./assets/images/scroll.png" />
        <span (click)="scrollTo('race-summary-container')">Scroll</span>
      </span> -->
    </div>

    <!-- <div class="col-12 target text-center d-none d-md-block">
      <span id="current-url" class="current-url text-center">
        {{ url }}
      </span>
      <span class="copy text-center text-md-center" (click)="copyText(url)"
        ><img src="./assets/images/copy_icon.svg" />
      </span>
    </div> -->

    <!-- <div class="target text-center d-block d-md-none">
      <div
        id="current-url"
        class="current-url text-center"
        style="
          width: 77%;
          height: 34px;
          line-height: 45px;
          margin: 0px 1rem;
          float: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 15px;
        "
      >
        {{ urlDisplay }}
      </div>
      <div style="width: 13%; float: left">
        <span
          class="copy text-center text-md-center float-left"
          (click)="copyText(url)"
        >
          <img src="./assets/images/copy_icon.svg"
        /></span>
      </div>
    </div> -->
  </div>
</div>