import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-race-media',
  templateUrl: './race-media.component.html',
  styleUrls: ['./race-media.component.css'],
})
export class RaceMediaComponent implements OnInit {
  @Input()
  data: any;

  @Input()
  emotionalVideo: any;

  @Input()
  translatedArray: any;

  isOriginalVideoVisible: boolean = true;

  isEmotionalVideoVisible: boolean = false;

  isAnimationVisible: boolean = false;
  isSelectedMediaId: number = 1;
  volume: boolean = false;
  myVideo: any;
  originalVideo: any = '';
  animation: any = '';
  selectedVideo: any;
  isIosDevice: boolean = false;
  @ViewChild('originalVideoPlayer') originalVideoPlayer!: ElementRef;
  @ViewChild('emotionalVideoPlayer') emotionalVideoPlayer:
    | ElementRef
    | undefined;
  @ViewChild('animationVideoPlayer') animationVideoPlayer:
    | ElementRef
    | undefined;

  ngOnInit(): void {
    this.isIosDevice = this.isiOS();
    this.originalVideo = this.data.videoAssetsInfo.find(
      (media: any) =>
        media.type === 'FACE_CAM_VIDEO' || media.type === 'FACECAM'
    );

    this.emotionalVideo = this.data.videoAssetsInfo.find(
      (media: any) => media.type === 'EMOTIONAL_VIDEO'
    );
    console.log(this.emotionalVideo, 'from race media');

    this.animation = this.data.videoAssetsInfo.find(
      (media: any) => media.type === 'EMOTIONAL_ANIMATION'
    );

    this.selectedVideo = {
      originalVideo: this.originalVideo,
      emotionalVideo: this.emotionalVideo,
      animationVideo: this.animation,
    };
  }

  isiOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  selectMedia(id: any) {
    this.isOriginalVideoVisible = false;
    this.isEmotionalVideoVisible = false;
    this.isAnimationVisible = false;
    this.isSelectedMediaId = id;

    if (id === 1) {
      this.isOriginalVideoVisible = true;
      this.myVideo = '';
      this.selectedVideo = this.originalVideo;
      setTimeout(() => {}, 500);
    }

    if (id === 2) {
      this.myVideo = '';
      this.isEmotionalVideoVisible = true;
      this.selectedVideo = this.emotionalVideo;

      setTimeout(() => {}, 500);
    }

    if (id === 3) {
      this.myVideo = '';
      this.isAnimationVisible = true;
      this.selectedVideo = this.animation;

      setTimeout(() => {}, 500);
    }
  }
  nextMedia() {
    if (this.isSelectedMediaId === 1) {
      this.isSelectedMediaId = 2;
      this.myVideo = '';
      this.isEmotionalVideoVisible = true;
      this.isOriginalVideoVisible = false;
      this.selectedVideo = this.emotionalVideo;

      setTimeout(() => {}, 500);
    } else if (this.isSelectedMediaId === 2) {
      this.isSelectedMediaId = 3;
      this.myVideo = '';
      this.isAnimationVisible = true;
      this.isEmotionalVideoVisible = false;
      this.selectedVideo = this.animation;

      setTimeout(() => {}, 500);
    } else if (this.isSelectedMediaId == 3) {
      this.isSelectedMediaId = 1;
      this.isOriginalVideoVisible = true;
      this.isAnimationVisible = false;
      this.myVideo = '';
      this.selectedVideo = this.originalVideo;
      setTimeout(() => {}, 500);
    }
  }
  previousMedia() {
    if (this.isSelectedMediaId === 1) {
      this.isSelectedMediaId = 3;
      this.myVideo = '';
      this.isAnimationVisible = true;
      this.isOriginalVideoVisible = false;
      this.selectedVideo = this.animation;

      setTimeout(() => {}, 500);
    } else if (this.isSelectedMediaId === 2) {
      this.isSelectedMediaId = 1;
      this.isOriginalVideoVisible = true;
      this.isEmotionalVideoVisible = false;
      this.myVideo = '';
      this.selectedVideo = this.originalVideo;

      setTimeout(() => {}, 500);
    } else if (this.isSelectedMediaId == 3) {
      this.isSelectedMediaId = 2;
      this.myVideo = '';
      this.isEmotionalVideoVisible = true;
      this.isAnimationVisible = false;
      this.selectedVideo = this.emotionalVideo;

      setTimeout(() => {}, 500);
    }
  }

  downloadMedia(videoUrl: any, name: string) {
    const url = videoUrl.url;
    const anchor = document.createElement('a');
    anchor.download = name;
    anchor.href = url;
    anchor.click();
  }
}
