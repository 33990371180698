<div class="emotional-data-container">
	<div class="title">
		<img width="10" [src]="" *ngIf="" /> {{title}}
	</div>
	<div class="emotional-value row">		
		<div class=".col-9 unit-style
		.col-sm-6
		.col-md-10
		.col-lg-6
		.col-xl-10">{{value}}</div>
		<div class="emotional-data-unit .col-3
		.col-sm-6
		.col-md-10
		.col-lg-6
		.col-xl-10">
			<!-- <div>{{unit}}</div> -->
		<div *ngIf = "unit === 'c' || unit === 'C'">°C</div>
		<div *ngIf = "unit !== 'c' && unit !== 'C'">{{unit}}</div>
		</div>		
	</div>
</div>