import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonModalServiceService {

  _modalHeaderTitle = new BehaviorSubject<any>(null);
  modalHeaderTitle$ = this._modalHeaderTitle.asObservable();

  _modalHeaderIsCloseBtnVisible = new BehaviorSubject<any>(false);
  modalHeaderIsCloseBtnVisible$ = this._modalHeaderIsCloseBtnVisible.asObservable();

  _modalHeaderIsSaveBtnVisible = new BehaviorSubject<any>(false);
  modalHeaderIsSaveBtnVisible$ = this._modalHeaderIsSaveBtnVisible.asObservable();




  updateHeaderTitle(title: string) {
    this._modalHeaderTitle.next(title);
  }

  updateCloseBtnVisiblity(isVisible: boolean) {
    this._modalHeaderIsCloseBtnVisible.next(isVisible);
  }

  updateSaveBtnVisiblity(isVisible: boolean) {
    this._modalHeaderIsSaveBtnVisible.next(isVisible);
  }


}
