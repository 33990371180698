import { Component } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
@Component({
  selector: 'app-race-share-error-handling',
  templateUrl: './race-share-error-handling.component.html',
  styleUrls: ['./race-share-error-handling.component.scss']
})
export class RaceShareErrorHandlingComponent {
  translationData: any;
  constructor(private translationService: TranslationService){}
  ngOnInit(){
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

}
