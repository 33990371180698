import { Component, Input } from '@angular/core';
import { RaceInfo } from 'src/app/components/interfaces/race-share.interface';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-telemetric-data-card',
  templateUrl: './telemetric-data-card.component.html',
  styleUrls: ['./telemetric-data-card.component.scss']
})
export class TelemetricDataCardComponent {
@Input() raceInfo!: RaceInfo;
translationData: any;
driftScore!: number;
@Input() isDriftChallenge: boolean = false;
avgDriftAngle!: number;
maxDriftSpeed!: number;
driftDistance!: number;
private OdometerObserver!: IntersectionObserver;
odometerConfig = { auto: false, format: '(,ddd).ddd' , duration: 3000};
odometerConfigmaxSpeed = { auto: false , duration: 3000};
odometerConfigTireTempAndmaxBrakeTorque = {auto: false, format: '(,ddd).dd', duration: 3000};
odometerConfigavgDriftAngle = {auto: false, format: '(,ddd).d' , duration: 3000}

constructor(private translationService: TranslationService){}
ngAfterViewInit(): void {
  const target = document.getElementById('stats-info-bar');

  this.OdometerObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0.5) {
        console.log('Target div is more than half in the viewport');
        this.odometerConfig = { ...this.odometerConfig, auto: true };
        this.odometerConfigmaxSpeed = { ...this.odometerConfigmaxSpeed, auto: true };
        this.odometerConfigavgDriftAngle = { ...this.odometerConfigavgDriftAngle, auto: true };
        this.odometerConfigTireTempAndmaxBrakeTorque = { ...this.odometerConfigTireTempAndmaxBrakeTorque, auto: true };
      } else {
        console.log('Target div is less than half in the viewport');
        this.odometerConfig = { ...this.odometerConfig, auto: false };
        this.odometerConfigmaxSpeed = { ...this.odometerConfigmaxSpeed, auto: false };
        this.odometerConfigavgDriftAngle = { ...this.odometerConfigavgDriftAngle, auto: false };
        this.odometerConfigTireTempAndmaxBrakeTorque = { ...this.odometerConfigTireTempAndmaxBrakeTorque, auto: false };
      }
    });
  }, {
    threshold: [0, 0.5, 1]
  });

  if (target) {
    this.OdometerObserver.observe(target);
  }
}


ngOnInit(){
  this.translationService.getTranslationDataAsObservable().subscribe(
    (translationData: any) => {
      this.translationData = translationData;
    }
  )


  this.driftScore = this.raceInfo.driftScore ?? 0;
  this.avgDriftAngle = this.raceInfo.maxValues.avgDriftAngle ?? 0;
  this.maxDriftSpeed = this.raceInfo.maxValues.maxDriftSpeed ?? 0;
  this.driftDistance = this.raceInfo.maxValues.totalDriftDistance ?? 0;

}
ngOnDestroy(): void {
  if (this.OdometerObserver) {
    this.OdometerObserver.disconnect();
  }
}
}
