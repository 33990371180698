<div id="usr-ptnr-inf">
    <div id="usr-ptnr-avtr">
        <figure class="usr">
            <img *ngIf="profileImage" [src]="profileImage">
            <div *ngIf="!profileImage" class="initals">
                <span>{{profileInitials}}</span>
            </div>
        </figure>
        <figure class="bis" *ngIf="businessOwner?.profileImage && subType ==='EVENT' ">
            <img *ngIf="businessOwner.profileImage " [src]="businessOwner.profileImage">
            <div *ngIf="!businessOwner.profileImage" class="initals">
                <span>{{businessOwnerInitials}}</span>
            </div>
        </figure>
    </div>
    <div id="usr-ptnr-txt">
        <span class="rc-prt">{{experienceOwner}}</span>
        <span *ngIf="subType ==='PERSONAL'">{{'RSP_VEHICLE_SHAREEXPERIENCE' | customTranslation: translationData}}</span>
        <span *ngIf="businessOwner?.Name && subType ==='EVENT'">{{'RSP_EVENT_OWNER_WITH_TITLE' | customTranslation: translationData}}</span>
        <span class="rc-prt">{{subType ==='EVENT' ? businessOwnerName : ''}}</span>
    </div>
</div>
