import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AwsAuthService } from './services/aws-auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private aws: AwsAuthService) {}
  accessToken:any
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.accessToken=this.aws.getCurrentUserToken()
    const isAusApi= request.url.startsWith(environment.aesBaseUrl);
    const isApiUrl = request.url.startsWith(environment.serverUrl+'api/v2');
    const isAesApi = request.url.startsWith(environment.ausBaseUrl);
    if ((isApiUrl || isAusApi || isAesApi) && this.accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.accessToken.access_token}`
        }
      });
    }
    return next.handle(request);
  }
}
