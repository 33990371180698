import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslationService } from './services/translation.service';
import { environment } from 'src/environments/environment';
import { LanguageService } from './services/language.service';
import { Router } from '@angular/router';
import { ToastService } from './services/toast.service';
import { AppServicesService } from './services/app-services.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'route';
  language = 'en-GB'
  translatedArray = [];
  showToast = false;
  toastMessage: any;
  toastType: any;
  position: any;
  leftPosition: any;  
  noAnimation : boolean = false;
  featureFlags: any;
  @ViewChild("scrollElement") scrollElement: ElementRef | any;
  showLangPicker: boolean = false;


  constructor(private translationService: TranslationService, 
    private languageService: LanguageService, 
    private router: Router,
    private toastService: ToastService,
    private _appService:AppServicesService
  ){
    const fullURL = window.location.href.split('/');
    const languageIdFromUrl=  window.location.href.split('/')[3]
    


    if (languageService.checkIsValideLanguage(fullURL[3])) {
      localStorage.setItem('language', fullURL[3]);      
    } 
   
   const selectedLang = this.languageService.getLanguage();
    this.language = selectedLang;
    if (languageIdFromUrl && languageService.checkIsValideLanguage(languageIdFromUrl) ||
      (fullURL.includes('logout') || fullURL.includes('login') ||  fullURL.some((url: string) => url.includes('login')) ||
       fullURL.includes('verify') || fullURL.includes('anonymize')) || fullURL.includes('stories')) {
      if (selectedLang) {
        this.language = selectedLang;
      } else {
        this.language = environment.supported_language[0];
        localStorage.setItem('language', this.language);
      }
    } else if (fullURL[3] !== '') {
      this.router.navigateByUrl('/' + this.language + '/404');
    } else if (fullURL[3] === '') {
      this.router.navigateByUrl('/' + this.language + '/home');
    }
  }

  ngOnInit(): void {   
    const isLanguagePresent = this.router.url.split('/')[1] && this.languageService.checkIsValideLanguage(this.router.url.split('/')[1]); 
    const selectedLang = isLanguagePresent ? this.router.url.split('/')[1] : this.language;
    this.getTranslations(selectedLang);
    this.featureFlags = this.translationService.featureFlags;
    this.getToastData();
    let videoStatus = sessionStorage.getItem('videoplayed');

    if(videoStatus === 'true'){
        this.noAnimation = true;
    }

    this._appService.showLanguageSelectionPage$.subscribe((isShowLangPicker)=>{
      this.showLangPicker = isShowLangPicker;
    });

    this.translationService.getAllSupportedLanguages().subscribe((supportedLanguages: any)=>{
     this.translationService.supportedLanguagesForDisplay = supportedLanguages;
    })
  }

  getToastData(){
    this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
  }

  /**
   * @description We are using this method to pass a valid language to translations API
   * If user has added any invalid language, pass the default language
   * @param locale 
   * @returns 
   */
  public isLangIdValid(locale:any): string {
     if(environment.supported_language.includes(locale)){
      return locale
     }else{
      return 'en-GB'
     }
  }

  getTranslations(languageId: string) {
    languageId=this.isLangIdValid(languageId)
    this.translationService
      .phraseApiCallForTranslations(languageId)

      .subscribe((allDeTranslationBasedOnlocle) => {   
        for(let i=0; i< allDeTranslationBasedOnlocle.length; i++) {
          this.translatedArray = this.translatedArray.concat(allDeTranslationBasedOnlocle[i]);
        }
        this.translationService.setTranslationData(this.translatedArray);
        this.translationService.sendSelectedLanguage(this.language);
        this.translationService.sendtranslationValue(this.translatedArray);
      });
  }

  scrollTop(event: any) {
		this.scrollElement.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
	}

}
