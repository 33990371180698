import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppServicesService } from 'src/app/services/app-services.service';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-anonymize-popup',
  templateUrl: './anonymize-popup.component.html',
  styleUrls: ['./anonymize-popup.component.scss']
})
export class AnonymizePopupComponent implements OnInit {

  @Output() closeAnonymizationPopup: EventEmitter<boolean> = new EventEmitter();
  loading: boolean = false;
  isAnonymizeCalled = false;
  isFormSubmitted = false;
  isAnonymizeSuccess = false;
  isAnonymizeFailed = false;
  isAlreadyAnonimized = false;
  anonymizeInApp = false;
  accessTokenData = { access_token: '' };
  language: string = 'en-GB';
  translationData = [];
  showSpinner: boolean = false;
  isParticipantNotFound: boolean = false;
  isDifferentUserLinked: boolean = false;

  constructor(private appService: AppServicesService, private awsAuth: AwsAuthService,
    private languageService: LanguageService, private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();

    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.accessTokenData = this.awsAuth.getCurrentUserToken();
    if (this.awsAuth.getUserLoginStatus()) {
      this.accessTokenData = this.awsAuth.getCurrentUserToken();
    } else {
      this.awsAuth.currentSession()?.then(
          (_res) => {
            let accessToken = _res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            let jwt_expires_in = accessToken.getExpiration();
            let refreshToken = _res.getRefreshToken();
            let jwtRefreshToken = refreshToken.getToken();
            let idToken = _res.getIdToken();
            let jwtIdToken = idToken.getJwtToken();
            let fullData = {
              id_token: jwtIdToken,
              access_token: jwt,
              refresh_token: jwtRefreshToken,
              expires_in: jwt_expires_in,
              token_type: "Bearer",
            };
            this.awsAuth.setUserLoginStatus(true);
            this.awsAuth.setCurrentUserToken(fullData);
            this.accessTokenData = this.awsAuth.getCurrentUserToken();
          }
      );
    }
  }

  cancelAnonymization() {
    if(!this.isFormSubmitted) {
      sessionStorage.removeItem('anonymizeParticipant');
      this.closeAnonymizationPopup.emit(true);
      this.scrollToTop();
    }
  }

  closePopup() {
    sessionStorage.removeItem('anonymizeParticipant');
    this.closeAnonymizationPopup.emit(true);
  }

  anonymization() {
    this.isFormSubmitted = true;
    const participantDetails = sessionStorage.getItem('anonymizeParticipant');
    sessionStorage.removeItem('anonymizeParticipant');
    if (participantDetails) {
      const data = JSON.parse(participantDetails);
      const accessToken = this.accessTokenData.access_token;
      this.showSpinner = true;
      this.appService.anonymize(data?.participantId, data?.participantKey, accessToken).subscribe(
        (data) => {
          this.isAnonymizeCalled = true;
          this.isAnonymizeSuccess = true;
          this.showSpinner = false;
          this.scrollToTop();
        },
        (error = HttpErrorResponse) => {
          this.showSpinner = false;
          this.isAnonymizeCalled = true;
          switch (error.error['error-code']) {
            case '1409': {
              this.isAnonymizeFailed = true;
              this.isAlreadyAnonimized = false;
              break;
            }
            case '1403': {
              this.isAnonymizeFailed = true;
              this.isAlreadyAnonimized = true;
              this.isParticipantNotFound = true;
              break;
            }
            case '1803': {
              this.isAnonymizeFailed = true;
              this.isAlreadyAnonimized = true;
              this.isDifferentUserLinked = true;
              break
            }
            case '1411': {
              this.isAnonymizeFailed = true;
              this.isAlreadyAnonimized = false;
              this.anonymizeInApp = true;
              break
            }
            default: {
              this.isAnonymizeFailed = true;
              this.isAlreadyAnonimized = true;
              this.isParticipantNotFound = false;
            }
          }
          this.scrollToTop();
        }
      )
    }

  }

  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    
    })
  }

}
