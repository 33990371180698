import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

interface participantDetails {
  participantId: string, participantKey: string
}

@Component({
  selector: 'app-anonymize',
  templateUrl: './anonymize.component.html',
  styleUrls: ['./anonymize.component.scss']
})
export class AnonymizeComponent implements OnInit {

  participantInfo: string = '';
  partcipantDetails:participantDetails = {participantId: '', participantKey: ''};
  language = 'en-GB'
  public participantDataToAnonymize: string = '';
  public isPageNotFound: boolean = false;
  translationData: any;
  isUserCiamLoggedIn: boolean = false;

  constructor(
    private route: ActivatedRoute, 
    private awsAuth: AwsAuthService, 
    private router: Router,
    private languageService: LanguageService,
    private _translationService: TranslationService
  ) {
    const participantId = this.route.snapshot.paramMap.get('partipationInfo');
    localStorage.setItem('routeUrl', 'stories/anonymize/'+participantId);
    this.language = this.route.snapshot.paramMap.get('languageId') || this.language; // Provide a default value of an empty string if languageId is null
    if (this.language && this.languageService.checkIsValideLanguage(this.language)) {
      this.isPageNotFound = false
    } else {
      this.isPageNotFound = true;
    }
   }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.route?.params?.subscribe((param) => {
      const partipationInfo = param['partipationInfo'];
  if (this.isValidBase64(partipationInfo)) {
    try {
      this.participantInfo = atob(partipationInfo);
      let participantInfo = this.participantInfo.split('/');
      this.partcipantDetails.participantId = participantInfo[0];
      this.partcipantDetails.participantKey = participantInfo[1];
    } catch (error) {
      console.log('Error while decoding participantInfo :>> ', error);
      this.isPageNotFound = true;
    }
  } else {
    console.log('partipationInfo is not a valid Base64 string');
    this.isPageNotFound = true;
  }
    });

    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    
    sessionStorage.setItem('anonymizeParticipant', JSON.stringify(this.partcipantDetails));

    if(this.awsAuth.getUserLoginStatus()) {
      // this.router.navigateByUrl('/'+this.language+'/home');
      this.triggerAnonymize();
    } else {
      this.awsAuth.currentSession().then(
          (_res) => {
            let accessToken = _res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            let jwt_expires_in = accessToken.getExpiration();
            let refreshToken = _res.getRefreshToken();
            let jwtRefreshToken = refreshToken.getToken();
            let idToken = _res.getIdToken();
            let jwtIdToken = idToken.getJwtToken();
            let fullData = {
              id_token: jwtIdToken,
              access_token: jwt,
              refresh_token: jwtRefreshToken,
              expires_in: jwt_expires_in,
              token_type: "Bearer",
            };
            this.awsAuth.setUserLoginStatus(true);
            this.awsAuth.setCurrentUserToken(fullData);
            this.triggerAnonymize();
          },
          (_err) => {
            console.log(_err);
            this.awsAuth.signIn();
          }
      );
    }
  }

  private isValidBase64(str: string) {
    try {
      /** 
       * This code is ensuring that the input string str is properly padded to be a valid Base64 string.
       * Adds = for padding if the string length is not a multiple of 4.
      */
      while (str.length % 4) {
        str += '=';
      }
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  private triggerAnonymize() {
    const isUserLoggedIn = localStorage.getItem('useLogged');
    if (!isUserLoggedIn || isUserLoggedIn.toString() !== 'true') {
      this.isUserCiamLoggedIn = false;
    } else {
      this.isUserCiamLoggedIn = true;
    }
    const anonymizeParticipantData = sessionStorage.getItem('anonymizeParticipant');
    if (anonymizeParticipantData) {
      this.participantDataToAnonymize = anonymizeParticipantData;      
    }
  }

  public closeAnonymizationPopup(status: boolean) {
    this.participantDataToAnonymize = '';
    
  }

}
