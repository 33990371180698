import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProdEnabledGuard implements CanActivate {
  enabledPath = [
    'coming-soon',
    'login',
    '/stories/anonymize',
    '/stories/link-participant',
    '/stories/processing',
    '/stories/verify',
    'my-race-details',
    '/stories/experience',
  ];

  constructor(private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!environment.production) {
      return true;
    } else if(this.enabledPath.some(path => state.url.includes(path))) {
      return true;
    } else {
      this.router.navigateByUrl('coming-soon');
      return false;
    }
  }
  
}
