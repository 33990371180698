<div id="stats-info-bar" *ngIf="!isDriftChallenge" >
    <div class="stats-info-item">
        <span class="stats-title">{{'NRSP_TOP_SPEED' | customTranslation: translationData}}</span>
        <h2 class="stats-value"><ng9-odometer [number]="raceInfo.maxValues.maxSpeed" [theme]="''" [config]="odometerConfigmaxSpeed"></ng9-odometer><span class="v-units">km/h</span></h2>
    </div>
    <div class="stats-info-item">
        <span class="stats-title">{{'NRSP_MAX_GFORCE' | customTranslation: translationData}}</span>
        <h2 class="stats-value"><ng9-odometer [number]="raceInfo.maxValues.maxGForce" [theme]="''" [config]="odometerConfig"></ng9-odometer><span class="v-units">g</span></h2>
    </div>
    <div class="stats-info-item" *ngIf="raceInfo.maxValues.maxTireTemp !== null && raceInfo.maxValues.maxTireTemp !== undefined">
        <span class="stats-title">{{'NRSP_MAX_TIRE_TEMP' | customTranslation: translationData}}</span>
        <h2 class="stats-value"><ng9-odometer [number]="raceInfo.maxValues.maxTireTemp" [theme]="''" [config]="odometerConfigTireTempAndmaxBrakeTorque"></ng9-odometer><span class="v-units">°C</span></h2>
    </div>
    <div class="stats-info-item" *ngIf="raceInfo.maxValues.maxBrakeTorque !== null && raceInfo.maxValues.maxBrakeTorque !== undefined">
        <span class="stats-title">{{'NRSP_MAX_BRAKE_TORQUE' | customTranslation: translationData}}</span>
        <h2 class="stats-value"><ng9-odometer [number]="raceInfo.maxValues.maxBrakeTorque" [theme]="''" [config]="odometerConfigTireTempAndmaxBrakeTorque"></ng9-odometer><span class="v-units">Nm</span></h2>
    </div>
</div>


<div id="stats-info-bar" *ngIf="isDriftChallenge">
  <div class="stats-info-item">
      <span class="stats-title">{{'RSP_DRIFT_SCORE' | customTranslation: translationData}}</span>
      <h2 class="stats-value"><ng9-odometer [number]="driftScore" [theme]="''" [config]="odometerConfigmaxSpeed"></ng9-odometer></h2>
  </div>
  <div class="stats-info-item">
      <span class="stats-title">{{'RSP_DRIFT_ANGLE' | customTranslation: translationData}}</span>
      <h2 class="stats-value"><ng9-odometer [number]="avgDriftAngle" [theme]="''" [config]="odometerConfigavgDriftAngle"></ng9-odometer><span class="v-units">°</span></h2>
  </div>
  <div class="stats-info-item" *ngIf="maxDriftSpeed !== null && maxDriftSpeed !== undefined">
      <span class="stats-title">{{'RSP_DRIFT_SPEED' | customTranslation: translationData}}</span>
      <h2 class="stats-value"><ng9-odometer [number]="maxDriftSpeed" [theme]="''" [config]="odometerConfigTireTempAndmaxBrakeTorque"></ng9-odometer><span class="v-units">km/h</span></h2>
  </div>
  <div class="stats-info-item" *ngIf="driftDistance !== null && driftDistance !== undefined">
      <span class="stats-title">{{'RSP_DRIFT_DISTANCE' | customTranslation: translationData}}</span>
      <h2 class="stats-value"><ng9-odometer [number]="driftDistance" [theme]="''" [config]="odometerConfigTireTempAndmaxBrakeTorque"></ng9-odometer><span class="v-units">m</span></h2>
  </div>
</div>
