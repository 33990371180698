
<div class="content" role="main">

    <div *ngIf="this.annotationsData && !showNotFound">
        <app-emotion-tour-summary [trackClusters]="this.annotationsData" [clusterData]="emotionTourData"></app-emotion-tour-summary>
     </div>
     <div *ngIf="showNotFound">
        <app-not-found-page></app-not-found-page>
    </div>
</div>

