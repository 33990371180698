import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/components/interfaces/myStories.interface';
import { AppServicesService } from 'src/app/services/app-services.service';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-no-vehicles',
  templateUrl: './no-vehicles.component.html',
  styleUrls: ['./no-vehicles.component.scss']
})
export class NoVehiclesComponent implements OnInit {

  isShowSpinner: boolean = false;
  carsDetails: any;
  translationData = [];
  @Input() profileDetails!: UserInfo;
 
 
 
   constructor( private appService: AppServicesService, 
    private translationService: TranslationService,
   ) { }
 
   ngOnInit(): void {
 // Empty method ngOninit
 this.translationService.getTranslationDataAsObservable().subscribe(
  (translationData: any) => {
    this.translationData = translationData;
  }
)
   }

}
