<div id="language-selection-pop">
    <div (click)="closeLanguagePicker()" id="close-btn">
        <span class="icon icon_close"></span>
    </div>
<div class="pg-inner-pop">
    <div class="pg-title">
        <h2>{{'CP_LM_LANDINGPAGE_SELECTLANG' | customTranslation: translationData}}</h2>
    </div>
    
    <div class="language-selection">
        <!-- <div class="langage-list country">
            <h5>
                <span>Your region</span> · 
                <span>Switzerland</span>
            </h5>
            <ul class="language">
                <li><a><span>French</span></a></li>
                <li><a><span>German</span></a></li>
                <li><a><span>Italian</span></a></li>
                <li><a><span>English (UK)</span></a></li>
            </ul>
        </div> -->
        <div class="langage-list global">
            <h5>
                <span>{{'CP_LM_LANDINGPAGE_ALLANG' | customTranslation: translationData}}</span> 
            </h5>
            <ul class="language">
                <li class="clm-3 clm-tab-8" *ngFor="let language of allLanguages">
                    <a><span  class="enable-selection" id="locale-desc" (click)="switchLanguage(language.localeCode)"> {{ language.localeDescription }} </span></a>
                </li>
            </ul>
        </div>
    </div>
</div>
</div>

