import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Auth, Hub} from "aws-amplify";
import { AuthAmplifyService } from '../../services/auth-service/amplify-auth-service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class AccessDeniedComponent implements OnInit {

  constructor(private authAmplifyService: AuthAmplifyService) {

	}

  ngOnInit(): void {
    this.login();
  }

  login() {
    let language = localStorage.getItem('language');
    let raceId = localStorage.getItem('raceId')
    if(raceId){
      localStorage.setItem('routeUrl', language+'/stories/experience/'+raceId);
    }
    this.authAmplifyService.signIn();

  }
}
