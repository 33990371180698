<div class="map-container" #mapContainer>
    <div class="text-overlay">

        <div class="info-panel-bottom">
            <!-- Event Name -->
            <span class="event-title">{{trackDesc.trackName}}</span>
            <div class="rowele">
                <!-- lap Count -->
                <app-tags-component [tagClass]="'tag-red tag'" *ngIf="raceItemType === 'TRACKRACE'" [tagTitle]="'Track Pace'"></app-tags-component>
                <!-- Race start time -->
                <app-tags-component *ngIf="!!trackDesc.distance" [tagClass]="'tag'" [tagTitle]="trackDesc.distance +' ' +'km'"></app-tags-component>

                <!-- time and date -->
                <app-tags-component *ngIf="!!noOfSectors" [tagClass]="'tag'" [tagTitle]="noOfSectors === 1 ? noOfSectors + ' sector' : noOfSectors + ' sectors'"></app-tags-component>
            </div>
        </div>
    </div>
    <div id="map">
</div>
