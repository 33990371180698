import { Injectable } from '@angular/core';
declare var createUnityInstance: any;
declare let TourMapAPI: any;

@Injectable({
  providedIn: 'root'
})
export class TourMapService {

  constructor() { 
    // This is intentional
  }

  returnMapVariable() {
    return TourMapAPI;
  }

  createMap(mapInstance:any){
    const buildUrl = 'assets/unity/Build/';

    return createUnityInstance(mapInstance, {
      dataUrl: buildUrl + 'TourMap.data.unityweb',
      frameworkUrl: buildUrl + 'TourMap.framework.js.unityweb',
      codeUrl: buildUrl + 'TourMap.wasm.unityweb',
      streamingAssetsUrl: 'StreamingAssets',
      companyName: 'MBition',
      productName: 'TourMap',
      productVersion: '0.1'
    })      
  }
}
