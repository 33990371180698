import { Component, OnDestroy, OnInit } from "@angular/core";
import { interval } from "rxjs";

import { Auth } from "aws-amplify";
import { AwsAuthService } from "src/app/services/aws-auth.service";

@Component({
  selector: "app-refresh-token",
  template: ``,
  styles: [],
})
export class RefreshTokenComponent implements OnInit, OnDestroy {
  subscribtionVar: any;

  constructor(private authAmplifyService: AwsAuthService) {}

  ngOnInit(): void {
    const source = interval(300);
    this.subscribtionVar = source.subscribe((_val) => {
      const tokenData = this.authAmplifyService.getCurrentUserToken();
      if (tokenData) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (tokenData.expires_in - currentTime <= 1/100) {
          this.refreshSession();
        }
      }
    });
  }

  refreshSession() {
    Auth.currentSession().then((_res) => {
      let accessToken = _res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      let jwt_expires_in = accessToken.getExpiration();
      let refreshToken = _res.getRefreshToken();
      let jwtRefreshToken = refreshToken.getToken();
      let idToken = _res.getIdToken();
      let jwtIdToken = idToken.getJwtToken();
      let fullData = {
        id_token: jwtIdToken,
        access_token: jwt,
        refresh_token: jwtRefreshToken,
        expires_in: jwt_expires_in,
        token_type: "Bearer",
      };
      this.authAmplifyService.setUserLoginStatus(true);
      this.authAmplifyService.setCurrentUserToken(fullData);
    });
  }

  ngOnDestroy(): void {
    this.subscribtionVar.unsubscribe();
  }
}
