<!-- <div class="car-details-container">
	<div class="amg-container full-width">
		<div class="row">
			<div class="d-flex d-lg-none col-12 car-data text-center">
				<div class="rider-name col-12 text-center">{{data?.participantInfo.firstName}}'s Ride</div>
			</div>
			<div class="col-12 col-lg-6" *ngIf="data?.vehicleInfo">
				<div class="car-animation-container" 
					[ngClass]="{'show-car': isCarVisible}">
					<div class="triangle"> 
						<img class="car-image" [src]="data?.vehicleInfo.vehicleImageUrl" />
					</div>
				</div>
			</div>
			<div [ngClass]="{'col-lg-12':carData, 'col-lg-6 data-centered': !carData}" class="d-none d-lg-flex car-data">
				<div class="rider-name" id="car-image-container">{{data?.participantInfo.firstName}}'s Ride</div>
			</div>
		</div>
	</div>
</div> -->
<div class="amg-container full-width">
  <div class="row">
    <!--
    <div class="d-flex d-lg-none col-12 car-data text-center">
      <div class="rider-name col-12 text-center">
        {{ data?.participantInfo.firstName | uppercase }}{{'rspApostrophe' | customTranslation:translatedArray}} {{'rspRide' | customTranslation:translatedArray}}
      </div>
    </div>
    -->
    <div class="user-car-sec">
      <div class="user-car-prof" style="padding: 0" *ngIf="data?.vehicleInfo">
        <!-- <div class="background-triangle">
          <img class="car-image" [src]="data?.vehicleInfo.vehicleImageUrl" />
        </div> -->
        <div class="background-triangle" [ngClass]="{ 'show-car': isCarVisible }">
            <img class="car-image-prop" [src]="data?.vehicleInfo.vehicleImageUrl" />
        </div>
      </div>
      <div class="user-name">
        <div class="rider-name" id="car-image-container">
          <div class="rider-font">{{'RSP_CUSTOMER_RIDE_WITH_NAME' | customTranslation: {param: data?.participantInfo.firstName }:translatedArray }}</div>
          <!-- <div>{{ data?.participantInfo.firstName | uppercase }}{{'rspApostrophe' | customTranslation:translatedArray}}</div>  -->
          <!-- <div>{{'rspRide' | customTranslation:translatedArray}}</div> -->
        </div>
      </div>
    </div>
  </div>
</div>
