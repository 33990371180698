import { Injectable } from "@angular/core";
import Dexie from "dexie";
import { AppServicesService } from "src/app/services/app-services.service";

@Injectable({
  providedIn: "root",
})
export class TourDbService extends Dexie {

  constructor(private appService: AppServicesService) {
    super("DexieDB"); //database name 'DexieDB'
    this.openVersion();
  }
  openVersion() {
    this.version(1).stores({
      myTrackStore:"trackId",
      participantResults:"participantId"
    });
  }
  openDb() {
    this.open() //opening the database
      .then((data) => console.log("DB Opened"))
      .catch((err) => console.log(err.message));
  }

  async addTour(obj: any) {
    await this.table("myTrackStore")
      .add(obj)
      .then((data) => console.log('tour data',data))
      .catch((err) => console.log(err.message));
  }

  async getTour(id:any) {
    let tourData
    await this.table("myTrackStore")
      .get({ trackId: id })
      .then((data) => {
        tourData = data;
      })
      .catch((err) => console.log(err.message));
      return tourData
  }

  async updateTour(key: any, obj: any) {
    await this.table("myTrackStore")
      .update(key, obj)
      .then((data) => console.log(data))
      .catch((err) => console.log(err.message));
  }

  async resetTourDatabase(tableName:any) {
    await this.table(tableName)
      .clear();
  }

  async addParticipant(obj: any) {
    await this.table("participantResults")
      .add(obj)
      .then((data) => console.log('Participant Data added successfully!'))
      .catch((err) => console.log(err.message));
  }

  async getParticipant(id: string) {
    let participantData
    await this.table("participantResults")
      .get({ participantId: id })
      .then((data) => {
        console.log("Participant Details are present for the ID");
        participantData = data;
      })
      .catch((err) => console.log(err.message));
      return participantData
  }

  async updateParticipant(key: any, obj: any) {
    await this.table("participantResults")
      .update(key, obj)
      .then((data) => console.log("Participant details updated successfully!"))
      .catch((err) => console.log(err.message));
  }


}
