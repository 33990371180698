<div class="car-stories-sharing" *ngIf="!isLoading; else showSpinner">
  <div class="stories-sharing-section" *ngFor="let year of stories;">
    <div class="year">
      <small>{{year[0]}}</small>
    </div>
    <ul class="stories">
      <li class="story" *ngFor="let storyCard of year[1] | keyvalue">
        <div class="story-inner">
          <app-story [story]="storyCard.value"></app-story>
        </div>
      </li>
    </ul>
  </div>
</div>

<ng-template #showSpinner>
  <div class="loader-container">
    <app-spinner-component></app-spinner-component>
  </div>
</ng-template>