<div id="race-info-main">
  <app-event-owner-details *ngIf="experienceOwnerName" [experienceOwner]="experienceOwnerName"
    [profileInitials]="profileInitials" [businessOwner]="eventDetails.businessOwnerInfo"
    [profileImage]="participantProfileImage" [subType]="subType" [itemType]="itemType">
  </app-event-owner-details>
  <app-event-name [subType]="subType" [eventName]="
      eventDetails.eventInfo && eventDetails.eventInfo.eventName
        ? eventDetails.eventInfo.eventName
        : ''
    " [raceName]="raceName"></app-event-name>
  <div id="race-eve-tag-list" class="tag-area">
    <span><app-tags-component [tagClass]="'tag-red tag'" *ngIf="isBestTime" [tagTitle]="'Best Time Challenge'">
      </app-tags-component>
      <app-tags-component [tagClass]="'tag-red tag'" *ngIf="isDriftChallenge" [tagTitle]="'Drift Challenge'">
      </app-tags-component>
      <app-tags-component *ngIf="subType === 'PERSONAL'" [tagClass]="'tag-red tag'"
        [tagTitle]="lapCount === 1 ? lapCount + ' lap' : lapCount + ' laps'"></app-tags-component></span>
    <span><app-tags-component [tagClass]="'tag'" *ngIf="
          !!eventDetails.raceInfo.raceTime &&
          eventDetails.raceInfo.raceTime >= 60 &&
          eventDetails.raceInfo.raceTime < 600000
        " [tagTitle]="
          (eventDetails.raceInfo.raceTime * 1000 | date : 'm:ss.SS' : 'UTC') +
          ' min'
        "></app-tags-component>
      <app-tags-component [tagClass]="'tag'" *ngIf="
          !!eventDetails.raceInfo.raceTime &&
          eventDetails.raceInfo.raceTime >= 600000
        " [tagTitle]="
          (eventDetails.raceInfo.raceTime * 1000 | date : 'mm:ss.SS' : 'UTC') +
          ' min'
        "></app-tags-component></span>
    <span>
      <app-tags-component *ngIf="!!eventDetails.raceInfo.startTime" [tagClass]="'tag'"
        [tagTitle]="eventDetails.raceInfo.startTime | date : 'dd.MM.yyyy'"></app-tags-component>
    </span>
    <span><app-tags-component [tagClass]="'tag'" *ngIf="!!eventDetails.vehicleInfo.vehicleModel"
        [tagTitle]="eventDetails.vehicleInfo.vehicleModel"></app-tags-component></span>
    <!-- <span>Best Time Challenge</span>
        <span>1:44.27 min</span>
        <span>12.08.2024</span>
        <span>Mercedes-AMG GT 63 S 4MATIC+</span> -->
  </div>
  <app-event-description *ngIf="subType === 'EVENT'" [eventDescription]="
      eventDetails.eventInfo && eventDetails.eventInfo.eventDescription
        ? eventDetails.eventInfo.eventDescription
        : ''
    "></app-event-description>
</div>
