import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppServicesService } from 'src/app/services/app-services.service';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-cluster-card',
  templateUrl: './cluster-card.component.html',
  styleUrls: ['./cluster-card.component.scss']
})
export class ClusterCardComponent implements OnInit {

  @Input() public clustername:any;
  @Input() public index:any;
  @Output() public sendMouseStatus = new EventEmitter<any>();
  sampleDesc = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
             Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi`
  imageData: any;

  translationData = [];
  
  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  addHeightAnimation(){
    if(this.clustername?.description){
      this.sendMouseStatus.emit('mouseover')
    }
  }

  removeHeightAnimation() {
    if(this.clustername?.description){
      this.sendMouseStatus.emit('mouseout')
    }

  }

  returnBackgroundImage(type:any){
    switch(type){
      case 'CIRCUIT':
        return '../../../assets/images/circuit-cover.png';      
      case 'EMOTION_TOUR':
        return '../../../assets/images/tour-cover.png';
      case 'OFFROAD_TOUR':
        return '../../../assets/images/offroad-cover.png';
      default:
        return '../../../assets/images/circuit-cover.png';

    }
  }



}
