import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  @Input() public message: string = "Cluster not available for the tour ! "
  translationData = []
  @Input() contentNotFound: boolean = false;
  
  constructor(private translationService: TranslationService) {}

  @HostListener('window:popstate', ['$event'])
  onPopState(event:any) {
    localStorage. removeItem('filter');

  }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  refreshPage(): void {
    window.location.reload();
  }

}
