<div class="race-media-container">
  <div class="title-row">
    <div class="module-name">
      {{ "CP_RSP_RSPSHAREYOURRACE" | customTranslation : translatedArray }}
    </div>
    <div class="link-header-menu">
      {{ "CP_RSP_RSPMEDIADOWNLOAD" | customTranslation : translatedArray }}
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-md-12 main-div">
      <div class="row space-between">
        <div class="select-dropdown col-sm-12">
          <app-race-media-select-dropdown (selectedMedia)="selectMedia($event)" [isSelectedMediaId]="isSelectedMediaId"
            [selectedVideo]="selectedVideo" [translatedArray]="translatedArray"></app-race-media-select-dropdown>
        </div>
        <div class="col-12 col-md-8 media-container">
          <video id="originalVideoPlayer" *ngIf="isOriginalVideoVisible" class="media-video" [muted]="!volume" controls
            autoplay preload="metadata" playsinline>
            <source type="video/mp4" [src]="originalVideo?.url" />
          </video>
          <video id="emotionalVideoPlayer" *ngIf="isEmotionalVideoVisible" class="media-video" [muted]="!volume"
            controls autoplay preload="metadata" playsinline>
            <source type="video/mp4" [src]="emotionalVideo?.url" />
          </video>
          <div class="ani-vid-reg">
            <video id="animationVideoPlayer" *ngIf="isAnimationVisible" class="media-animation" [muted]="!volume"
              controls autoplay playsinline preload="metadata">
              <source type="video/mp4" [src]="animation?.url" />
            </video>
          </div>
          <div (click)="previousMedia()">
            <img class="previous-arrow" alt="" src="./assets/images/race/left-arrow.png" />
          </div>
          <div (click)="nextMedia()">
            <img class="next-arrow" alt="" src="./assets/images/race/right-arrow.png" />
          </div>
        </div>
        <div class="col-sm-4 col-lg-4 sub-class">
          <div class="media-link-container">
            <div [ngClass]="{ active: isOriginalVideoVisible }" class="link-title" (click)="selectMedia(1)">
              <div class="col-8 title-name">
                <span class="mobile-icon-padding">
                  <img class="mobile-icon" src="./assets/images/race/mobile-icon-landscape.png" alt="" />
                </span>
                <div class="med-info">
                  <span class="name"> {{ "CP_RSP_RSPORIGINALHD" | customTranslation : translatedArray }} </span>
                  <span class="bytes-column">({{ (originalVideo?.fileSize / 1000000).toFixed(2) }}  MB)</span>
                </div>
              </div>
              <div class="col-4 download-button" *ngIf="isOriginalVideoVisible"  (click)="downloadMedia(originalVideo, 'original.mp4')">
                <img src="./assets/images/race/download_icon.svg" alt="Download" />
                <span class="download-media">{{  "CP_RSP_RSPDOWNLOAD" | customTranslation : translatedArray }}</span>
              </div>
            </div>
            <div [ngClass]="{ active: isEmotionalVideoVisible }" class="link-title " (click)="selectMedia(2)">
              <div class="col-8 title-name">
                <span class="mobile-icon-padding">
                  <img class="mobile-icon" src="./assets/images/race/mobile-icon-landscape.png" alt="landscape" />
                </span>
                <div class="med-info">
                  <span class="name">{{ "CP_RSP_RSPHIGHLIGHTVIDEO" | customTranslation : translatedArray }}</span>
                  <span class="bytes-column">({{ (emotionalVideo?.fileSize / 1000000).toFixed(2) }} MB)</span>
                </div>
              </div>
              <div class="col-4 download-button" *ngIf="isEmotionalVideoVisible" (click)="downloadMedia(emotionalVideo, 'hot-lap.mp4')">
                <img src="./assets/images/race/download_icon.svg" alt="Download" />
                <span class="download-media">{{ "CP_RSP_RSPDOWNLOAD" | customTranslation : translatedArray }}</span>
              </div>
            </div>
            <div *ngIf="animation" [ngClass]="{ active: isAnimationVisible }" class="link-title " (click)="selectMedia(3)">
              <div class="col-10 title-name">
                <span class="mobile-icon-padding">
                  <img class="mobile-icon" src="./assets/images/race/mobile-icon-portrait.png" alt="portrait" />
                </span>
                <div class="med-info"> 
                  <span class="name">{{ "CP_RSP_RSPINSTASTORY" | customTranslation : translatedArray }}</span>
                  <span class="bytes-column">({{ (animation?.fileSize / 1000000).toFixed(2) }} MB)</span>
                </div>
              </div>
              <div class="col-2 download-button" *ngIf="isAnimationVisible"
                (click)="downloadMedia(animation, 'highlight.mp4')">
                <img src="./assets/images/race/download_icon.svg" alt="Download" />
                <span class="download-media">{{ "CP_RSP_RSPDOWNLOAD" | customTranslation : translatedArray }}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!this.isIosDevice" class="select-dropdown col-sm-12 download-media-mobile">
          <div (click)="downloadMedia(originalVideo, 'original.mp4')">
            <a *ngIf="this.isOriginalVideoVisible">
              {{ "CP_RSP_RSPDOWNLOAD" | customTranslation : translatedArray }}
              {{ "CP_RSP_RSPORIGINALHD" | customTranslation : translatedArray }} ({{ (originalVideo?.fileSize /
              1000000).toFixed(2) }} MB)
            </a>
            <span *ngIf="this.isOriginalVideoVisible">
              <img class="download-icon" src="./assets/images/race/download.svg" alt="download" />
            </span>
          </div>
          <div (click)="downloadMedia(emotionalVideo, 'hot-lap.mp4')">
            <a *ngIf="this.isEmotionalVideoVisible">
              {{ "CP_RSP_RSPDOWNLOAD" | customTranslation : translatedArray }}
              {{ "CP_RSP_RSPEMOTIONALVIDEO" | customTranslation : translatedArray }}({{ (emotionalVideo?.fileSize /
              1000000).toFixed(2) }} MB)
            </a>
            <span *ngIf="this.isEmotionalVideoVisible">
              <img alt="Download" src="./assets/images/race/download.svg" (click)="downloadMedia(emotionalVideo, 'hot-lap.mp4')" />
            </span>
          </div>
          <div (click)="downloadMedia(animation, 'highlight.mp4')">
            <a *ngIf="this.isAnimationVisible">
              {{ "CP_RSP_RSPDOWNLOAD" | customTranslation : translatedArray }}
              {{ "CP_RSP_RSPEMOTIONALANIMATION" | customTranslation : translatedArray }}({{ (animation?.fileSize / 1000000).toFixed(2) }} MB)
            </a>
            <span *ngIf="this.isAnimationVisible">
              <img alt="Download" src="./assets/images/race/download.svg"  (click)="downloadMedia(animation, 'highlight.mp4')" />
              </span>
          </div>
        </div>

        <div *ngIf="this.isIosDevice" class="select-dropdown col-sm-12 download-media-mobile">
          <div>
            <a download="original.mp4" href="{{ originalVideo?.url }}" *ngIf="this.isOriginalVideoVisible">{{
              "CP_RSP_RSPDOWNLOAD" | customTranslation : translatedArray }}
              {{ "CP_RSP_RSPORIGINALHD" | customTranslation : translatedArray }} ({{
              (originalVideo?.fileSize / 1000000).toFixed(2)
              }}
              MB)</a>
            <a download="original.mp4" href="{{ originalVideo?.url }}" *ngIf="this.isOriginalVideoVisible">
              <span>
                <img src="./assets/images/race/download.svg" alt="Download" /></span>
            </a>
          </div>
          <div>
            <a download="hot-lap.mp4" href="{{ emotionalVideo?.url }}" *ngIf="this.isEmotionalVideoVisible">{{
              "CP_RSP_RSPDOWNLOAD" | customTranslation : translatedArray }}
              {{ "CP_RSP_RSPEMOTIONALVIDEO" | customTranslation : translatedArray }}({{
              (emotionalVideo?.fileSize / 1000000).toFixed(2)
              }}
              MB)</a>

            <a download="hot-lap.mp4" href="{{ emotionalVideo?.url }}" *ngIf="this.isEmotionalVideoVisible">
              <span *ngIf="this.isEmotionalVideoVisible">
                <img src="./assets/images/race/download.svg" alt="Download" />
              </span>
            </a>
          </div>
          <div>
            <a download="highlight.mp4" href="{{ animation?.url }}" *ngIf="this.isAnimationVisible">{{ "CP_RSP_RSPDOWNLOAD" |
              customTranslation : translatedArray }}
              {{
              "CP_RSP_RSPEMOTIONALANIMATION" | customTranslation : translatedArray
              }}({{ (animation?.fileSize / 1000000).toFixed(2) }} MB)</a>

            <a download="highlight.mp4" href="{{ animation?.url }}" *ngIf="this.isAnimationVisible">
              <span *ngIf="this.isAnimationVisible">
                <img src="./assets/images/race/download.svg" alt="Download" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>