<div class="backgroung-Image inner-page-main">
    <div class="info-container">
        <div class="alert-icon-reg">
            <div class="alert-icon">
                <span class="icon icon-info"></span>

            </div>
        </div>
        <ng-container>
            <div class="info-message">
                <h3>Something went wrong</h3>
                <div>
                    <span>Please reload the page or try again later to resolve this issue.</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>