import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-annotation-details',
  templateUrl: './annotation-details.component.html',
  styleUrls: ['./annotation-details.component.scss']
})
export class AnnotationDetailsComponent implements OnInit {

  @Input() annotationDetails:any;
  @Output() public closeDetailsPage = new EventEmitter<object>();
  translationData = [];

  constructor(private translationService: TranslationService) { }

  ngOnInit(): void {  
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

 
  closePage(){
    this.closeDetailsPage.emit();
  }


}
