import { Pipe, PipeTransform } from "@angular/core";
import { defaultTranslationArray } from "./default-translation";


@Pipe({
  name: "customTranslation",
})
export class CustomTranslationPipe implements PipeTransform {
  defaultEnTranslation = defaultTranslationArray;
  param: any = {}

  // transform(value: unknown, ...args: unknown[]): string {
  //   let translatedArray = args;
    
  //   let translatedValue: string = '';
  //   if (translatedArray) {
      
  //     translatedArray.forEach((item: any)=>{
  //       item?.filter((data: any) => {
  //         if (data.key.name === value) {
  //           translatedValue = data.content;
  //           return data;
  //         }
  //       });
  //     })
  //   }
  //   if (translatedValue.trim() === '') {
  //   this.defaultEnTranslation.forEach((data: any) => {
  //       if (data.key === value) {
  //         translatedValue = data.value;
  //       }
  //     });
  //   }

  //   return translatedValue;
  // }

  transform(value: any, ...args: any[]): unknown {
    let translatedArray = args;
    let translatedValue: string = "";
    if (translatedArray) {
      if (translatedArray.length > 1) {
        this.param = translatedArray[0];
        translatedArray = translatedArray[1];
        translatedArray.map((item: any) => {
            if (item.key.name === value) {
              translatedValue = item.content;
              if (this.param && this.param['param']) {
                translatedValue = translatedValue.replace('{param}', this.param['param']);
              }
              return item;
            }
      });
      } else {
      translatedArray.map((item: any) => {
        item.filter((data: any) => {
          if (data.key.name === value) {
            translatedValue = data.content;
            return data;
          }
        });
      });
      } 
    }
    
    if (translatedValue.trim() === '') {
      this.defaultEnTranslation.forEach((data: any) => {
          if (data.key === value) {
            if (this.param && this.param['param']) {
              data.value = data.value.replace('{param}', this.param['param']);
            }
            translatedValue = data.value;
          }
        });
      }
    return translatedValue;
  }
}
