import {
  AfterViewChecked,
  AfterViewInit,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Component,
  HostListener,
} from '@angular/core';
import { timer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data-services/data-service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-emotional-data-animator',
  templateUrl: './emotional-data-animator.component.html',
  styleUrls: ['./emotional-data-animator.component.css'],
})
export class EmotionalDataAnimatorComponent implements OnInit {
  position: any = '- -';
  time = {
    min: '- -',
    sec: '- -',
    millisec: '- -',
    unit: '',
  };
  distance: any;
  topSpeed: number = 0;
  svgIconArrow: string = '';
  rankingListLink: string = '';
  maxBrakeTorque: any;
  maxTransmissionOilTemp: any;
  maxTireTemp: any;
  maxGForce: any;
  raceId: any;
  bestTime: any;
  maxOilTemp: any;
  maxLateralAcc: any;
  maxDriftAngle: any;
  lapCount: any;
  maxLongitudalAcc: any;
  participant: any;

  @Input()
  data: any;

  @Input()
	translatedArray: any = [];

  tickerData: any;

  @ViewChild('myIdentifier')
  myIdentifier: ElementRef | undefined = undefined;

  @ViewChild('tickerWrapper')
  tickerWrapper: ElementRef | undefined = undefined;

  @ViewChild('myIdentifier1')
  myIdentifier1: ElementRef | undefined = undefined;

  @ViewChild('tickerWrapper1')
  tickerWrapper1: ElementRef | undefined = undefined;

  topSliderWidth: number | undefined = undefined;
  bottomSliderWidth: number | undefined = undefined;

  eventId: string | undefined = undefined;
  sliceMarqueeData: any = false;


  constructor(private dataService: DataService, private translationService: TranslationService

    
  ) { 
		this.translationService.sharetranslationData.subscribe((data) => {
			this.translatedArray = data;
		});
	}

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 700) {
      this.sliceMarqueeData = true;
    }
  }

  ngOnInit() {
    this.participant = this.data.participantInfo.firstName;

    this.raceId = this.data.raceInfo.raceId;

    this.distance = this.data.trackInfo.distance
      ? this.data.trackInfo.distance
      : 0;

    this.maxGForce = this.data.raceInfo.maxValues.maxGForce?.toFixed(2);

    this.topSpeed = this.data.raceInfo.maxValues.maxSpeed?.toFixed(1);

    this.maxBrakeTorque = this.data.raceInfo.maxValues.maxBrakeTorque?.toFixed(2);

    this.maxTireTemp = this.data.raceInfo.maxValues?.maxTireTemp;

    this.maxTransmissionOilTemp =
      this.data.raceInfo.maxValues?.maxTransmissionTemp;

    this.position = '#' + (this.data.rankInfo?.participantRank ? this.data.rankInfo?.participantRank : '');

        let timeObjhours = Math.floor(((this.data.raceInfo.maxValues.bestTime) / (60 * 60)) % 24);
        let hours = timeObjhours > 0 ? timeObjhours < 10 ? '0' + timeObjhours : timeObjhours : ''
        let timeObjmin = Math.floor(((this.data.raceInfo.maxValues.bestTime) / (60)));
        let minute = timeObjmin > 0 ? timeObjmin < 10 ? '0'+ timeObjmin : timeObjmin : '00';
        let timeObjsec = Math.floor((this.data.raceInfo.maxValues.bestTime) % 60);
        let seconds = timeObjsec < 10 ? '0' + timeObjsec: timeObjsec
        let timeObjmilliseconds = ((this.data.raceInfo.maxValues.bestTime)%60);
			  let millisecondsArray = timeObjmilliseconds.toFixed(2).toString().split('.');        
        let millisecond = millisecondsArray[1] ? millisecondsArray[1] : '00';
        this.bestTime = minute.toString() +':'+ seconds.toString() + '.' + millisecond.toString();


    this.maxOilTemp = this.data.raceInfo.maxValues.maxOilTemp;
    this.maxLateralAcc = (this.data.raceInfo.maxValues.maxLateralAcc)?.toFixed(2);
    this.maxDriftAngle = this.data.raceInfo.maxValues.maxDriftAngle;
    this.lapCount = this.data.raceInfo.maxValues.lapCount;
    this.maxLongitudalAcc = (this.data.raceInfo.maxValues.maxLongitudalAcc)?.toFixed(2);

    this.getTime();

    /*const timerVar = timer(10, 10);
		const timerVarSubscribe = timerVar.subscribe(val => {
			if (this.myIdentifier.nativeElement.offsetWidth) {
				this.leftVal = (this.leftVal < (this.myIdentifier.nativeElement.offsetWidth * -1)) ? window.innerWidth : this.leftVal - 1;
			}
			
			if (this.myIdentifier1.nativeElement.offsetWidth) {
				this.rightVal = (this.rightVal > window.innerWidth) ? this.myIdentifier1.nativeElement.offsetWidth*-1 : this.rightVal + 1;
			}
		});*/
  }

  ngOnChanges() {}

  ngAfterViewChecked() {
    if (this.tickerWrapper) {
      if (this.tickerWrapper.nativeElement.offsetWidth) {
        this.topSliderWidth =
          2 * this.tickerWrapper.nativeElement.offsetWidth + 2;
      }
    }

    if (this.tickerWrapper1) {
      if (this.tickerWrapper1.nativeElement.offsetWidth) {
        this.bottomSliderWidth =
          2 * this.tickerWrapper1.nativeElement.offsetWidth + 2;
      }
    }

    if (window.innerWidth < 700) {
      this.sliceMarqueeData = true;
    }
  }

  getTime() {
    if (this.data) {
      let totaltime = 0;
      totaltime = this.data.raceInfo.raceTime;
      this.time.min =
        Math.trunc(totaltime / 1000 / 60) > 0
          ? Math.trunc(totaltime / 1000 / 60).toString()
          : '';
      this.time.sec = Math.trunc((totaltime / 1000) % 60).toString();
      this.time.millisec = (Math.trunc(((totaltime / 1000) % 60) * 100) / 100)
        .toString()
        .split('.')[1];
      this.time.unit = Math.trunc(totaltime / 1000 / 60) > 0 ? 'MIN' : 'SEC';
    }
  }
}
