<div class="access-denied">
  <div class="modal" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">

        </div>
        <div class="modal-body">
          <div class="title">{{'RSP_ACCESS_DENIED_TITLE' | customTranslation: translationData}}</div>
          <p [innerHTML]="'RSP_INVALID_ACCESS_MSG' | customTranslation: translationData"></p>
          <div class="login-button" (click)="triggerLogin(); disableBtn = true" [ngClass]="disableBtn ? 'disable-login-btn': ''">{{'RSP_LOGIN_WITH_ANOTHER_ID' | customTranslation: translationData}}</div>
        </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
</div>