import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-track-details',
  templateUrl: './track-details.component.html',
  styleUrls: ['./track-details.component.scss']
})
export class TrackDetailsComponent implements OnInit {

  @Input() public track: any = [];
  @Input() public access_Token:any;
  @Input() public moveCarousel:string = 'left';
  @Output() public sendTrackData = new EventEmitter<any>();
  @Output() public sendButtonOverlay = new EventEmitter<any>();

  isAnchorDisable = true;
  translationData = [];
  pathColor = '#fff';
  fill = "#000";
  selectedTrack:any = 0;
  selectedTrackDetails:any = null
  addRightOverlay:any = true;
  addLeftOverlay:any = false;
  carouselEl: any;
  carouselElWidth: any;
  individualCarouselSection: number = 0;


  constructor(private translationService: TranslationService) { }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.carouselEl = document.getElementsByClassName('circuit-info-panel')
    this.carouselElWidth = this.carouselEl[0]?.clientWidth
    this.individualCarouselSection = this.carouselElWidth / this.track.length
    console.log('carwidth', this.individualCarouselSection)
    this.setSelectedTrackClass({},0);
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['moveCarousel']?.currentValue !== ''){
      this.moveList(this.moveCarousel.substring(0, this.moveCarousel.length - 1));
    }
  }

  emitTrackData(data:any, index:any){
    data.index = index;
    this.sendTrackData.emit(data)
  }

  moveList(direction:string){
    let carousel = document.getElementById("listcontainer");
    let firstEle = document.getElementById('path0');
    if(direction === 'left' && carousel && firstEle){
      carousel.scrollLeft += (3 * firstEle.offsetWidth);
    }
    if(direction === 'right' && carousel && firstEle){
      carousel.scrollLeft -= (3 * firstEle.offsetWidth);
    }
  }

  onTrackScroll(event:any){
    let lastEle = document.getElementById('path'+(this.track.length-1));    
    if(lastEle && (lastEle?.getBoundingClientRect().left < (lastEle?.scrollWidth * 3))){
      this.addRightOverlay = false;
      this.sendButtonOverlay.emit('right')
    } else {
      this.addRightOverlay = true;
    }
    let firstEle = document.getElementById('path0');
    let scrolledValue = firstEle?.getBoundingClientRect().x
    if(scrolledValue && scrolledValue < 0) {
      this.addLeftOverlay = true;
    } else{
      this.addLeftOverlay = false;
      this.sendButtonOverlay.emit('left')
    }
  }

  setSelectedTrackClass(trackData:any, index:any){
    this.selectedTrackDetails = trackData;
    this.selectedTrack = index;
    this.track?.forEach((item:any, i:any) => {
      if(i === index){
        item.fill = "#666767";
        item.path = "#ffff"
      }else{
        item.fill = "none";
        item.path = "#767676";
      }
    });
  }

}
