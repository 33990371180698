import { Component } from '@angular/core';

@Component({
  selector: 'app-spinner-component',
  templateUrl: './spinner-component.component.html',
  styleUrls: ['./spinner-component.component.scss']
})
export class SpinnerComponentComponent {

}
