import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public translatedArray:any=[];
  public languageType:string='';
  public translationContent = new BehaviorSubject<any>(this.translatedArray);
  public sharetranslationData = this.translationContent.asObservable();
  public languageContent = new BehaviorSubject<any>(this.languageType);
  public sharelanguageType = this.languageContent.asObservable();
 
  constructor(private httpClient: HttpClient) { }
  sendtranslationValue(value: any) {
    console.log(value,"translation value");
    this.translationContent.next(value);
  }
  sendSelectedLanguage(value: any){
    console.log(value,"lanuage from subscription");
    this.languageContent.next(value);
  }

  setTranslationData(data:any){
    this.translatedArray=data;
  }
  getTranslationData(){
    return this.translatedArray;
      }
      setLanguageType(type:any){
this.languageType=type;
  }
  getLanguageType(){
return this.languageType;
  }


  getAllLanguages() {
		return this.httpClient.get(`${environment.phraseEndPoint}?access_token=a2a6d17811db4560969c2e8d6d261037a5afaae66d219f220110c293372c5b3b`);
	}
  
  phraseApiCallForTranslations(languageId: string): Observable<any> {
    let page1 = this.httpClient.get(`https://api.phrase.com/v2/projects/69f7cea3aa5ebe03d2bb58e5bbda250b/locales/${languageId}/translations?access_token=a2a6d17811db4560969c2e8d6d261037a5afaae66d219f220110c293372c5b3b&page=1&per_page=100`);
    let page2 = this.httpClient.get(`https://api.phrase.com/v2/projects/69f7cea3aa5ebe03d2bb58e5bbda250b/locales/${languageId}/translations?access_token=a2a6d17811db4560969c2e8d6d261037a5afaae66d219f220110c293372c5b3b&page=2&per_page=100`);
    let page3 = this.httpClient.get(`https://api.phrase.com/v2/projects/69f7cea3aa5ebe03d2bb58e5bbda250b/locales/${languageId}/translations?access_token=a2a6d17811db4560969c2e8d6d261037a5afaae66d219f220110c293372c5b3b&page=3&per_page=100`);
    
    return forkJoin([page1, page2, page3]);
  }
}
