import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-stories-section',
  templateUrl: './stories-section.component.html',
  styleUrls: ['./stories-section.component.scss']
})
export class StoriesSectionComponent implements OnInit {
@Input() storyDetails : any;
stories: any[]= [];
@Input() isLoading!: boolean;
// value:any[]=[]
  constructor() {}
ngOnChanges(changes: SimpleChanges){
  this.stories = Array.from(this.storyDetails?.entries())?.reverse();
  // this.stories=[];

}
  ngOnInit(): void {  
    // this.value=this.stories[0][1]
  }

}