import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ToastType } from 'src/app/services/toast.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss']
})
export class CustomToastComponent implements OnInit, AfterViewInit {
  @Input() message = '';
  @Input() toastType:any;
  @Input() position = '7%';
  color = '#b21e23';
  messageLength: any;
  @Input() leftPosition: any = '25%';
  topPosition: any = 'auto';
  isIconShown: any = true;
  iconSource: string = 'assets/images/icon-check.png';
  translationData: any = [];
  constructor(private translationService: TranslationService){}

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.addIcon();
  }

  ngAfterViewInit(): void {
    
    this.addIcon();
    if (this.message.length > 0 && this.isIconShown) {
      // alert('inside')
      this.messageLength = document.getElementById("toast")?.offsetWidth ? document.getElementById("toast")?.offsetWidth : 0;
      console.log(this.messageLength)
      this.messageLength = this.messageLength + 120            
    } else if(!this.isIconShown) {
      this.messageLength = document.getElementById("toast")?.offsetWidth ? document.getElementById("toast")?.offsetWidth : 0;
      this.messageLength = this.messageLength + 80 
    }
  }

  addIcon() {
    switch (this.toastType) {
      case ToastType.Success:
        this.isIconShown = true;
        this.color = '#00b359';
        this.iconSource = 'assets/images/icon-check.png';
        break;
      case ToastType.Error:
        this.isIconShown = true;
        this.color = '#b21e23';
        this.iconSource = 'assets/images/icn-warning.png';
        break;
      case ToastType.Notification:
        this.color = 'black'
        this.isIconShown = false;
        break;
    }
  }

}
