import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-event-name',
  templateUrl: './event-name.component.html',
  styleUrls: ['./event-name.component.scss']
})
export class EventNameComponent {
@Input() eventName!: string;
@Input() subType!: string;
@Input() raceName!: string;
}
