import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

  languageId: string|null;
  isPageNotFound = false;
  
  constructor(private route: ActivatedRoute) { 
    //This is intentional
    this.languageId = this.route.snapshot.paramMap.get('languageId');
    if (this.languageId && environment.supported_language.includes(this.languageId)) {
      this.isPageNotFound = false
    } else {
      this.isPageNotFound = true;
    }
  }

  ngOnInit(): void {
    //This is intentional
  }

}
