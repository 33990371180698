import { ActivatedRoute, Router } from '@angular/router';
import { timeStamp } from 'console';
// import { AuthAmplifyService } from '../services/auth-service/amplify-auth-service';
// import { AuthenticationService } from '../services/auth-service/auth-service';
// import { DataService } from '../services/data-services/data-service';
// import { TranslationService } from '../services/translation.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthAmplifyService } from '../../services/auth-service/amplify-auth-service';
import { DataService } from '../../services/data-services/data-service';
import { AuthenticationService } from '../../services/auth-service/auth-service';
import { TranslationService } from 'src/app/services/translation.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-share-race',
  templateUrl: './share-race.component.html',
  styleUrls: ['./share-race.component.css'],
})
export class ShareRaceComponent implements OnInit {
  data: any;
  selectedRaceId: string = '';
  raceList: any;
  isDataAvailable = false;
  isDataFetched = false;
  isLoaderVisible = true;
  isLoggedIn = false;
  isLoginRequired = false;
  currentUser: any;
  isPageAccessDenied = false;

  translatedArray = [];
  language = 'en-GB';
  isNotFound = false;
  finalLang = false;


  constructor(private dataService: DataService, private location: Location, private route: ActivatedRoute, private router: Router, private authService: AuthAmplifyService, private auth_Service: AuthenticationService,  private translationService: TranslationService, private languageService: LanguageService) {
    const userLang = navigator.language 
    
    //this.language = (userLang === 'en-GB' || userLang === 'de-DE' || userLang === 'ko-KR') ? userLang : 'en-GB';
    const langId = this.route.snapshot.paramMap.get('languageId');
    if (!langId || !this.languageService.checkIsValideLanguage(langId)) {
      this.isNotFound = true;
    } else {
      this.isNotFound = false;
      this.language = langId && this.languageService.checkIsValideLanguage(langId) ? langId : (userLang && this.languageService.checkIsValideLanguage(userLang) ? userLang : 'en-GB');
      localStorage.setItem('language', this.language);

      this.authService.currentSession()
      .then((res: any) => {
        let accessToken = res.getAccessToken()
        let jwt = accessToken.getJwtToken();
        let jwt_expires_in = accessToken.getExpiration();
        let refreshToken = res.getRefreshToken();
        let jwtRefreshToken = refreshToken.getToken();
        let idToken = res.getIdToken();
        let jwtIdToken = idToken.getJwtToken();
        let fullData = { "id_token": jwtIdToken, "access_token": jwt, "refresh_token": jwtRefreshToken, "expires_in": jwt_expires_in, "token_type": "Bearer" }
      
        this.authService.signedIn = true;
        this.auth_Service.login(fullData);

        if (res.isValid()) {
          this.authService.signedIn = true;
          this.isLoggedIn = true;
        }
      }).catch((err: any) => {
        this.authService.signedIn = false;
        console.log(err);
      });
    }    
  }

  getTranslations(languageId: string) {
    
    this.translationService
      .phraseApiCallForTranslations(languageId)
      .subscribe((allDeTranslationBasedOnlocle: string | any[]) => { 
         
          for(let i=0; i< allDeTranslationBasedOnlocle.length; i++) {
            this.translatedArray = this.translatedArray.concat(allDeTranslationBasedOnlocle[i]);
          }      
        this.translationService.setTranslationData(this.translatedArray);
        this.translationService.sendSelectedLanguage(this.language);
        this.translationService.sendtranslationValue(this.translatedArray);
        this.finalLang = true;
    },
    (err: any) => { this.finalLang = true; }
  );

  }

  checkIfLangSelected(){
    this.languageService.setIsNotParticipantSelectedLang(true);
    let raceLangData:any = sessionStorage.getItem('languagePicked') ? sessionStorage.getItem('languagePicked') : '{"languagePicked":false,"raceid":""}'
    raceLangData = raceLangData.replace(/&quot;/ig,'"');
    let parsedData:any = JSON.parse(raceLangData)
    return [parsedData['languagePicked'] , parsedData['raceid']]
  }

  ngOnInit() {
    let [languagePicked, raceidValue] = this.checkIfLangSelected()
    const id = this.route.snapshot.paramMap.get('id');
    localStorage.setItem('raceId', '' + id);
    sessionStorage.removeItem('partcipationDetails');
    localStorage.setItem('routeUrl', 'stories/experience/' + id);
    if(!this.isNotFound) {
      if (id) {
        this.selectedRaceId = id;
        this.dataService.getRaceShare(id).subscribe(
          (data: any) => {
            if (!languagePicked && id !== raceidValue) {
              this.language = data.participantInfo.participantLocale;                    
              const currentURL = this.router.url.split('/');
              currentURL[1] = this.language;
              localStorage.setItem('language', this.language);
              this.languageService.setIsNotParticipantSelectedLang(false);
              this.location.go(currentURL.join('/'));
            } 
            this.languageService.setParticipantLanguage(this.language);   
            
            this.getTranslations(this.language);
            this.data = data;
            this.isDataFetched = true;
            this.isDataAvailable = true;
            localStorage.removeItem('routeUrl');
            this.isLoaderVisible = false;
          },
          (err: any) => {
            this.data = err.error.msg;
            
            if (err.status === 403 || err.status === 401 || err.status === 404) {
              if (this.isLoggedIn) {
                this.dataService.getRaceShare(id).subscribe(
                  (data: any) => {                    
                    
                    this.languageService.setParticipantLanguage(data.participantInfo.participantLocale);
                    if (this.languageService.getIsNotParticipantSelectedLang() && this.languageService.language !== data.participantInfo.participantLocale) {
                      
                      this.language = data.participantInfo.participantLocale;                    
                      const currentURL = this.router.url.split('/');
                      currentURL[1] = this.language;
                      localStorage.setItem('language', this.language);
                      this.languageService.setIsNotParticipantSelectedLang(false);
                      this.location.go(currentURL.join('/'));
                    }
                    
                    this.getTranslations(this.language);
                    this.data = data;
                    this.isDataFetched = true;
                    this.isDataAvailable = true;
                    this.isLoaderVisible = false;
                  },
                  (err: any) => {                  

                    this.getTranslations(this.language);
                    this.data = err.error.msg;
                    if (err.status === 403 || err.status === 404) {
                      //this.router.navigateByUrl('/access-denied');
                      this.isPageAccessDenied = true;
                      this.isLoaderVisible = false;
                    }                   
                  }
                );
              } else {

                this.getTranslations(this.language);

                this.isLoginRequired = true;
                this.isLoaderVisible = false;
              }
            }
          }
        );
      } else {
        this.isPageAccessDenied = true;
        this.isLoaderVisible = false;
      }
    }
  }
}
