import { Component, Input, OnInit } from '@angular/core';
import { AppServicesService } from 'src/app/services/app-services.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-track-cards',
  templateUrl: './track-cards.component.html',
  styleUrls: ['./track-cards.component.scss']
})
export class TrackCardsComponent implements OnInit {

  @Input() public track: any;
  @Input() public access_Token:any;
  translationData = [];
  isAnchorDisable = true;
  ciamToken: any;
  showLoader:boolean = false;

  constructor(private translationService: TranslationService, private appService:AppServicesService, private toastService: ToastService,) { }

  ngOnInit(): void {
    this.ciamToken = localStorage.getItem('token')? localStorage.getItem('token') : '' ;
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  } 

  setUnsetFavourite(status: boolean) {
    this.showLoader = true;
    this.appService.setFavouriteTrackStatus(this.ciamToken, this.track.guid, status).subscribe((response: any) => {
      if (response['error-code']) {
        this.setToastMessage('Error', response['error-message']);
      } else {
        this.setToastMessage('Success', response.message);
        this.track.isFavorite = !this.track.isFavorite;
        this.track.favoriteCount = status ? (this.track.favoriteCount+1) : (this.track.favoriteCount -1)
      }
      this.showLoader = false;
    },
    (error: any) =>  {
      this.showLoader = false;
      if (error.error['error-code']) {
        this.setToastMessage('Error', error.error['error-message']);
      }
    },)
  }

  setToastMessage(type:any, message:any) {
    this.toastService.show(ToastType[type], message, '8%', '40%');
        setTimeout(() => {
          this.toastService.hide();
        }, 4000);
  }

}
