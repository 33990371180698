<ng-container *ngIf="!isPageNotFound && isUserCiamLoggedIn">
    <div id="parent" class="page-container">
        <div id="mainMessage" class="content title-position" role="main">
            <h1>{{'CP_LM_MAINPAGE_WELCOME_LINE1' | customTranslation: translationData}} </h1>
            <h1>{{'CP_LM_MAINPAGE_WELCOME_LINE2' | customTranslation: translationData}} </h1>
        </div>
    </div>
    <ng-container *ngIf="participantDataToAnonymize?.length">
        <app-anonymize-popup (closeAnonymizationPopup)="closeAnonymizationPopup($event)"></app-anonymize-popup>
    </ng-container>
</ng-container>

<ng-container *ngIf="!isUserCiamLoggedIn">
    <app-spinner-component></app-spinner-component>
</ng-container>


<ng-container *ngIf="isPageNotFound">
    <app-no-route ></app-no-route>
</ng-container>


