export const defaultTranslationArray = [
    {
        "key": "BP_EM_ADD_COVERIMG",
        "value": "Add cover image"
    },
    {
        "key": "CP_LM_LANDINGPAGE_EXPLORE",
        "value": "Explore"
    },
    {
        "key": "CP_LM_LANDINGPAGE_SEARCHTEXT",
        "value": "Search places, landmarks, roads"
    },
    {
        "key": "CP_LM_LANDINGPAGE_CREATE",
        "value": "Create"
    },
    {
        "key": "CP_LM_LANDINGPAGE_LIBRARY",
        "value": "Library"
    },
    {
        "key": "CP_LM_LANDINGPAGE_TRACKS",
        "value": "Track"
    },
    {
        "key": "CP_LM_LANDINGPAGE_LOGIN",
        "value": "Login"
    },
    {
        "key": "CP_LM_LANDINGPAGE_EMOTIONTOUR",
        "value": "Emotion tour"
    },
    {
        "key": "CP_LM_LANDINGPAGE_TRACKPACE",
        "value": "Track Pace"
    },
    {
        "key": "CP_LM_LANDINGPAGE_OFFROAD",
        "value": "Offroad Adventure"
    },
    {
        "key": "CP_LM_LANDINGPAGE_MYTRACKS",
        "value": "My Tracks"
    },
    {
        "key": "CP_LM_LANDINGPAGE_FAVOURITE",
        "value": "Favourites"
    },
    {
        "key": "CP_LM_LANDINGPAGE_LOGOUT",
        "value": "Logout"
    },
    {
        "key": "CP_LM_TOURDETAILSPAGE_HIGHLIGHTS",
        "value": "Highlights"
    },
    {
        "key": "CP_LM_TOURDETAILSPAGE_ADDFAV",
        "value": "Please login to use this feature"
    },
    {
        "key": "CP_LM_TOURDETAILSPAGE_MEMORIAL",
        "value": "Memorial"
    },
    {
        "key": "CP_LM_TOURDETAILSPAGE_FROMSTART",
        "value": "from start"
    },
    {
        "key": "CP_LM_TOURDETAILSPAGE_MORE",
        "value": "More"
    },
    {
        "key": "CP_LM_ANNOTATIONDETAILSPAGE_OVERVIEW",
        "value": "Overview"
    },
    {
        "key": "CP_LM_CIRCUITDETAILSPAGE_CIRCUIT",
        "value": "Circuit"
    },
    {
        "key": "CP_LM_CIRCUITDETAILSPAGE_ADDRESS",
        "value": "Address"
    },
    {
        "key": "CP_LM_CIRCUITDETAILSPAGE_WEBSITE",
        "value": "Website"
    },
    {
        "key": "CP_LM_CIRCUITDETAILSPAGE_STORIESHEADER",
        "value": "Stories from the Community"
    },
    {
        "key": "CP_LM_TRACKDETAILSPAGE_SECTORS",
        "value": "sectors"
    },
    {
        "key": "CP_LM_PAGENOTFOUND_MESSAGE",
        "value": "Cluster not available for the tour"
    },
    {
        "key": "CP_LM_DEFAULTPAGE_MESSAGE",
        "value": "Page not found"
    },
    {
        "key": "CP_LM_MAINPAGE_WELCOME_LINE1",
        "value": "Welcome to me Stories"
    },
    {
        "key": "CP_LM_MAINPAGE_WELCOME_LINE2",
        "value": "your companion for new adventures"
    },
    {
        "key": "CP_LM_MAINPAGE_LOAD_MORE",
        "value": "Load More"
    },
    {
        "key": "CP_LM_MAINPAGE_BY",
        "value": "by"
    },
    {
        "key": "CP_LM_MAINPAGE_NO_RESULTS",
        "value": "No Search Results"
    },
    {
        "key": "CP_LM_FOOTER_PROVIDER",
        "value": "Provider"
    },
    {
        "key": "CP_LM_FOOTER_PRIVACY",
        "value": "Privacy"
    },
    {
        "key": "CP_LM_FOOTER_LEGAL",
        "value": "Legal"
    },
    {
        "key": "CP_LM_MAINPAGE_MYPROFILE",
        "value": "My profile"
    },
    {
        "key": "CP_LM_MAINPAGE_LOGOUT",
        "value": "Logout"
    },
    {
        "key": "CP_SM_EDITPROFILE_SUBMESSAGE",
        "value": "To modify your Mercedes me account details, kindly visit the official Mercedes-Benz website."
    },
    {
        "key": "CP_SM_EDITPROFILE_MESSAGE",
        "value": "Introduce yourself to the community. These optional information will also update on your me Stories web and app profile."
    },
    {
        "key": "CP_SM_EDITPROFILE_NICKNAME",
        "value": "Nick Name"
    },
    {
        "key": "CP_SM_EDITPROFILE_SOCIALLINK",
        "value": "Social Link"
    },
    {
        "key": "CP_SM_EDITPROFILE_DESCRIPTION",
        "value": "Description"
    },
    {
        "key": "CP_SM_EDITPROFILE_URLMESSAGE",
        "value": "Enter a URL that you can send to other users via their profile"
    },
    {
        "key": "CP_SM_EDITPROFILE_CHARACTERLIMIT",
        "value": "Characters"
    },
    {
        "key": "CP_SM_EDITPROFILE_UPDATE",
        "value": "Update"
    },
    {
        "key": "CP_LM_MAINPAGE_MYSTORIES",
        "value": "My Stories"
    },
    {
        "key": "CP_SM_CIAMLINK_MESSAGE",
        "value": "Thank you for your participation in the event. You will be notified by e-mail when your recording is ready. You can close this window now."
    },
    {
        "key": "CP_SM_ONGOING_EVENT_TITLE",
        "value": "Live event"
    },
    {
        "key": "CP_SM_LEADERBOARD_BTN_LBL",
        "value": "Go to leaderboard"
    },
    {
        "key": "CP_SM_CIAMCONFIRMPAGE_MESSAGE",
        "value": "Please confirm the link between the mentioned event participation and Mercedes me ID below. All recordings of this event will be available for the given Mercedes me ID."
    },
    {
        "key": "CP_SM_CIAMCONFIRMPAGE_EVENTNAME",
        "value": "Event name & date"
    },
    {
        "key": "CP_SM_CIAMCONFIRMPAGE_PARTICIPANTDETAILS",
        "value": "Event participation details"
    },
    {
        "key": "CP_SM_CIAMCONFIRMPAGE_MERCEDESID",
        "value": "Your Mercedes me ID"
    },
    {
        "key": "CP_SM_CIAMCONFIRMPAGE_CONNECTMERCEDESMEID",
        "value": "Connect with other Mercedes me ID"
    },
    {
        "key": "CP_SM_CONFIRM_BTN",
        "value": "Confirm"
    },
    {
        "key": "CP_SM_GO_TO_APP_BTN",
        "value": "Go to App"
    },
    {
        "key": "CP_SM_ANONYMIZATION_POPUP_TITLE",
        "value": "Anonymize event data"
    },
    {
        "key": "CP_SM_ANONYMIZATION_POPUP_DESC",
        "value": "If you choose to anonymize your event data, please be aware that all information associated with your events. Data will be permanently removed and cannot be recovered."
    },
    {
        "key": "CP_SM_ANONYMIZATION_VIDEO_CONTENT",
        "value": "Video content"
    },
    {
        "key": "CP_SM_ANONYMIZATION_LB_RANKING",
        "value": "Event leaderboard"
    },
    {
        "key": "CP_SM_ANONYMIZATION_RACE_DETAILS",
        "value": "Race details"
    },
    {
        "key": "CP_SM_ANONYMIZATION_CONFIRMATION",
        "value": "Are you sure you want to anonymize your data?"
    },
    {
        "key": "CP_SM_CANCEL",
        "value": "Cancel"
    },
    {
        "key": "CP_SM_OK",
        "value": "Okay"
    },
    {
        "key": "CP_REFRESH_BTN",
        "value": "Reload"
    },
    {
        "key": "CP_CONTENT_NOT_FOUND",
        "value": "Sorry, we are unable to load content at the moment. Please try reloading the page."
    },
    {
        "key": "CP_SM_DATA_ANONYMIZATION_FAIL_MSG",
        "value": "Event data anonymisation failed."
    },
    {
        "key": "CP_SM_DATA_ANONYMIZATION_FAIL_APP_MSG",
        "value": "Anonymization possible only on the App"
    },
    {
        "key": "CP_SM_DATA_ANONYMIZATION_FAIL_APP_SUB_MSG",
        "value": 'To anonymize your data, please follow these steps:Open the Mercedes-Benz Stories App > go to the "My Stories" page > navigate to the event car > tap "Options" in the top right of the recording > Choose "Delete recording”. Your data will be anonymized.'
    },
    {
        "key": "CP_SM_DATA_ANONYMIZED",
        "value": "Event data already anonymised"
    },
    {
        "key": "CP_SM_DATA_ANONYMIZED_SUCCESS",
        "value": "Event data anonymization successful."
    },
    {
        "key": "CP_SM_ANONYMIZING",
        "value": "Anonymizing"
    },
    {
        "key": "CP_FOOTER_TOP_LBL",
        "value": "Top"
    },
    {
        "key": "CP_SM_DATA_ANONYMIZED_PARTICIPANT_NOT_FOUND",
        "value": "Participant not found"
    },
    {
        "key": "CP_SM_CIAM_ANONYMIZED",
        "value": "User anonymised"
    },
    {
        "key": "CP_SM_CIAM_ANONYMIZED_MESSAGE",
        "value": "You have anonymised your account. Unfortunately, deleted experiences can't be restored."
    },
    {
        "key": "CP_SM_CIAM_LINKED_MESSAGE",
        "value": "This experience is already linked to another Mercedes me ID. Kindly reach out to the event administrator for further assistance."
    },
    {
        "key": "CP_SM_CIAM_LINKED",
        "value": "User already linked"
    },
    {
        "key": "CP_SM_NO_VEHICLE_TITLE",
        "value": "No vehicle"
    },
    {
        "key": "CP_SM_NO_VEHICLE_SUB_MSG",
        "value": "Nothing recorded yet"
    },
    {
        "key": "CP_SM_NO_VEHICLE_MSG",
        "value": "There are no vehicles in your Garage, yet. Use the Mercedes me Stories App to add a vehicle."
    },
    {
        "key": "CP_SM_GARAGE_NO_STORY_MSG",
        "value": " There are no Stories from this car, yet. Use the Mercedes me Stories App to record a ride and publish the Recording. You can also use the app to publish a special Moment with your car."
    },
    {
        "key": "CP_SM_GARAGE_OF_TEXT",
        "value": "of"
    },
    {
        "key": "CP_SM_GARAGE_EXPERIENCES",
        "value": "Experiences"
    },
    {
        "key": "CP_SM_GARAGE_EXPERIENCE",
        "value": "Experience"
    },
    {
        "key": "CP_SM_GARAGE_MOMENTS",
        "value": "Moments"
    },
    {
        "key": "CP_SM_GARAGE_MOMENT",
        "value": "Moment"
    },
    {
        "key": "CP_SM_GARAGE_EDIT_PROFILE",
        "value": "Edit Profile"
    },
    {
        "key": "CP_SM_GARAGE_SHARE_PROFILE",
        "value": "Share Profile"
    },
    {
        "key": "CP_SM_GARAGE_ABOUT",
        "value": "ABOUT"
    },
    {
        "key": "CP_SM_GARAGE_SOCIAL",
        "value": "SOCIAL"
    },
    {
        "key": "CP_PENDING_VIDEO_MSG",
        "value": "We are working on a little extra for this experience. This takes a few minutes, check back later."
    },
    {
        "key": "CP_SM_OFFROAD_TITLE",
        "value": "Offroad Adventure"
    },


    { key: 'CP_RSP_RSPPUBLISHTITLE', value: 'Publish now, to share your experience' },
    { key: 'CP_RSP_RSPPUBLISHBUTTON', value: 'Publish' },
    { key: 'CP_RSP_RSPPUBLISHSUCCESSMSG', value: 'Published successfully' },
    { key: 'CP_RSP_RSPOVERALLRANKING', value: 'Overall ranking' },
    { key: 'CP_RSP_RSPWATCHFULLSCREEN', value: 'Watch Fullscreen' },
    { key: 'CP_RSP_RSPNORACEVIDEO', value: 'Video Not Available' },
    { key: 'CP_RSP_RSPSHAREYOURRACE', value: 'Share Your Race' },
    { key: 'CP_RSP_RSPMEDIADOWNLOAD', value: 'DOWNLOAD RACE VIDEOS & DATA HERE' },
    { key: 'CP_RSP_RSPORIGINALHD', value: 'ORIGINAL HD' },
    { key: 'CP_RSP_RSPDOWNLOAD', value: 'Download' },
    { key: 'CP_RSP_RSPHIGHLIGHTVIDEO', value: 'HOT LAP VIDEO' },
    { key: 'CP_RSP_RSPINSTASTORY', value: 'HIGHLIGHT VIDEO' },
    { key: 'CP_RSP_RSPEMOTIONALANIMATION', value: 'EMOTIONAL ANIMATION' },
    { key: 'CP_RSP_RSPEMOTIONALVIDEO', value: 'EMOTIONAL VIDEO' },
    { key: 'CP_RSP_RSPMEDIAORIGINAL', value: 'ORIGINAL' },
    { key: 'CP_RSP_RSPMEDIAHIGHLIGHT', value: 'HOT LAP' },
    { key: 'CP_RSP_RSPMEDIAINSTA', value: 'HIGHLIGHT' },
    { key: 'CP_RSP_RSPMEDIAVIDEO', value: 'VIDEO' },
    { key: 'CP_RSP_RSPMEDIASTORY', value: 'VIDEO' },
    { key: 'RSP_INVALID_ACCESS_MSG', value:'You are not the owner of this race summary. <br /> The race summary you want to visit has not been shared publicly.',},
    { key: 'RSP_REFRESH_BTN', value: 'Refresh' },
    { key: 'RSP_BACK', value: 'Back' },
    { key: 'RSP_404', value: 'Page not found' },
    { key: 'RSP_CONTENT_NOT_FOUND', value: 'Sorry, we are unable to load content at the moment. Please try reloading the page.' },
    { key: 'RSP_ACCESS_DENIED_TITLE', value: 'Access Denied' },
    { key: 'RSP_ACCESS_DENIED_MSG', value: ' To view this page, first register with "Mercedes me" or log in with your existing Mercedes me ID. The Mercedes me ID must be associated with your email address to get access to your race highlights.' },
    { key: 'RSP_LOGIN_BTN_LBL', value: 'Login/Register Now' },
    { key: 'RSP_RACE_UNAVAILABLE', value: 'Race data not available.' },
    { key: 'RSP_LOADING_LBL', value: 'Loading...' },
    { key: 'RSP_CUSTOMER_RIDE_WITH_NAME', value: "{param}'s ride" },
    { key: 'RSP_CUSTOMER_HIGHLIGHT_WITH_NAME', value: "{param}'s HIGHLIGHTS" },
    { key: 'CP_LM_LANDINGPAGE_ALL', value: 'All' },
    { key: 'CP_LM_LANDINGPAGE_MENU', value: 'Menu' },
    { key: 'CP_LM_LANDINGPAGE_CLOSE', value: 'Close' },
    { key: 'CP_LM_LANDINGPAGE_SELECTLANG', value: 'Select Language' },
    { key: 'CP_LM_LANDINGPAGE_ALLANG', value: 'All languages' },
    { key: 'CP_LM_LANDINGPAGE_NOTFOUND', value: 'Not Found' },
    { key: 'CP_LM_TOURDETAILSPAGE_TOURBY', value: 'Tour by' },
    { key: 'CP_SM_TOAST_MSG_SUCCESS', value: 'Update Successful'},
    { key: 'CP_SM_TOAST_MSG_SOCLNK_INVALID', value:'Social Link is invalid'},
    { key: 'CP_SM_TOAST_MSG_SOCLNK_ERR', value: 'Social Link should be within 300 characters'},
    { key: 'CP_SM_TOAST_MSG_DESC_ERR', value: 'Description should be within 300 characters'},
    { key: 'CP_SM_TOAST_MSG_NICKNAME_ERR', value: 'Nick Name should be within 64 characters'},
    { key: 'CP_LM_TOAST_MSG_CLUSTER_NOT_FOUND', value:'Cluster not found for tour!'},
    { key: 'CP_TOAST_MSG_TEMPLOGIN_ERR', value:'Incorrect credentials '},
    { key: 'CP_LM_TOAST_MSG_COPIEDTOCLIPBOARD', value: 'Link copied to clipboard!'},

    { key: 'RSP_MAX_BRAKE_TORQUE', value: 'MAX BRAKE TORQUE'},
    { key: 'RSP_MAX_DRIFT_ANGLE', value: 'MAX DRIFT ANGLE'},
    { key: 'RSP_MAX_GFORCE', value: 'MAX G FORCE'},
    { key: 'RSP_MAX_LAT_ACC', value: 'MAX LATERAL ACC'},
    { key: 'RSP_MAX_LON_ACC', value: 'MAX LONGITUDAL ACC'},
    { key: 'RSP_MAX_TIRE_TEMP', value: 'MAX TIRE TEMP'},
    { key: 'RSP_MAX_TRANS_OIL_TEMP', value: 'MAX TRANSMISSION OIL TEMP'},
    { key: 'RSP_RANKING', value: 'RANKING'},
    { key: 'RSP_TIME', value: 'TIME'},
    { key: 'RSP_LAP_COUNT', value: 'LAP COUNT'},
    { key: 'RSP_TOP_SPEED', value: 'TOP SPEED'},
    { key: "RSP_LOGIN_WITH_ANOTHER_ID", value: "Login with your Mercedes me ID"},


    {key: "RSP_EVENT_OWNER_WITH_TITLE", value:"with"},
    {key: "RSP_VIDEOS_FROM_YOUR_RACE", value:"Videos from your challenge"},
    {key: "RSP_ORIGINAL_RECORDING", value:"Original recording"},
    { key: 'NRSP_MAX_BRAKE_TORQUE', value: 'Max brake torque'},
    { key: 'NRSP_MAX_TIRE_TEMP', value: 'Max tire temp'},
    { key: 'NRSP_TOP_SPEED', value: 'Top speed'},
    { key: 'NRSP_MAX_GFORCE', value: 'Max g-force'},
    { key: 'RSP_TRACK_AND_VEHICLE', value: 'Track and vehicle'},
    { key: 'RSP_VEHICLE', value: 'Vehicle'},
    { key: 'RSP_VEHICLE_SHAREEXPERIENCE', value: 'shared an Experience'},
    { key: 'RSP_DRIFT_SCORE', value: 'Drift score'},
    { key: 'RSP_DRIFT_ANGLE', value: 'Average drift angle'},
    { key: 'RSP_DRIFT_SPEED', value: 'Max. drift speed'},
    { key: 'RSP_DRIFT_DISTANCE', value: 'Drift distance'},
    { key: 'RSP_HOTLAP', value: 'Hotlap'},
    { key: 'RSP_ERROR_MSG', value: 'This Experience content has not been published yet. How about collecting your own Experiences instead?'},
    { key: 'RSP_ERROR_HEADING', value: "We didn't mean to desert you."},    { key: 'RSP_DOWNLOAD_ALL', value: 'Download all'},


]
