import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { Location } from '@angular/common';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-ciam-login',
  templateUrl: './ciam-login.component.html',
  styleUrls: ['./ciam-login.component.scss'],
})
export class CiamLoginComponent implements OnInit {
  language = '';
  constructor(
    private router: Router,
    private awsAuth: AwsAuthService,
    private location: Location,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.authorizeCode();
  }

  initiateNavigation() {
    let url = localStorage.getItem('routeUrl');
    localStorage.setItem('isUserLoggedIn', 'user');

    if (sessionStorage.getItem('partcipationDetails')) {
      this.router.navigateByUrl('/'+this.language+'/stories/link-participant');
    } else {
      if (url) {
        this.router.navigateByUrl('/' + url);
        this.location.go('/' + url);
        localStorage.removeItem('routeUrl');
      } else {
          this.router.navigateByUrl('/' + this.language + '/home');
          this.location.go('/' + this.language + '/home');
      }
    }
  }

  authorizeCode() {
    Auth.currentSession().then((res: any) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      let jwt_expires_in = accessToken.getExpiration();
      let refreshToken = res.getRefreshToken();
      let jwtRefreshToken = refreshToken.getToken();
      let idToken = res.getIdToken();
      let jwtIdToken = idToken.getJwtToken();
      let fullData = {
        id_token: jwtIdToken,
        access_token: jwt,
        refresh_token: jwtRefreshToken,
        expires_in: jwt_expires_in,
        token_type: 'Bearer',
      };
      this.awsAuth.setCurrentUserToken(fullData);
      this.awsAuth.setUserLoginStatus(true);
      localStorage.setItem('useLogged', 'true');
      localStorage.setItem('access', fullData.access_token);

      this.awsAuth.setLoginStatus(true);
      this.initiateNavigation();
    },
    (error: any)=> {
      /* this is added because if we stay in CIAM login screen for more than 5 mins and 
         then login even after suceessful login, there will be no current user error 
         so screen will be blank and no redirect will be triggered. hence retriggering signin 
         so that the new session is created.
      */
      this.awsAuth.signIn()
    });
  }
}
