import { Injectable } from '@angular/core';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class LeafletMapServiceService {

  private map!: L.Map;

  constructor() { }

  initializeMap(mapId: string, lat: number, lng: number, zoom: number): L.Map {
    this.map = L.map(mapId, { zoomControl: false, doubleClickZoom: false }).setView([lat, lng] as L.LatLngExpression, zoom);


    L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
      maxZoom: 18,
      attribution: "",
    }).addTo(this.map);

    return this.map;
  }
}
