import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/components/interfaces/myStories.interface';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-profile-mobile-view',
  templateUrl: './profile-mobile-view.component.html',
  styleUrls: ['./profile-mobile-view.component.scss']
})
export class ProfileMobileViewComponent implements OnInit {
  profileImage = "../../../../../../assets/images/profile-fallback.png";
  @Input()  profileDetails !: UserInfo;
  showOverlay: boolean = false;
  isProfileClicked : boolean = false;
  overlayActive: boolean = false;
  @Input() isOwner!: boolean;
  @Input() shareProfileUrl: any;
  @Input() userId: any;
  isStoriesSection: boolean = false;
  featureFlags: any;
  isShareProfileVisible: boolean = false;
  translationData: any;

  constructor(private translationService: TranslationService) {
    this.featureFlags = this.translationService.featureFlags;
   }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    if(this.featureFlags.general.enableShareProfileButton){
      this.isShareProfileVisible = true
    }
  }
  

  toggleOverlay() {
    this.overlayActive = !this.overlayActive;
  }


  profileClicked(){
    this.userId= this.userId? this.userId: '';
    this.isProfileClicked=true;
    this.isStoriesSection = true;
    
  }
  cancelClicked(event:any){
    if(event){
      this.isProfileClicked=false
    }
  }
  socialLinkClicked(){
    window.open(this.profileDetails.socialLink, '_blank');
  }

}