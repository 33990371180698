import { Component, OnInit, Input, HostListener } from '@angular/core';
import { DataService } from '../../services/data-services/data-service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
	selector: 'app-race-content',
	templateUrl: './race-content.component.html',
	styleUrls: ['./race-content.component.css']
})
export class RaceContentComponent implements OnInit {

	participantRaceData: any;

	emotionalVideo: string = '';

	raceList: any;

	parameterList: any;

	compareWith: any;

	compareRaceData: any;
	
	compareRaceFullData: any;

	currentRaceDataFromList: any;

	fullRaceList: any;
	
	car1Pos: any;

	
	isLoaderVisible = false;
	isbannerLoaded= true;
	isShareModuleLoaded= false;
	isCarDataLoaded= false;
	isRacedataAnimatorLoaded= false;
	isRaceCompareLoaded= false;
	isRaceAnalysisLoaded= false;

	translatedArray = [];

	@Input()
	data: any;

	@Input()
	selectedRaceId: string | undefined = undefined;

	constructor(private dataService: DataService, private translationService: TranslationService) {

		this.translationService.sharetranslationData.subscribe((data) => {
			this.translatedArray = data;
		});

	}

	@HostListener('window:scroll', ['$event'])
	onScroll(e: any) {
		const bannerElement = document.querySelector('#banner-container');
		const shareModuleElement = document.querySelector('#share-module-container');
		const carDataElement = document.querySelector('#car-data-container');
		const raceDataElement = document.querySelector('#race-data-container');
		// const raceCompareElement = document.querySelector('#race-compare-container');
		// const raceAnalysisElement = document.querySelector('#race-analysis-container');


		/*//console.log(position, window.innerHeight);
		// checking whether fully visible
		if (position.top >= 0 && position.bottom <= window.innerHeight) {
			this.isCarVisible = true;
			//console.log('Element is fully visible in screen');
		}

		// checking for partial visibility
		if (position.top < window.innerHeight && position.bottom >= 0) {
			this.isCarVisible = true;
		}
		isbannerLoaded= false;
	isShareModuleLoaded= false;
	isCarDataLoaded= false;
	isRacedataAnimatorLoaded= false;
	isRaceCompareLoaded= false;
	isRaceAnalysisLoaded: false
		*/
		if (bannerElement) {
			const bannerPosition = bannerElement.getBoundingClientRect();
			if (bannerPosition.top < window.innerHeight && bannerPosition.bottom >= 0) {
				this.isbannerLoaded = true;
			}
		}

		if (shareModuleElement) {
			const shareModulePosition = shareModuleElement.getBoundingClientRect();
			if (shareModulePosition.top > 0  && shareModulePosition.bottom <= window.innerHeight) {
				this.isShareModuleLoaded = true;
			}
		}
		
		if (carDataElement) {
			const carDataPosition = carDataElement.getBoundingClientRect();
			if (carDataPosition.top < window.innerHeight && carDataPosition.bottom >= 0) {
				this.isCarDataLoaded = true;
			}
		}

		if (raceDataElement) {
			const raceDataPosition = raceDataElement.getBoundingClientRect();
			if (raceDataPosition.top > 0  && raceDataPosition.bottom <= window.innerHeight) {
				this.isRacedataAnimatorLoaded = true;
			}
		}
		

		
		// const raceComparePosition = raceCompareElement.getBoundingClientRect();
		// const raceAnalysisPosition = raceAnalysisElement.getBoundingClientRect();

		// if (raceComparePosition.top < window.innerHeight && raceComparePosition.bottom >= 0) {
		// 	this.isRaceCompareLoaded = true;
		// }
		// if (raceAnalysisPosition.top < window.innerHeight && raceAnalysisPosition.bottom >= 0) {
		// 	this.isRaceAnalysisLoaded = true;
		// }

	}


	ngOnInit() {
		 window.scrollTo(0, 5);
		// this.getRaceList(this.data.raceinfo.eventId);

		// const uniqueId = this.data.raceinfo.uniqueId;
		// this.dataService.getParticipantData(uniqueId).subscribe((data: any) => {
		// 	this.participantRaceData = data;

		// 	this.emotionalVideo = data.racer.racerlist.filter((race: any) => race.id === this.selectedRaceId).map((race: any) => race.videoUrl);

		// 	this.parameterList = [];
		// 	Object.keys(this.data.raceinfo.telemetryData[0]).forEach(key => {
		// 		if (key !== 'lat' && key !== 'lon' && key !== 'actioncam' && key !== 'ts') {
		// 			this.parameterList.push({
		// 				'key': key,
		// 				'parameter-title': key,
		// 			});
		// 		}
		// 	});

		// }, (err) => {
		// 	console.log(err);
		// });

		this.emotionalVideo = 'https://d35fh30lisupgf.cloudfront.net/Output/639.mp4' //hardcoded value
	}

	getRaceList(eventId: string) {
		// this.dataService.getAllRacesInEvent(eventId, 0, 200).subscribe((data: any) => {
		// 	this.fullRaceList = data.raceInfoResponses;
		// 	this.raceList = [];
		// 	this.getCurrentRaceDataFromList(data.raceInfoResponses);
		// 	Object.keys(data.raceInfoResponses).forEach(key => {
		// 		if (data.raceInfoResponses[key].content.raceId !== this.selectedRaceId) {
		// 			this.raceList.push({
		// 				raceId: data.raceInfoResponses[key].content.raceId,
		// 				name: data.raceInfoResponses[key].content.name
		// 			});
		// 		}

		// 	});
		// 	this.compareWith = this.raceList[0];
		// 	if (this.compareWith) {
		// 		this.getCompareRaceData(this.compareWith.raceId);
		// 	}

		// }, (err) => {
		// 	console.log(err);
		// })
	}


	getCurrentRaceDataFromList(data: any) {
		const raceData = data.filter((entry: any) => {
			return entry.content.raceId === this.selectedRaceId;
		});
		this.currentRaceDataFromList = raceData;
	}
	
	updateCompareRace(e: any) {
		this.getCompareRaceData(e);
	}


	getCompareRaceData(id: string) {
		// this.dataService.getRaceShare(id).subscribe((data: any) => {
		// 	this.compareRaceFullData = data;
		// 	this.compareRaceData = data.raceinfo;
		// }, (err) => {
		// 	console.log(err);
		// }
		// );
	}

	compareSelection(compareWith: any) {
		// this.compareWith = compareWith;
		// this.compareRaceData = {};
		// this.getCompareRaceData(this.compareWith.raceId)
	}
	
	updateCarPos(pos: number){
		this.car1Pos = pos;
	}

	loaderVisibility(isVisible:boolean) {
		this.isLoaderVisible = isVisible;
	}

}
