import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponentComponent } from './components/profile-component/profile-component.component';
import { TagsComponentComponent } from './components/tags-component/tags-component.component';
import { TranslationModule } from '../translation.module';
import { MyStoriesGarageComponent } from './pages/my-stories-garage/my-stories-garage.component';
import { CarGarageComponent } from './components/car-garage/car-garage.component';
import { ProfileMobileViewComponent } from './components/profile-component/profile-mobile-view/profile-mobile-view.component';
import { NoVehiclesComponent } from './pages/no-vehicles/no-vehicles.component';
import { SharedModule } from 'src/app/share.module';
import { StoriesSectionComponent } from './components/stories-section/stories-section.component';
import { StoryComponent } from './components/stories-section/story/story.component';
import { ExperienceDetailsComponent } from './components/experience-details/experience-details.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { RaceContentComponent } from './components/race-content/race-content.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from './_helper/jwt.interceptor';
import { BannerComponent } from './components/banner/banner.component';
import { CarDetailsComponent } from './components/car-details/car-details.component';
import { EmotionalDataAnimatorComponent } from './components/emotional-data-animator/emotional-data-animator.component';
import { EmotionalDataComponent } from './components/emotional-data-animator/emotional-data/emotional-data.component';
import { RaceMediaComponent } from './components/race-media/race-media.component';
import { NotValidUserComponent } from './pages/not-valid-user/not-valid-user.component';
import { ShareRaceComponent } from './pages/share-race/share-race.component';
import { DataService } from './services/data-services/data-service';
import { WindowRef } from './services/window-ref/window-ref.service';
import { StoriesRoutingModule } from './stories-routing.module';
import { CiamConfirmLinkComponent } from './pages/ciam-confirm-link/ciam-confirm-link.component';
import { CiamLinkComponent } from './pages/ciam-link/ciam-link.component';
import { AnonymizeComponent } from './pages/anonymize/anonymize.component';
import { AnonymizePopupComponent } from './pages/anonymize-popup/anonymize-popup.component';
import { RaceMediaSelectDropdownComponent } from './components/select-dropdown/race-media-select-dropdown.component';
import { CustomTranslationPipe } from 'src/app/pipes/custom-translation.pipe';
import { RaceShareMainComponent } from './pages/race-share-main/race-share-main.component';
import { EventDescriptionComponent } from './components/event-description/event-description.component';
import { EventDetailsComponent } from './components/event-details/event-details.component';
import { EventNameComponent } from './components/event-name/event-name.component';
import { EventOwnerDetailsComponent } from './components/event-owner-details/event-owner-details.component';
import { HighlightVideoComponent } from './components/highlight-video/highlight-video.component';
import { RaceVideosComponent } from './components/race-videos/race-videos.component';
import { TelemetricDataCardComponent } from './components/telemetric-data-cards/telemetric-data-card.component';
import { Ng9OdometerModule } from 'ng9-odometer';
import { EventCarDetailsComponent } from './components/event-car-details/event-car-details.component';
import { EventTrackDetailComponent } from './components/event-track-detail/event-track-detail.component';
import { RaceShareErrorHandlingComponent } from './pages/race-share-error-handling/race-share-error-handling.component';
import { ErrorHandlingInterceptor } from 'src/app/interceptors/error-handling.interceptor';
import { VerifyComponent } from './pages/verify/verify.component';

@NgModule({
  declarations: [
    ProfileComponentComponent,
    StoriesSectionComponent,
    StoryComponent,
    TagsComponentComponent,
    MyStoriesGarageComponent,
    CarGarageComponent,
    ProfileMobileViewComponent,
    NoVehiclesComponent,
    ExperienceDetailsComponent,
    NotFoundPageComponent,
    AccessDeniedComponent,
    RaceContentComponent,
    ShareRaceComponent,
    NotValidUserComponent,
    RaceMediaComponent,  
    BannerComponent,
    CarDetailsComponent,
    EmotionalDataAnimatorComponent,
    EmotionalDataComponent,
    RaceMediaSelectDropdownComponent,
    CiamConfirmLinkComponent,
    CiamLinkComponent,
    AnonymizeComponent,
    AnonymizePopupComponent,
    NoVehiclesComponent,
    RaceShareMainComponent,
    EventDescriptionComponent,
    EventDetailsComponent,
    EventNameComponent,
    EventOwnerDetailsComponent,
    HighlightVideoComponent,
    RaceVideosComponent,
    TelemetricDataCardComponent,
    EventCarDetailsComponent,
    EventTrackDetailComponent,
    RaceShareErrorHandlingComponent,
    VerifyComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoriesRoutingModule,
    HttpClientModule,
    TranslationModule,
    Ng9OdometerModule.forRoot(),
  ],
  providers: [
    DataService,
    WindowRef,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class StoriesModule { }