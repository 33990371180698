<ng-container *ngIf="isNotFound">
    <app-not-found-page></app-not-found-page>
</ng-container>
<div class="wrapper" *ngIf="!isNotFound">
    <div class="content-container" *ngIf="!isLoginRequired && finalLang">
        <app-race-content *ngIf="isDataAvailable && selectedRaceId" [data]="data" [selectedRaceId]="selectedRaceId">
        </app-race-content>
        <div class="no-data-container txt-center" *ngIf="!isDataAvailable && isDataFetched">
            <div class="no-data">{{'RSP_RACE_UNAVAILABLE' | customTranslation: translatedArray}}</div>
        </div>
        <ng-container *ngIf="isPageAccessDenied">
            <app-not-valid-user></app-not-valid-user>
        </ng-container>
    </div>
    <div class="content-container" *ngIf="isLoginRequired && finalLang">
        <app-access-denied></app-access-denied>
    </div>
    <div class='loader' [ngClass]="{'d-none': !isLoaderVisible, 'd-block':isLoaderVisible}">
        <div class="spinner-border text-primary">
        </div>
    </div>
</div>