<div class="pop-screen anonymize-popup trans">
    <div class="pop-ups"  *ngIf="!isAnonymizeCalled">
        <div class="pop-up-content">
      
            <ng-container>
                <div class="anonymize-text-message">
                <h1>{{'CP_SM_ANONYMIZATION_POPUP_TITLE' | customTranslation: translationData}}</h1>
                <p>
                    {{'CP_SM_ANONYMIZATION_POPUP_DESC' | customTranslation: translationData}}
                </p>
                </div>
                <div class="pop-cont-info">
                   
           
                    <div class="icon-region-main">
                        <div class="icon-item">
                            <span class="icon-placeholder">
                                <span class="icon icon_video"></span>
                                <span class="restricted-icon"></span>
                            </span>
                            <p class="tags tag">{{'CP_SM_ANONYMIZATION_VIDEO_CONTENT' | customTranslation:
                                translationData}}</p>
                        </div>
                        <div class="icon-item">
                            <span class="icon-placeholder">
                                <span class="icon icon_race"></span>
                                <span class="restricted-icon"></span>
                            </span>
                            <p class="tags tag">{{'CP_SM_ANONYMIZATION_RACE_DETAILS' | customTranslation:
                                translationData}}</p>
                        </div>
                        <div class="icon-item">
                            <span class="icon-placeholder">
                                <span class="icon icon_rank"></span>
                                <span class="restricted-icon"></span>
                            </span>
                            <p class="tags tag"> {{'CP_SM_ANONYMIZATION_LB_RANKING' | customTranslation:
                                translationData}}</p>
                        </div>
                    </div>
                    <p class="text-anonymised">{{'CP_SM_ANONYMIZATION_CONFIRMATION' | customTranslation:
                        translationData}}</p>
                </div>
                <div class="button-region right">
                    <ng-container>
                        <!-- <button class="hallow" (click)="cancelAnonymization()"><span>{{'CP_LM_Cancel' | customTranslation: translationData}}</span></button> -->
                        <button [disabled]="isFormSubmitted" class="beta" (click)="anonymization()"
                            id="anonymizationConfirmButton">
                            <span *ngIf="!showSpinner" class="primary full-w">{{'CP_SM_CONFIRM_BTN' |
                                customTranslation: translationData}}</span>
                            <ng-container *ngIf="showSpinner">
                                <span>{{'CP_SM_ANONYMIZING' | customTranslation: translationData}}</span>
                                <div class="spin-in-btn">
                                    <div class="spinner-border spinner"></div>
                                </div>
                            </ng-container>

                        </button>
                        <div class="lt-fr-row">
                            <div class="log-out-link" [ngClass]="isFormSubmitted ? 'disabled': '' " (click)="cancelAnonymization()" id="anonymizationCancelButton">
                                <span>{{'CP_SM_CANCEL' | customTranslation: translationData}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            
        
    
        </div>
    </div>

    <ng-container *ngIf="isAnonymizeCalled">
            <div  [ngClass]="anonymizeInApp == true ? 'confirm-button-app' : 'confirm-button'"> 
          <div>
              <div class="alert-icon-reg">

                  <div class="alert-icon ">
                      <span *ngIf="isAnonymizeFailed && !isAlreadyAnonimized" class="icon icon_failed"></span>
                      <span *ngIf="isAnonymizeSuccess" class="icon icon_success"></span>
                      <span *ngIf="isAnonymizeFailed && isAlreadyAnonimized && !isDifferentUserLinked" class="icon icon_info"></span>
                      <span *ngIf="isDifferentUserLinked" class="icon icon_success icon-info-msg"></span>
                  </div>

              </div>
              <h3 *ngIf="isAnonymizeFailed && !isAlreadyAnonimized && !anonymizeInApp">{{'CP_SM_DATA_ANONYMIZATION_FAIL_MSG' |
                  customTranslation: translationData}}</h3>
              <h3 *ngIf="isAnonymizeFailed && isAlreadyAnonimized && !isParticipantNotFound && !isDifferentUserLinked">{{'CP_SM_DATA_ANONYMIZED' | customTranslation:
                  translationData}}</h3>
              <h3 *ngIf="isAnonymizeSuccess">{{'CP_SM_DATA_ANONYMIZED_SUCCESS' | customTranslation: translationData}}
              </h3>
              <h3 *ngIf="isAnonymizeFailed && isAlreadyAnonimized && isParticipantNotFound">{{'CP_SM_DATA_ANONYMIZED_PARTICIPANT_NOT_FOUND' | customTranslation:
                translationData}}</h3>
                <h3 *ngIf="isAnonymizeFailed && isAlreadyAnonimized && isDifferentUserLinked">{{'CP_SM_CIAM_LINKED' | customTranslation:
                    translationData}}</h3>
                <span *ngIf="isAnonymizeFailed && isAlreadyAnonimized && isDifferentUserLinked" class="subInfo-msg">{{'CP_SM_CIAM_LINKED_MESSAGE' | customTranslation: translationData}}</span>
                <h3 *ngIf="isAnonymizeFailed && !isAlreadyAnonimized && anonymizeInApp">{{'CP_SM_DATA_ANONYMIZATION_FAIL_APP_MSG' | customTranslation: translationData}}</h3>
                <span *ngIf="isAnonymizeFailed && !isAlreadyAnonimized && anonymizeInApp" class="subInfo-msg">{{'CP_SM_DATA_ANONYMIZATION_FAIL_APP_SUB_MSG' | customTranslation: translationData}}</span>
          </div>
      </div> 
      </ng-container>
    <!-- <div class="button-region right" *ngIf="isAnonymizeFailed && !isAlreadyAnonimized && anonymizeInApp">
        <ng-container>
            <button class="beta"
                id="anonymizationOnAppButton">
                <span class="primary full-w">{{'CP_SM_GO_TO_APP_BTN' | customTranslation: translationData}}</span>
            </button>
        </ng-container>
    </div> -->


</div>