import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from "@angular/core";
import * as d3 from "d3";

@Component({
selector: "app-geo-path",
  templateUrl: "./geo-path.component.html",
  styleUrls: ["./geo-path.component.scss"],
})
export class GeoPathComponent implements OnInit, OnChanges {
  @Input() width: any;
  @Input() height: any;
  @Input() fill:any ="none";
  @Input() pathColor:any ="#767676";
  @Input() name:any = "circuit name"
  // Format: [[-6, 36], [33, 30], [43, 11], [51, 12], [29, -33], [18, -35], [7, 5], [-17, 14], [-6, 36]]
  @Input() coordinates: any;
  divId = "id" + this.createUUID();

  constructor() {
    //This is intentional
  }

  ngOnInit(): void {
    //This is intentional
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fill'].currentValue) {
      d3.select(`#${this.divId} g.map`)
        .selectAll("path").style("stroke", this.pathColor);
      d3.select(`#${this.divId} g.map`)
        .selectAll("path").style("fill", this.fill);
    }
  }

  ngAfterViewInit() {
    this.plotTrack();
  }
  createUUID() {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const typedArray = new Uint32Array(1);
        const randomValue = crypto.getRandomValues(typedArray)[0];
        const randomFloat = randomValue / Math.pow(2, 32);
        let r = (dt + randomFloat * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }
  plotTrack() {
    if (this.coordinates.length <= 2) {
      if (this.coordinates.length == 1) {
        this.coordinates.push([this.coordinates[0][0], this.coordinates[0][1]]);
      }
      if (
        JSON.stringify(this.coordinates[0]) ==
        JSON.stringify(this.coordinates[1])
      ) {
        this.coordinates[1][0] = this.coordinates[1][0] + 0.1;
      }
    }
    const geojson: any = {
      type: "FeatureCollection",
      features: [
        {
          geometry: {
            type: "LineString",
            coordinates: this.coordinates,
          },
          type: "Feature",
        },
      ],
    };

    let g = d3
      .select(`#${this.divId} g.map`)
      .selectAll("path")
      .data(geojson.features);

    // fitSize makes the output take up all the space inside the svg
    let projection = d3
      .geoIdentity()
      .reflectY(true)
      .fitSize(
        [parseInt(this.width, 10)-4, parseInt(this.height, 10)-10],
        geojson
      );
    const path: any = d3.geoPath().projection(projection);
    // So that it still works if there are more features than just one
    g.enter()
      .append("path")
      .attr("d", path)
      .style("fill", this.fill)
      .style("stroke-width", "4")
      .style("stroke", this.pathColor);
  }

}
