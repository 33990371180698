<div class="backgroung-Image inner-page-main">

    <div *ngIf="!isSpinnerLoads; else SpinnerLoads">
        <ng-container *ngIf="!isConfirmClicked && !isCiamLinked && !isDifferentCiamLinked && !isAnonymisedParticipant">
            <div class="ciam-container">
                <div class="lt-fr-row">
                    <p>{{'CP_SM_CIAMCONFIRMPAGE_MESSAGE' | customTranslation: translationData }}</p>
                </div>
                <div class="lt-fr-row">
                    <label>{{'CP_SM_CIAMCONFIRMPAGE_EVENTNAME' | customTranslation: translationData }}</label>
                    <h1>{{eventInfoResponse.eventInfo.eventName}}</h1>
                    <div class="tag-item track">
                        <span>{{eventStartDate | date: 'dd.MM.yyyy'}} - {{eventEndDate | date: 'dd.MM.yyyy'}}</span>
                    </div>
                </div>

                <div class="lt-fr-row">
                    <label>{{'CP_SM_CIAMCONFIRMPAGE_PARTICIPANTDETAILS' | customTranslation: translationData }}</label>
                    <h4>{{eventInfoResponse.participantInfo.firstName}} {{eventInfoResponse.participantInfo.lastName}}
                    </h4>
                    <p>{{eventInfoResponse.participantInfo.email}}</p>
                </div>
                <div class="lt-fr-row divider">
                    <span class="icon icon_link"></span>
                    <hr>
                </div>

                <div class="lt-fr-row usr-info">
                    <label>{{'CP_SM_CIAMCONFIRMPAGE_MERCEDESID' | customTranslation: translationData }}</label>
                    <h4>{{eventInfoResponse.ciamInfo.firstName}} {{eventInfoResponse.ciamInfo.lastName}}</h4>
                    <p> {{eventInfoResponse.ciamInfo.email}} </p>
                    <div class="usr-avtar" *ngIf="eventInfoResponse.ciamInfo.profileImageUrl">
                        <img style="background-image: url({{profileImage}});" alt="" />
                        <div class="usr-initial">

                        </div>
                    </div>
                    <div class="usr-avtar" *ngIf="!eventInfoResponse.ciamInfo.profileImageUrl">
                        <img src="../../../assets/images/profile-fallback.png" alt="..." />
                        <div class="usr-initial">

                        </div>
                    </div>
                </div>

                <div class="button-region">
                    <button class="primary full-w" (click)="confirm()">
                        <span>{{'CP_SM_CONFIRM_BTN' | customTranslation: translationData }}</span>
                    </button>
                </div>
                <div class="lt-fr-row">
                    <div class="log-out-link" (click)="logout()">
                        <span>{{'CP_SM_CIAMCONFIRMPAGE_CONNECTMERCEDESMEID' | customTranslation: translationData
                            }}</span>
                    </div>
                </div>

            </div>

        </ng-container>
        <div class="info-container" *ngIf="isAnonymisedParticipant || isDifferentCiamLinked">
                <div class="alert-icon-reg">
                    <div class="alert-icon">
                        <span class="icon icon-info"></span>

                    </div>
                </div>
                <ng-container *ngIf="isDifferentCiamLinked">
                    <div class="info-message">
                        <h3>{{'CP_SM_CIAM_LINKED'| customTranslation: translationData}}</h3>
                        <div>
                        <span>{{'CP_SM_CIAM_LINKED_MESSAGE' | customTranslation: translationData}}</span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isAnonymisedParticipant">
                    <div class="info-message">
                        <h3>{{'CP_SM_CIAM_ANONYMIZED'| customTranslation: translationData}}</h3>
                        <div>
                        <span>{{'CP_SM_CIAM_ANONYMIZED_MESSAGE'|customTranslation: translationData}}</span>
                        </div>
                    </div>
                </ng-container>
        </div>


        <!-- <ng-container *ngIf="isConfirmClicked || isCiamLinked">
        <app-ciam-link></app-ciam-link>
    </ng-container> -->
    </div>
    <ng-template #SpinnerLoads>
        <app-spinner-component></app-spinner-component>
    </ng-template>