<ng-container *ngIf="!isPageNotFound">
    <div id="parent" class="page-container">
        <div id="imageContainer" class="next-section" [ngClass]="!isProd?'background-section': 'no-background-section'" role="main">
        </div>
        <div id="mainMessage" class="content title-position" role="main">
            <h1>{{'CP_LM_MAINPAGE_WELCOME_LINE1' | customTranslation: translationData}} </h1>
            <h1>{{'CP_LM_MAINPAGE_WELCOME_LINE2' | customTranslation: translationData}} </h1>
            <div class="message-button-block">
                <button *ngIf="!currentUser && !isProd" (click)="triggerLogin()"
                    class="login-button"><span>{{'CP_LM_LANDINGPAGE_LOGIN' | customTranslation:
                        translationData}}</span></button>
                <button (click)="goToExplore()" class="login-button" *ngIf="!isProd">
                    <span>{{'CP_LM_LANDINGPAGE_EXPLORE' | customTranslation: translationData}}</span>
                    <span class="icon icon_up_right"></span> 
                </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isPageNotFound">
    <app-no-route ></app-no-route>
</ng-container>


