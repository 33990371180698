
<div id="explore-widget-main" *ngIf="clusterList && completeList" class="cluster-container-style">
  <div id="search-main">
    <div class="search-area-style">
      <app-search [pageNumber]="pageNumber" [searchFilter]="searchFilter"
       (emitSearchTerm)="getSearchTerm($event)" 
       (emitPaginatedResults)="emitPaginatedResults($event)" 
       (emitTotalResultLength)="getTotalResultLength($event)" 
       (emitTotalPages)="getTotalPages($event)"
       (emitSearchResults)="getSearchResults($event)"
       (goToPage)="sendDetails($event)"
       >       
      </app-search>
    </div>
  </div>
  <div class="filter-area">
    <div (click)="showTracks('ALL')" [ngClass]="filterName==='ALL'? 'all-selected':''" class="circuit-filter"><span>{{'CP_LM_LANDINGPAGE_ALL' | customTranslation: translationData}}</span></div>
    <div (click)="!isCircuitsEnabled ? $event.stopPropagation():showTracks('CIRCUIT')" [ngClass]="filterName==='CIRCUIT'? 'circuit-selected':''" class="circuit-filter"><span>{{'CP_LM_LANDINGPAGE_TRACKPACE' | customTranslation: translationData}}</span></div>
    <div (click)="!isEmotiontourEnabled? $event.stopPropagation():showTracks('EMOTION_TOUR')" [ngClass]="filterName==='EMOTION_TOUR'? 'emotiontour-selected':''" class="circuit-filter"><span>{{'CP_LM_LANDINGPAGE_EMOTIONTOUR' | customTranslation: translationData}}</span></div>
    <div class="circuit-filter" [ngClass]="isOffRoadEnabled && filterName === 'OFFROAD_TOUR' ? 'offroad-selected': ''" (click)="!isOffRoadEnabled ? $event.stopPropagation():showTracks('OFFROAD_TOUR')"><span>{{'CP_LM_LANDINGPAGE_OFFROAD' | customTranslation: translationData}}</span></div>
    <div *ngIf="ciamToken.length > 1" (click)="showTracks('MY_TRACKS')" [ngClass]="filterName==='MY_TRACKS'? 'all-selected':''" class="circuit-filter"><span>{{'CP_LM_LANDINGPAGE_MYTRACKS' | customTranslation: translationData}}</span></div>
    <div *ngIf="ciamToken.length > 1" (click)="showTracks('FAVOURITE')" [ngClass]="filterName==='FAVOURITE'? 'all-selected':''" class="circuit-filter"><span>{{'CP_LM_LANDINGPAGE_FAVOURITE' | customTranslation: translationData}}</span></div>
  </div>
  

  <ng-container *ngIf="(completeList?.length > 0 && !showLoader ) && enableTrackList">
  <div id="explore-list-region">
    <!-- <div class="title-style"><h1>{{'CP_LM_LANDINGPAGE_EXPLORE' | customTranslation: translationData }} {{totalElements}} {{'CP_LM_LANDINGPAGE_TRACKS' | customTranslation: translationData }}(s)</h1></div> -->
    <div class="list-parent-class" id="cluster-list">
      <ul>
        <li *ngFor="let clustername of completeList; let i = index" class="cluster-names">
          <div (click)="sendDetails(clustername)">
            <app-cluster-card [index]="i" [clustername]="clustername" (sendMouseStatus)="getsentMouseStatus($event, clustername)">          
            </app-cluster-card>
          </div>   
        </li>
      </ul>
      <div *ngIf="showSpinner && !showLoader" id="morespinner"><span class="spinner-circle"></span></div>
      <div *ngIf="((this.index < (this.totalPages-1)) && completeList.length >= 15) && !showSpinner" class="load-more" (click)="fetchNextResults()"><span>{{'CP_LM_MAINPAGE_LOAD_MORE' | customTranslation: translationData}} </span></div>
    </div>
  </div>
  </ng-container>
  <ng-container *ngIf="showLoader">
    <div id="spinner"><span class="spinner-circle"></span></div>
  </ng-container>
  <ng-container *ngIf="showLoader && !showSpinner">
    <div id="filterspinner"><span class="spinner-circle"></span></div>
  </ng-container>
  <ng-container *ngIf="completeList?.length === 0 && !showLoader">
    <h1>{{'CP_LM_MAINPAGE_NO_RESULTS' | customTranslation: translationData}}!</h1>
  </ng-container>
</div>
