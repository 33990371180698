<div id="car-garage-main">
    <div class="car-image-container">
        
        <div id="car-carousel-reg">
            <div id="carouse-holder">
                <div class="car-carousel">
                    <div class="car-carousel-inner" [style.transform]="'translateX(' + -currentIndex * 100 + '%)'">
                        <div class="car-card" *ngFor="let carInfo of carDetails; let i = index"
                            [ngClass]="{ active: currentIndex === i, visited: i < currentIndex }">
                            <div class="car-card-box">
                                <div class="car-img-box">
                                    <img class="car-image"
                                        *ngIf="carInfo.vehicleInfo?.vehicleDetails?.['BE040-P27'].url"
                                        [src]="carInfo.vehicleInfo?.vehicleDetails?.['BE040-P27'].url?carInfo.vehicleInfo?.vehicleDetails?.['BE040-P27'].url:defaultCarImage"
                                        (error)="handleImageError($event)" alt="" />
                                    <img class="car-image" *ngIf="carInfo.vehicleInfo?.vehicleDetails===null"
                                        src="../../../../../assets/images/car-fallback.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="car-min" [ngClass]="isMinCardActive?'min-active':'' ">
        <div class="car-index">
            <small>
                <span>{{ currentIndex + 1 }}</span>
                {{'CP_SM_GARAGE_OF_TEXT' | customTranslation: translationData }}
                <span>{{ carDetails?.length }}</span>
            </small>
        </div>
        <div class="car-infos">
            <h1 class="car-name">
                {{ selectedInfo?.vehicleInfo?.vehicleName !== null ? selectedInfo?.vehicleInfo?.vehicleName :
                'Mercedes-Benz' }}
            </h1>
            <div class="car-tags">
                <div class="tag" *ngIf="!selectedInfo?.totalExperiences && !selectedInfo?.totalMoments">
                    {{'CP_SM_NO_VEHICLE_SUB_MSG' | customTranslation: translationData }}</div>

                <div class="tag" *ngIf="selectedInfo?.totalExperiences">
                    {{ selectedInfo.totalExperiences }} <span *ngIf="selectedInfo?.totalExperiences>1">
                        {{'CP_SM_GARAGE_EXPERIENCES' | customTranslation: translationData }} </span> <span
                        *ngIf="selectedInfo?.totalExperiences===1"> {{'CP_SM_GARAGE_EXPERIENCE' | customTranslation:
                        translationData }}</span>
                </div>
                <div class="tag" *ngIf="selectedInfo?.totalMoments">
                    {{ selectedInfo.totalMoments }} <span *ngIf="selectedInfo?.totalMoments === 1"> {{'CP_SM_GARAGE_MOMENT'
                        | customTranslation: translationData }}</span> <span *ngIf="selectedInfo?.totalMoments>1">
                        {{'CP_SM_GARAGE_MOMENTS' | customTranslation: translationData }}</span> </div>
            </div>
        </div>
        <div class="car-min-img">
            <img class="car-image"  *ngIf="selectedInfo.vehicleInfo?.vehicleDetails?.['BE000-P27'].url" [src]="selectedInfo.vehicleInfo?.vehicleDetails?.['BE000-P27'].url?selectedInfo.vehicleInfo?.vehicleDetails?.['BE000-P27'].url:defaultCarImageFall" (error)="handleFrontImageError($event)" alt="" />
            <img class="car-image fall" *ngIf="selectedInfo.vehicleInfo?.vehicleDetails===null" src="../../../../../assets/images/car-fallback-front.png" alt="" />
        </div>
        <div class="carousel-control-buttons" >
            <button [ngClass]="{ disabled: currentIndex == 0 }" [attr.disabled]="currentIndex == 0 ? true : null"
                (click)="moveLeftButton(carDetails[currentIndex - 1]?.vinOrFin, carDetails[currentIndex - 1])"
                class="car-button back">
                <span></span>
            </button>
            <button [ngClass]="{ disabled: currentIndex == carDetails?.length - 1 }"
                [attr.disabled]="currentIndex == carDetails?.length - 1 ? true : null"
                (click)="moveRightButton(carDetails[currentIndex + 1]?.vinOrFin, carDetails[currentIndex + 1])"
                class="car-button next">
                <span></span>
            </button>
        </div>
    </div>
    <div class="dot-container" >
        <div class="dots-carousel" [style.transform]="'translateX(' + -currentIndex * 1.6275 + 'em)'">
            <span class="empty-dot-box"></span>
            <span class="dot" *ngFor="let carInfo of carDetails; let i = index"
                [ngClass]="{ active: currentIndex === i }" (click)="currentSlide(i, carInfo?.vinOrFin, carInfo)"></span>
            <span class="empty-dot-box"></span>
        </div>
    </div>


    <div class="user-moment-container">
        
        <div *ngIf="myStory" class="user-moment">
            <app-stories-section [storyDetails]="myStory" [isLoading]="isLoading"></app-stories-section>
        </div>
    </div>
    <div *ngIf="myStoryCount" >
        <div class="car-stories-sharing"></div>
        <p class="message no-moments">{{'CP_SM_GARAGE_NO_STORY_MSG' | customTranslation: translationData }} </p>
    </div>
</div>