import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    isRefreshingToken: boolean;

    constructor() {
        let storedUser = localStorage.getItem('currentCIAMUser');
        if (storedUser) {
            storedUser = JSON.parse(storedUser);
        }
        this.currentUserSubject = new BehaviorSubject<any>(storedUser);
        this.currentUser = this.currentUserSubject.asObservable();
        this.isRefreshingToken = false;
        this.checkCurrentUser();
    }

    checkCurrentUser() {
        let storedUser = localStorage.getItem('currentCIAMUser');
        if (!storedUser && this.currentUserSubject.value) {
            //this.logout();
        }
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    isLoggedIn() : Observable<boolean> {
        return this.currentUserSubject.asObservable();
    }

    login(result: any) {
        localStorage.setItem('currentCIAMUser', JSON.stringify(result));
        this.currentUserSubject.next(result);
        return result;
    }


    changeAccessToken() {
        console.log(this.currentUserSubject.value.access_token);
        this.currentUserSubject.value.access_token = this.currentUserSubject.value.access_token+'fsdfsdf';        
        console.log(this.currentUserSubject.value);
        this.currentUserSubject.next(this.currentUserSubject.value);
    }

    //handleUnauthorizedCall(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    //     console.log('handling 403');
    //     if (!this.isRefreshingToken) {
    //         console.log('handling 403 - step1');
    //       this.isRefreshingToken = true;
    //       const currentUser = this.currentUserSubject.value;
    
    //       // Reset here so that the following requests wait until the token
    //       // comes back from the refreshToken call.
    //       this.currentUserSubject.next('');
    //       // get a new token via userService.refreshToken
    //       return this.awsCognitoService.getNewToken(currentUser.refresh_token).pipe(mergeMap((newToken: any) => {
    //           // did we get a new token retry previous request
    //           if (newToken) {
    //             localStorage.setItem('access_token', newToken.access_token);
    //             localStorage.setItem('id_token', newToken.id_token);
    //             currentUser.access_token = newToken.access_token;                
    //             currentUser.id_token = newToken.id_token;
    //             this.currentUserSubject.next(currentUser);
    //             return next.handle(req);
    //           }
    
    //           // If we don't get a new token, we are in trouble so logout.
    //           this.logout();
    //           return throwError(() => '');
    
    //         }), catchError(error => {
    //           // If there is an exception calling 'refreshToken', bad news so logout.
    //           this.logout();
    //           return throwError(() => error);
    //         })
    //           , finalize(() => {
    //             this.isRefreshingToken = false;
    //           })
    //         );
    //     } 
    //     else {
    //         return next.handle(req);
    //     }
    //   }
}