<div class="main-error-page">

    <div class="error-content">
        <div class="error-txt">
            <h1>{{'RSP_ERROR_HEADING' | customTranslation: translationData}}</h1>
            <p class="sub-head">
              {{'RSP_ERROR_MSG' | customTranslation: translationData}}
            </p>
        </div>
        <!-- <div class="error-atn">
            <button class="error-button"><span >Send me somewhere nice</span></button>
        </div> -->
    </div>
    <div class="error-image">
        <picture>
            <source media="(min-width:1280px)" srcset="../../../../../assets/images/raceShare-error-landscape.png">
            <source media="(min-width:768px)" srcset="../../../../../assets/images/raceShare-error-tablet.png">
            <source media="(min-width:280px)" srcset="../../../../../assets/images/raceShare-error-portrait.png">
            <img src="../../../../../assets/images/raceShare-error-landscape.png" alt="error" >
        </picture>
    </div>

</div>
