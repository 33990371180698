
<div id="race-share-main" *ngIf="isShowSpinner && !isAccessDenied">

    <div id="back-btn-reg-rshr" *ngIf="isBackButtonVisible">
        <button class="trans" >
            <span class="icon icon_back"></span>
        </button>
    </div>
    <div id="race-share-container" *ngIf="raceSharePageInfo">
        <section>
                <app-event-details  [eventDetails]="raceSharePageInfo" [raceInfo]="raceSharePageInfo.raceInfo"></app-event-details>
        </section>
        <section>
                <app-highlight-video *ngIf="raceSharePageInfo.videoAssetsInfo" [videoAssetsInfo]="raceSharePageInfo.videoAssetsInfo"></app-highlight-video>
        </section>
        <section>
                <app-telemetric-data-card [raceInfo] = "raceSharePageInfo.raceInfo" [isDriftChallenge]="isDriftChallenge" ></app-telemetric-data-card>
        </section>
        <section>
            <div id="rc-trk-veh-main" class="rc-shr-pnl">
                <div class="rc-shr-title not-mobile">
                    <h1 *ngIf="!isTrackError">{{'RSP_TRACK_AND_VEHICLE' | customTranslation: translationData}}</h1>
                    <h1 *ngIf="isTrackError">{{'RSP_VEHICLE' | customTranslation: translationData}}</h1>
                </div>
                <div class="track-veh-box">
                    <div class="rc-shr-title for-mobile">
                        <h1 *ngIf="!isTrackError">{{'CP_LM_LANDINGPAGE_TRACKS' | customTranslation: translationData}}</h1>
                    </div>
                    <div class="track-veh-item" *ngIf="!isTrackError">
                        <app-event-track-detail *ngIf="trackInfo" [trackDesc]="raceSharePageInfo.trackInfo" [trackDetailsInfo]="trackInfo" [raceItemType] = "raceSharePageInfo.itemType" ></app-event-track-detail>

                    </div>
                    <div class="rc-shr-title for-mobile">
                        <h1 >{{'RSP_VEHICLE' | customTranslation: translationData}}</h1>
                    </div>
                    <div class="track-veh-item">
                        <app-event-car-details [vehicleInfo]="raceSharePageInfo.vehicleInfo"></app-event-car-details>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section *ngIf="isLeaderBoardVisible">
            <div class="rc-shr-pnl" >
                <div class="rc-shr-title">
                    <h1 >Leaderboard</h1>
                    <a role="button">Details</a>
                </div>
                <div class="leader-board-list-min">

                   <div class="lb-score-bar-item gold">
                        <div class="pos-avatar">
                            <span class="rc-pos">1</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                   </div>

                   <div class="lb-score-bar-item silver">
                        <div class="pos-avatar">
                            <span class="rc-pos">2</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                    </div>

                    <div class="lb-score-bar-item bronze">
                        <div class="pos-avatar">
                            <span class="rc-pos">3</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                    </div>

                    <div class="lb-score-bar-item usr">
                        <div class="pos-avatar">
                            <span class="rc-pos">4</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name User</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                    </div>

                    <div class="lb-score-bar-item"></div>
                    <div class="lb-score-bar-item"></div>

                    <div class="lb-score-bar-item">
                        <div class="pos-avatar">
                            <span class="rc-pos">199</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section> -->
        <section>
            <app-race-videos [videoAssetsInfo]="raceSharePageInfo.videoAssetsInfo"></app-race-videos>
        </section>
        <!-- <section *ngIf="isImpressionsVisible">
            <div class="rc-shr-pnl">
                <div class="rc-shr-title">
                    <h1 >Impressions</h1>
                </div>
                <div class="race-video-box flip-h">
                    <div class="race-vid-1 rc-vid">
                        <figure>
                            <img src="../../../../../assets/images/race/race_img_001.jpg" />
                        </figure>
                    </div>
                    <div class="race-vid-2 rc-vid">
                        <figure>
                            <img src="../../../../../assets/images/race/race_img_002.jpg" />
                        </figure>
                    </div>
                    <div class="race-vid-3 rc-vid">
                        <figure>
                            <img src="../../../../../assets/images/race/race_img_003.jpg" />
                        </figure>
                    </div>
                </div>
            </div>
        </section> -->




</div>

</div>
<div *ngIf="!isShowSpinner" >
    <div class="spinner-border spinner">
    </div>
</div>

<div *ngIf="isShowSpinner" [ngClass]="(isBestTimeChallenge || isDriftChallenge || (isSubType ==='EVENT'||isSubType ==='PERSONAL') ) ? 'dummy-page-feel' : 'dummy-page-feel-not-track'"></div>
<div *ngIf="isAccessDenied">
    <app-race-share-error-handling></app-race-share-error-handling>
</div>


<!-- <ng-container *ngIf="isPageNotFound">
    <app-no-route ></app-no-route>
</ng-container> -->
