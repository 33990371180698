import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from '../../../services/translation.service';

@Component({
	selector: 'app-emotional-data',
	templateUrl: './emotional-data.component.html',
	styleUrls: ['./emotional-data.component.css']
})
export class EmotionalDataComponent implements OnInit {
	
	
	@Input()
	value: any;
	
	
	@Input()
	title: any;
	
	
	@Input()
	unit: any;	
	
	@Input()
	icon: any;

	translatedArray =[];

	constructor(private translationService: TranslationService) { 
		
	}

	ngOnInit() {
	}

}
