import {Injectable} from '@angular/core';

import {Auth} from 'aws-amplify';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})

export class AuthAmplifyService {

  signedIn = false;
  auth = Auth;
  currentUser: any;
  constructor() {
    this.config();
  }

  config() {
    this.auth.configure(environment.amplifyConfig);
  }

  getCurrentUserInfo() {
    return this.auth.currentUserInfo().then((info) => {
      this.currentUser = info;
      return info;
    });
  }

  async signIn() {
    await this.auth.federatedSignIn({customProvider: 'CIAMProvider'})
      .then(user => {
        this.signedIn = true;
      })
      .catch(err => {
        console.log(err);
        this.signedIn = false;
      });
  }

  async logout() {
    await this.auth.signOut().then((res) => {
      this.signedIn = false;
    });
  }

  currentSession() {
    return this.auth.currentSession();
  }
}