<div  class="main-region" >
  <div class="parent-map-region" [ngClass]="isScrolledTitle ? 'scrolled' : ''" >
    <div id="twodmapcontainer"  class="map-class">
      <div [ngClass]="showMapData ? 'animate-fade' : ''" id="twodmap" #2DmapElement></div>
    </div>
    <div class="full-map">
      <canvas class="unity-container" id="unity-canvas"></canvas>
    </div>
    
  </div>
  <div id="left-region">
    <div class="highlight-region"  id="highlight-region" (scroll)="scrollHandler($event)" >
      <div class="empty-reg-highlight"></div>
      <div id="hightlight-content">        
        <div id="tour-data-main">
          <div id="tour-title" class="tour-title-main">
            <span (click)="goToExploreMain()" class="arrow"></span>
            <div id="title-bg-sticky"></div>
            <div id="tour-title-region">
              <div class="title-txt">
                <div class="title-head" [ngClass]="isScrolledTitle ? 'scrolled hide-from-view' : ''" >
                <div class="bookmark-position-container">
                  <h1>{{ overview?.properties.name  || 'DEFAULT_TRACK'}}</h1> 
                  <span class="" id='bookmark'></span>
                </div>              
                  <h6 class="scrolled-title">
                    {{ overview?.properties?.name  || 'DEFAULT_TRACK'}}
                  </h6>
                </div>
                <div class="tag-items" [ngClass]="isScrolledTitle ? 'hide-from-view' : ''">
                  <a href="#" [class.disabled]="isAnchorDisable" class="btn btn-primary"><span>{{'CP_LM_LANDINGPAGE_EMOTIONTOUR' | customTranslation: translationData}}</span></a>
                  <a href="#" [class.disabled]="isAnchorDisable" class="btn common-button"><span>{{overview?.lengthOfTour}}</span></a>
                  <a href="#" [class.disabled]="isAnchorDisable" class="btn common-button"><span>{{trackClusters?.length}} {{'CP_LM_TOURDETAILSPAGE_HIGHLIGHTS' | customTranslation: translationData}}</span></a>
                </div>
                <div class="arrow-down-ani" id="arrow-down-ani">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                
              </div>
            </div>
          </div>
          <div class="cover-initial-desc">
            <div class="owner-cover">
              <span [ngStyle]="{'background-image':'url('+overview?.properties?.owner?.profileImageUrl+')'}" class="prof-image"></span>
              <span class="owner-info-style">{{'CP_LM_TOURDETAILSPAGE_TOURBY' | customTranslation: translationData}} <b>{{ overview?.properties?.owner?.nickName || 'Anoymous'}}</b> . {{overview?.date || 'August 29 2021'}}</span>
            </div>
             <p class="initial-desc">
              {{ overview?.properties?.description || sampleDesc }}
            </p>
          </div>
          <ul id="list-element">
            <li #scrollMe id="parent-list" class="fadeOut-items">
              <div class="dynamic-h"></div>
              <div class="distance-between"></div>
              <div class="sticky-content" id="sticky-cont">
                <div class="fade-in-li-content">
                  <div class="tour-cover-content">
                    
                    <div class="annotations-image overlay-class slideshow-wrapper">
                      <ng-container *ngIf="overview?.properties?.media">
                        <div class="set-fav-style">
                          <div class="distance-style">{{ overview?.properties?.description || sampleDesc }}</div>
                          <ng-container *ngIf="showShareSection"> 
                            <hr/>
                            <div class="fav-area-style">
                              <div class="fav-share-style">
                                <span class="favourite-icn">
                                  <span class="fav-icn place-icn"></span> 
                                  <span class="fav-count count-style">0</span>
                                </span>
                                <span (click)="copyTourURL()" class="favourite-icn share-button more-style">
                                  <span class="share-icn"></span>
                                  <span class="count-style">{{'CP_LM_TOURDETAILSPAGE_SHARE' | customTranslation: translationData}}</span>
                                </span>
                              </div>                              
                              <span (click)="downloadTrackDetails()" class="download-icn more-style"></span>
                            </div>
                          <hr/>
                          </ng-container>                          
                        </div>
                        <img
                        id="annotation-card"
                        [src]="overview?.properties?.media[0].url"
                        class="fadeIn-items"
                        alt="image"
                      />
                      </ng-container>
                      <ng-container *ngIf="!overview?.properties?.media">
                        <img
                        id="annotation-card"
                        src="assets/images/cover.png" 
                        class="fadeIn-items"
                        alt="image"
                      />
                      </ng-container>
                    </div>
                    <div *ngIf="trackClusters && showHighlightsIndex" class="contents-list distance-style">
                      <p><b>{{'CP_LM_TOURDETAILSPAGE_HIGHLIGHTS' | customTranslation: translationData}}</b></p>
                      <div class="cluster-content-list" *ngIf="trackClusters.length > 0">
                        <div>
                          <div class="content-margin" *ngFor="let item of firstHalf;">
                            <div class="favourite-icn"><span>{{item.iteration >= 10 ? item.iteration : '0'+ item.iteration}}</span> <span class="dot"></span> <span>{{item.properties.cluster_name}}</span> </div>
                          </div>
                        </div>
                        <div>
                          <div class="content-margin" *ngFor="let item of secondHalf;">
                            <div class="favourite-icn"><span>{{item.iteration >= 10 ? item.iteration : '0'+ item.iteration}}</span> <span class="dot"></span> <span>{{item.properties.cluster_name}}</span> </div>
                          </div>
                        </div>                        
                      </div>                     
                    </div>
                    <!-- <div class="annotations-description distance-style" id="annotation-description">
                      <p id="description" class="fadeIn-items">
                        {{ overview?.properties?.description || sampleDesc }}
                      </p>
                    </div> -->
                   
                  </div>                  
                  
                    
                    <div class="progress-bar-h">
                      <div class="progress-line"></div>
                    </div>
                  </div>
                      
              </div>
              
              <div class="li-top"></div>
              <div  class="li-bottom"></div>
            </li>
            <li *ngFor="let cluster of trackClusters; let i = index"  class="fadeOut-items" #scrollMe >
              <div class="dynamic-h"></div>
              
                
              <div class="sticky-content">
                <div class="fade-in-li-content">
                  <div class="header-container inner-title">
                    <div class="image">
                      <span class="list-count">
                        <span *ngIf="i<10">0</span> 
                        <span>{{ i + 1 }}</span>
                      </span>
                    </div>
                    <div class="text">
                      <h1>{{ cluster.properties.cluster_name }}</h1>
                      <div class="distance-style padding-style">{{cluster.properties?.distance_from_origin ? cluster.properties?.distance_from_origin : '0'}} {{'CP_LM_TOURDETAILSPAGE_FROMSTART' | customTranslation: translationData}}</div>
                    </div>
                  </div>
                  <div *ngIf="cluster.properties?.cover_image" class="annotations-image">
                    <img
                      id="{{ 'annotation-card' + i }}"
                      [src]="cluster.properties.cover_image"
                      class="fadeIn-items"
                      alt="image"
                    />
                  </div>
                  <div class="annotations-description distance-style">
                    <span class="fadeIn-items long-description-style">{{ cluster.properties.long_ai_summary }}</span>
                    <!-- <span (click)="goToAnnotation(cluster.properties.annotations[0].id)" class="expand-button" *ngIf="cluster.isSingleton"> <u>{{'CP_LM_TOURDETAILSPAGE_MORE' | customTranslation: translationData}}</u></span> -->
                  </div>
                    <div *ngIf="cluster.isSingleton === false" class="sub-cluster-info">
                      <div class="sub-info-item" *ngFor="
                          let annotations of cluster.properties.annotations;
                          let i = index
                        "
                      >
                      <div *ngIf="cluster.properties.annotations">
                        <div class="image-tile-main">
                        <div class="annotation-media">
                          <img
                            [src]="annotations.media.url"
                            *ngIf="annotations.media?.url"
                            class="fadeIn-items"
                            alt="image"
                          />
                      
                        </div>
                        <small class="title">{{ annotations.name }}</small>
                      </div>
                  
                      </div>
                      <div *ngIf="annotations.short_ai_summary" class="annotations-description">
                        <p class="fadeIn-items">
                          {{ annotations.short_ai_summary }}
                        </p>
                      </div>

                    </div>
                  </div>
                  <div
                    *ngIf="cluster.multiple"
                    class="image-tile-main"
                  >
                    <div
                      *ngFor="
                        let annotations of cluster.properties.annotations
                        let i = index
                      "
                    >
                      <div class="annotation-img-card">
                        <div (click)="goToAnnotation(annotations.id)"  class="annotation-media more-style">
                          <img
                            [src]="annotations.media.url"
                            *ngIf="annotations.media?.url"
                            class="fadeIn-items" alt="image"
                          />
                         
                        </div>
                        <small  class="title more-style">{{ annotations.name }}</small>
                      </div>
                      <div class="annotations-description">
                      </div>                     
                    </div>
                  </div>
                    <div class="progress-bar-h">
                      <div class="progress-line"></div>
                    </div>
                </div>


                

              </div>
              
              <div class="distance-between" >
                {{cluster.properties.route_position}} 
              </div>
              
              <div class="li-top"></div>
              <div  class="li-bottom"></div>
              
              
            </li>
          </ul>
        </div>

        
      </div>
    </div>
    
    <div id="bg-container">
      <div id="bg-highlight"></div>
    </div>
  </div>
</div>


<div *ngIf="showDetails"class="details-page-class">
  <app-annotation-details (closeDetailsPage)="closeDetailsPage($event)" [annotationDetails]="annotationDetails"></app-annotation-details>
</div>