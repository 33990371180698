
<div class="track-veh-item">
    <div class="car-reg">
        <figure>
            <img [src]="vehicleInfo.vehicleImageUrl">
            <img *ngIf="vehicleInfo.vehicleImageUrl===null"  class="car-fallback" src="../../../../../assets/images/img-experience-detail-ce-fallback-amg.png" alt="" />        </figure>
    </div>
    <div class="veh-info-box">
        <span>{{vehicleInfo.vehicleModel ? vehicleInfo.vehicleModel : 'Mercedes-Benz'}}</span>
    </div>
</div>
