import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class RouterLanguageGaurdGuard implements CanActivate {

  constructor(private router: Router, private languageService: LanguageService) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let languageId = this.languageService.getLanguage();
      let originalUrl = state.url;       
      if(!originalUrl.startsWith('/login') && !originalUrl.startsWith('/logout') && !originalUrl.includes('/verify') && !originalUrl.includes('/anonymize')) {
        /* adding this line just to make sure modified url does not contain // like: languageid//originalurl 
          or no / at all between the url sections
        */
        originalUrl = originalUrl.replace(/^\/+/, ''); 
        const modifiedUrl = `/${languageId}/${originalUrl}`;
        console.log('modified:', modifiedUrl);
        this.router.navigate([modifiedUrl]);
      }
      else if(originalUrl.includes('verify')  || originalUrl.includes('anonymize')){
        this.addStroiesInUrl(originalUrl, languageId);
      }
      return true;
  } 



  addStroiesInUrl(originalUrl : any, languageId: any){
    originalUrl  = originalUrl.replace('/'+languageId, ''); 
    let modifiedUrl = "";
    modifiedUrl = originalUrl.includes('stories')? `/${languageId}/${originalUrl}`: `/${languageId}/stories${originalUrl}`;
    this.router.navigate([modifiedUrl]);
  }
}
