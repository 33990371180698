
<ng-container>
    <ng-container *ngIf="!showNotFound && !isPageNotFound">
    <div [ngClass]="hideLandingPage ? '' : 'animate-fade'" id="landing-page-main">
        <div id="map" #mapElement></div>
        <div *ngIf="showListArea" class="cluster-style">
            <app-clusters-list [isEmotiontourEnabled]="this._enableEmotionTour" [isCircuitsEnabled]="this._enableCircuits" [enableTrackList]="_enableTrackList" [isOffRoadEnabled]="_enableOffTour" [totalElements]="totalElements" [completeClusterList]="completeList" [filter]="selectedFilter" (showMarker)="showMarker($event)" (sendSearchDataToMap)="sendSearchDataToMap($event)" (sendDataToMap)="goToTour($event)" [clusterList]="passedList"></app-clusters-list>
        </div>
    </div>
    
    <div id="summaryPage" class="" [ngClass]="showDetails ? '' : 'hide-page'">
        <app-emotion-tour-summary (backClicked)="backClicked($event)" [selectedCoord]="selectedCoord" [tourGuid]="guid" [initialize]="initialize" [navFromLocation]="navFromLocation" [loadAnnotations]="loadAnnotationsFlag" [trackClusters]="this.AnnotationsData" [clusterData]="emotionTourData"></app-emotion-tour-summary>
     </div>

     <div class="details-page-class" *ngIf="showCircuitDetails">
        <app-circuit-details-page 
        [circuitData]="circuitData" 
        [coordinate]="circuitCoordinates"
        [circuitimageData]="circuitimageData"
        [navFromLanding]="navFromLanding"
        [slides]="trackSlides"
        (goToHomePage)="hideCircuitDetails($event)">
        </app-circuit-details-page></div>
    </ng-container>
     
    <div *ngIf="isPageNotFound">
        <app-no-route></app-no-route>
    </div>
</ng-container>

