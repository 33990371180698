<section class="responsive-profile" (click)="toggleOverlay()">
    <div class="profile-sec">
        <div class="usr-img">
            <img src="../../../../../../assets/images/empty.png" class="profile-image"
                style="background-image: url({{profileDetails.profileImageUrl?profileDetails.profileImageUrl:profileImage}});"
                alt="">
        </div>
        <div class="profile-name profile-btn">
            <span class="name-txt">{{profileDetails.firstName}} {{profileDetails.lastName}}</span>
        </div>
    </div>
    <!--<div class="big icon icon_arrow_down"></div>-->

</section>


<div class="container">
    <!-- Overlay -->
    <div class="overlay" [ngClass]="{'active': overlayActive}">

        <div class="profile-wrapper">
            <div class="overlay-menu" *ngIf="overlayActive">
                <span (click)="toggleOverlay()" class="icon icon_close"></span>
            </div>
            <div class="profile">
                <div class="profile-group-primary">
                    <div class="profile-picture">
                        <img class="profile-image"
                            style="background-image: url({{profileDetails.profileImageUrl?profileDetails.profileImageUrl:profileImage}});"
                            alt="">
                    </div>
                    <div class="profile-name">
                        <h6>{{profileDetails.firstName}} {{profileDetails.lastName}}</h6>
                        <small *ngIf="!!profileDetails?.email && profileDetails.isOwner" >{{profileDetails.email}}</small>
                        <div class="prof-links-main">
                            <span (click)="profileClicked()" class="prof-link" *ngIf="profileDetails.isOwner">{{'CP_SM_GARAGE_EDIT_PROFILE' | customTranslation: translationData}}</span>
                            <span class="prof-link" *ngIf="profileDetails.isOwner && isShareProfileVisible" >{{'CP_SM_GARAGE_SHARE_PROFILE' | customTranslation: translationData}}</span>
                        </div>

                    </div>
                </div>
                <div class="profile-group-secondary">
                    <div class="prof-info about" *ngIf='!!profileDetails?.description'>
                        <h6>{{'CP_SM_GARAGE_ABOUT' | customTranslation: translationData}}</h6>
                        <p>{{profileDetails.description}}</p>
                    </div>
                    <div class="prof-info social" *ngIf="!!profileDetails?.socialLink?.length">
                        <h6>{{'CP_SM_GARAGE_SOCIAL' | customTranslation: translationData}}</h6>
                        <span class="social-link" (click)="socialLinkClicked()"> {{profileDetails.socialLink}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<div *ngIf="isProfileClicked" class="edit-profile-visible" >
    <app-edit-profile (cancelClicked)="cancelClicked($event)" [isStoriesSection]="isStoriesSection" [userId]="userId"></app-edit-profile>
</div>