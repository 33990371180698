import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserInfo } from 'src/app/components/interfaces/myStories.interface';
import { TranslationService } from 'src/app/services/translation.service';
import { MyStoriesService } from '../../services/my-stories.service';



@Component({
  selector: 'app-profile-component',
  templateUrl: './profile-component.component.html',
  styleUrls: ['./profile-component.component.scss']
})
export class ProfileComponentComponent implements OnInit {
@Input()  profileDetails !: UserInfo;
profileImage = "../../../../../assets/images/profile-fallback.png";
@Output() isProfileClicked = new EventEmitter<boolean>();
@Input() shareProfileUrl: any;
@Input() userId: any;
featureFlags: any;

isShareProfileVisible : boolean = false;
  translationData: any;
  constructor(private translationService: TranslationService, private myStoriesService: MyStoriesService) {
    this.featureFlags = this.translationService.featureFlags;
   }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    if(this.featureFlags.general.enableShareProfileButton){
      this.isShareProfileVisible = true
    }
    if(this.profileDetails){
      console.log(this.profileDetails)
    }
  }



  profileClicked() {
    this.myStoriesService.editProfilePopupVisible(true); // Show popup
  }

  
  socialLinkClicked(){
    window.open(this.profileDetails.socialLink, '_blank');
  }
  
  shareProfileClicked(){
    
  }

}